import { Col, Row } from 'antd';
import React from 'react';
import * as texts from 'assets/texts/corporateEvents';
import OfficeTripsForm from './Form';
import styles from './OfficeTrips.module.scss';

const OfficeTrips = () => {
  return (
    <Row className={styles.Container}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <h2 className={styles.Title}>{texts.officeTrips}</h2>
            <p className={styles.Description}>{texts.officeTripsDescription}</p>
          </Col>
        </Row>
        <Row justify="space-between" gutter={{ sm: 40 }}>
          <Col xs={0} sm={12}>
            <img
              className={styles.Image}
              src={texts.officeTripsImage}
              alt="Office Trips"
            />
          </Col>
          <Col xs={24} sm={12}>
            <OfficeTripsForm />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OfficeTrips;
