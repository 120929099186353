import { gql, useQuery } from '@apollo/client';
import { Pack, QueryGetPackForPurchaseArgs } from 'apollo/generated/types';

const GET_PACK = gql`
  query getPack($id: ID!, $date: String!) {
    getPackForPurchase(id: $id, date: $date) {
      adultValue
      description
      name
      id
      otherValue
      remaining
      enDescription
      enName
      status
      activities {
        activityTimes {
          dailyAvailability
          endHour
          id
          remaining
          startHour
          openActivityTimetablesPerDay {
            id
          }
        }
        id
        name
        hasSingleTimetable
      }
    }
  }
`;

const useGetPackQuery = (id: string, date: string) => {
  const query = useQuery<{ getPackForPurchase: Pack }, QueryGetPackForPurchaseArgs>(
    GET_PACK,
    {
      variables: { id, date },
    }
  );
  return query.data?.getPackForPurchase;
};
export default useGetPackQuery;
