import React, { FC, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Col, Row, Input } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import TricaoLogo from 'assets/images/tricao-logo.png';
import InstagramIcon from 'assets/images/instagram-icon.svg';
import TiktokIcon from 'assets/images/tiktok-icon.svg';
import YoutubeIcon from 'assets/images/youtube-icon.svg';
import * as texts from 'assets/texts/footer';
import useListParkAvailabilityQuery from 'apollo/resolvers/parkAvailability/list';
import styles from './Footer.module.scss';
import HelpForm from './HelpForm';

const Footer: FC = () => {
  const [parkAvailabilitySchedule, setParkAvailabilitySchedule] =
    useState<{ summer: string; winter: string }>();

  const { data: parkAvailabilityData } = useListParkAvailabilityQuery();

  const setParkAvailabilityFromQuery = () => {
    if (parkAvailabilityData) {
      const parkAvailabilitySummer = parkAvailabilityData.listParkAvailability.find(
        (availability) => availability.isWinterTime === false
      );
      const parkAvailabilityWinter = parkAvailabilityData.listParkAvailability.find(
        (availability) => availability.isWinterTime === true
      );

      if (parkAvailabilitySummer && parkAvailabilityWinter) {
        const summerSchedule = `${parkAvailabilitySummer?.aperture} - ${parkAvailabilitySummer?.closure}`;
        const winterSchedule = `${parkAvailabilityWinter?.aperture} - ${parkAvailabilityWinter?.closure}`;

        setParkAvailabilitySchedule({
          summer: summerSchedule,
          winter: winterSchedule,
        });
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderNewsLetter = () => {
    return (
      <>
        <Row justify="space-around">
          <Col lg={12} sm={18} xs={22}>
            <h1 className={styles.JoinEmailChainMessage}>
              {texts.joinEmailChainMessage}
            </h1>
          </Col>
        </Row>
        <Row id="contactUs" className={styles.Row} justify="space-around">
          <Col lg={8} sm={14} xs={18}>
            <Input
              className={styles.InputMail}
              size="large"
              prefix={<MailOutlined className={styles.mailIcon} />}
            />
          </Col>
        </Row>
      </>
    );
  };

  useEffect(() => {
    setParkAvailabilityFromQuery();
  }, [parkAvailabilityData]);

  return (
    <div className={styles.Container}>
      <div className={styles.ShareSocialMediaContainer}>
        {/* Hide this until further notice <ShareSocialMedia /> */}
      </div>
      {/* Commented because newsLetter is disabled: {renderNewsLetter()} */}
      <Row className={styles.Row} justify="space-around">
        <Col
          sm={{ span: 11, order: 1 }}
          xs={{ span: 24, order: 2 }}
          className={styles.availabilityInfo}
        >
          <Row justify="space-between">
            <Col xs={24} md={12}>
              <div>
                <p className={styles.LightWeight}>{parse(texts.address as string)}</p>
                <a
                  className={styles.Href}
                  href="https://www.google.com/maps/place/Parque+Tricao/@-33.7049855,-71.6338541,17z/data=!3m1!4b1!4m5!3m4!1s0x966236f2787f2d2d:0x7aac3248e5307883!8m2!3d-33.7049855!4d-71.6316654"
                >
                  {texts.seeGoogleMapText}
                </a>
              </div>
              <br />
              <div>
                <p className={styles.Schedule}>{texts.summerScheduleTitle}</p>
                <p className={styles.LightWeight}>
                  {texts.summerMonths} {parkAvailabilitySchedule?.summer}
                </p>
                <p className={styles.Schedule}>{texts.winterScheduleTitle}</p>
                <p className={styles.LightWeight}>
                  {texts.winterMonths} {parkAvailabilitySchedule?.winter}
                </p>
              </div>
            </Col>
            <Col xs={24} md={10} className={styles.HrefContainer}>
              <div>
                <a className={styles.Href} href="/faq">
                  {texts.frequentQuestionsLink}
                </a>
                <br />
                <br />
                <a className={styles.Href} href="/purchaseChange">
                  {texts.changeTicketsLink}
                </a>
                <br />
                <br />
                <a
                  className={styles.Href}
                  href={texts.tosLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {texts.tos}
                </a>
                <br />
                <br />
                <a
                  className={styles.Href}
                  href={texts.privacyPolicyLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {texts.privacyPolicy}
                </a>
              </div>
            </Col>
          </Row>
          <Row className={styles.LogoRow} justify="space-between">
            <Col xs={24} md={11} className={styles.SocialMediaIcons}>
              <div>
                <a
                  className={styles.SocialMediaIcon}
                  href="https://www.instagram.com/parquetricao/"
                >
                  <img src={InstagramIcon} alt="instagram" />
                </a>
                <a
                  className={styles.SocialMediaIcon}
                  href="https://www.youtube.com/watch?v=nAzfUkn_ml4&list=PLLkPT7eQTByG4-hxMR3odM1T7OIj_FBL4&index=1"
                >
                  <img src={YoutubeIcon} alt="youtube" />
                </a>
                <a
                  className={styles.SocialMediaIcon}
                  href="https://www.tiktok.com/@parquetricao?is_from_webapp=1&sender_device=pc"
                >
                  <img
                    className={styles.SocialMediaImage}
                    src={TiktokIcon}
                    alt="tiktok"
                  />
                </a>
              </div>
            </Col>
            <Col xs={24} md={11} className={styles.LogoContainer}>
              <img
                src={TricaoLogo}
                alt="Tricao-Logo-Template"
                className={styles.TricaoLogo}
              />
            </Col>
          </Row>
        </Col>
        <Col
          className={styles.HelpFormContainer}
          sm={{ span: 11, order: 2 }}
          xs={{ span: 24, order: 1 }}
        >
          <div id="contactUs">
            <HelpForm />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
