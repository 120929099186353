import React from 'react';
import { useLocation } from 'react-router-dom';

type WebpaySearchParams = {
  token: string | null;
  purchaseId: string;
  date: string | null;
  email: string | null;
  uuid: string | null;
};

const webpayQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const getWebpayTokenSearchParams = (): WebpaySearchParams => {
  const query = webpayQuery();
  const token = query.get('token');
  const date = query.get('date');
  const email = query.get('email');
  const purchaseId = query.get('purchaseId') || '';
  const uuid = query.get('uuid');

  return { token, purchaseId, date, email, uuid };
};

export default getWebpayTokenSearchParams;
