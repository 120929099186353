import { gql, useMutation } from '@apollo/client';
import { ContactUsOutput, MutationSendEmailArgs } from 'apollo/types';

const SEND_EMAIL = gql`
  mutation sendEmail($input: ContactUsInput!) {
    sendEmail(input: $input) {
      response
    }
  }
`;

const useSendEmailMutation = () => {
  return useMutation<{ sendEmail: ContactUsOutput }, MutationSendEmailArgs>(
    SEND_EMAIL,
    {}
  );
};

export default useSendEmailMutation;
