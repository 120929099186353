import React, { FC, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  FormInstance,
  Radio,
  message,
  Checkbox,
  Select,
} from 'antd';
import cellPhoneRegExp from 'utils/validations/cellPhoneRegExp';
import emailRegExp from 'utils/validations/emailRegExp';
import rutFormValidator from 'utils/validations/rutFormValidator';
import rutFormat from 'utils/formats/rutFormat';
import {
  BillingDocumentType,
  BillingSystemInputArgs,
  ClientFormData,
  CreatePurchaseInputArgs,
  CustomerType,
  GiftCardPurchaseInput,
  UpsertClientAndCreateGiftCardTransactionInput,
  UpsertClientAndCreateTransactionInput,
  UpsertClientArgs,
} from 'apollo/types';
import * as texts from 'assets/texts/purchaseSummary';
import { getOrCreatePurchaseData } from 'storage/utils/getOrCreatePurchaseData';
import useUpsertWebpayTransactionMutation from 'apollo/resolvers/webpayTransaction/upsert';
import useUpsertWebpayGiftCardTransactionMutation from 'apollo/resolvers/webpayTransaction/upsertGiftCard';
import purchaseDataToPurchaseInput from 'apollo/mapper/puchaseDataToPurchaseInput';
import redirectToWebpay from 'utils/webpay/redirectToWebpay';
import LoadingModal from 'components/generalUI/LoadingModal';
import { useHistory } from 'react-router-dom';
import dniValidator from 'utils/validations/dniValidator';
import { DefaultOptionType } from 'antd/lib/select';
import useListBillingSystemCitiesLazyQuery from 'apollo/resolvers/billingSystem/city/list';
import useListBillingSystemCommunesLazyQuery from 'apollo/resolvers/billingSystem/commune/list';
import { RuleObject } from 'antd/lib/form';
import getGiftCardSearchParams from 'utils/dates/getGiftCardSearchParams';
import styles from './ClientForm.module.scss';

interface ClientFormProps {
  form: FormInstance;
  date: string;
}

const ClientForm: FC<ClientFormProps> = ({ form, date }: ClientFormProps) => {
  const history = useHistory();
  const giftCardParams = getGiftCardSearchParams();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPassport, setIsPassport] = useState<boolean>(false);
  const [upsertWebpayTransactionMutation] = useUpsertWebpayTransactionMutation();
  const [upsertWebpayGiftCardTransactionMutation] =
    useUpsertWebpayGiftCardTransactionMutation();
  const [arePoliticsAccepted, setArePoliticsAccepted] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [customerType, setCustomerType] = useState<CustomerType>(CustomerType.Person);
  const [listBillingSystemCities, { loading: citiesLoading }] =
    useListBillingSystemCitiesLazyQuery();
  const [cities, setCities] = useState<DefaultOptionType[]>();
  const [selectedCityId, setSelectedCityId] = useState<string>();
  const [listBillingSystemCommunes, { loading: communesLoading }] =
    useListBillingSystemCommunesLazyQuery();
  const [communes, setCommunes] = useState<DefaultOptionType[]>();

  const resetDocumentTypeField = () => {
    form.setFieldsValue({ documentType: false });
    setIsPassport(false);
  };

  const listCities = async () => {
    if (isInvoice) {
      const response = await listBillingSystemCities();
      if (response && response.data) {
        const mappedCities = response.data.listBillingSystemCities.map((city) => ({
          label: city.name,
          value: city.id,
        }));
        setCities(mappedCities);
      }
    }
  };

  const listCommunes = async () => {
    if (selectedCityId) {
      const response = await listBillingSystemCommunes({
        variables: { input: { billingSystemCityId: selectedCityId } },
      });
      if (response && response.data) {
        const mappedCommunes = response.data.listBillingSystemCommunes.map((commune) => ({
          label: commune.name,
          value: commune.id,
        }));
        setCommunes(mappedCommunes);
        form.setFieldsValue({ customerCommuneId: undefined });
      }
    }
  };

  const createWebpayTransaction = async (
    clientArguments: UpsertClientArgs,
    purchaseArguments: CreatePurchaseInputArgs,
    billingSystemArguments: BillingSystemInputArgs
  ) => {
    const upsertClientAndCreateTransaction: UpsertClientAndCreateTransactionInput = {
      clientArguments,
      purchaseArguments,
      billingSystemArguments,
    };

    try {
      const data = await upsertWebpayTransactionMutation({
        variables: upsertClientAndCreateTransaction,
      });
      if (data.data && data.data.createPurchase) {
        setIsModalVisible(false);
        const { url, token } = data.data.createPurchase;
        if (token) {
          redirectToWebpay(url, token);
        } else {
          history.push(url);
        }
      }
    } catch {
      setIsModalVisible(false);
      throw new Error();
    }
  };

  const createWebpayTransactionForGiftCard = async (
    clientArguments: UpsertClientArgs,
    giftCardPurchaseArguments: GiftCardPurchaseInput,
    billingSystemArguments: BillingSystemInputArgs
  ) => {
    const upsertClientAndCreateTransaction: UpsertClientAndCreateGiftCardTransactionInput =
      {
        clientArguments,
        giftCardPurchaseArguments,
        billingSystemArguments,
      };

    try {
      const data = await upsertWebpayGiftCardTransactionMutation({
        variables: upsertClientAndCreateTransaction,
      });
      if (data.data && data.data.createGiftCardPurchase) {
        setIsModalVisible(false);
        const { url, token } = data.data.createGiftCardPurchase;
        if (token) {
          redirectToWebpay(url, token);
        } else {
          history.push(url);
        }
      }
    } catch {
      setIsModalVisible(false);
      throw new Error();
    }
  };

  const onFinish = async (clientFormData: ClientFormData) => {
    try {
      if (!arePoliticsAccepted) {
        await message.warning(texts.politicsWarning);
        return;
      }

      setIsModalVisible(true);

      const client: UpsertClientArgs = {
        firstName: clientFormData.firstName,
        lastName: clientFormData.lastName,
        phone: clientFormData.phone,
        email: clientFormData.email.trim(),
        documentNumber: clientFormData.documentNumber,
        documentIsPassport: isPassport,
      };

      const billingSystemInput: BillingSystemInputArgs = {
        billingDocumentType: clientFormData.billingDocumentType,
        customerBusinessActivity: clientFormData.customerBusinessActivity,
        customerAddress: clientFormData.customerAddress,
        customerCityId: clientFormData.customerCityId,
        customerCommuneId: clientFormData.customerCommuneId,
        customerType: clientFormData.customerType,
      };

      if (giftCardParams) {
        const giftCardPurchaseInput: GiftCardPurchaseInput = {
          from: giftCardParams.from,
          to: giftCardParams.to,
          totalPrice: Number(giftCardParams.amount),
          method: 'webpay',
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        createWebpayTransactionForGiftCard(
          client,
          giftCardPurchaseInput,
          billingSystemInput
        );
      } else {
        const purchase = getOrCreatePurchaseData(date);
        const purchaseInput = purchaseDataToPurchaseInput(purchase, date, 'webpay');
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        createWebpayTransaction(client, purchaseInput, billingSystemInput);
      }
    } catch {
      setIsModalVisible(false);
      throw new Error();
    }
  };

  const filterByValue = (value: string, option: DefaultOptionType | undefined) => {
    if (!option || !option.label) {
      return false;
    }
    return option.label.toString().toLowerCase().includes(value.toLowerCase());
  };

  const renderCustomerTypeField = () => {
    if (isInvoice) {
      return (
        <>
          <p className={styles.BillingWarning}>{texts.billingWarning}</p>
          <Form.Item
            name="customerType"
            rules={[{ required: true, message: texts.warning }]}
          >
            <Radio.Group
              onChange={(e) => setCustomerType(e.target.value as CustomerType)}
            >
              <Radio value={CustomerType.Person}>{texts.person}</Radio>
              <Radio value={CustomerType.Company}>{texts.company}</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      );
    }
    return <></>;
  };

  const renderCustomerNameFields = () => {
    if (isInvoice && customerType === CustomerType.Company) {
      return (
        <Form.Item rules={[{ required: true, message: texts.warning }]} name="firstName">
          <Input className={styles.FormInput} placeholder={`${texts.companyName} *`} />
        </Form.Item>
      );
    }
    return (
      <>
        <Form.Item rules={[{ required: true, message: texts.warning }]} name="firstName">
          <Input className={styles.FormInput} placeholder={`${texts.firstName} *`} />
        </Form.Item>
        <Form.Item rules={[{ required: true, message: texts.warning }]} name="lastName">
          <Input className={styles.FormInput} placeholder={`${texts.lastName} *`} />
        </Form.Item>
      </>
    );
  };

  const renderDocumentTypeField = () => {
    if (!isInvoice) {
      return (
        <Form.Item name="documentType">
          <Radio.Group
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            onChange={(e) => setIsPassport(e.target.value)}
          >
            <Radio value={false}>{texts.rut}</Radio>
            <Radio value>{texts.passport}</Radio>
          </Radio.Group>
        </Form.Item>
      );
    }
    return <></>;
  };

  const renderDocumentNumberField = () => {
    if (isPassport) {
      return (
        <Form.Item
          name="documentNumber"
          rules={[
            { required: true, message: texts.warning },
            { validator: dniValidator },
          ]}
        >
          <Input className={styles.FormInput} placeholder={`${texts.dni} *`} />
        </Form.Item>
      );
    }
    return (
      <Form.Item
        name="documentNumber"
        rules={[
          { required: true, message: texts.warning },
          { validator: rutFormValidator },
        ]}
        normalize={rutFormat}
      >
        <Input className={styles.FormInput} placeholder={`${texts.rutExample} *`} />
      </Form.Item>
    );
  };

  const renderInvoiceFields = () => {
    if (isInvoice) {
      return (
        <>
          <Form.Item
            rules={[{ required: true, message: texts.warning }]}
            name="customerBusinessActivity"
          >
            <Input
              className={styles.FormInput}
              placeholder={`${texts.customerBusinessActivity} *`}
            />
          </Form.Item>
          <Form.Item
            name="customerCityId"
            rules={[{ required: true, message: texts.warning }]}
          >
            <Select
              showSearch
              filterOption={filterByValue}
              className={styles.FormSelect}
              options={cities}
              placeholder={`${texts.city} *`}
              onChange={setSelectedCityId}
            />
          </Form.Item>
          <Form.Item
            name="customerCommuneId"
            rules={[{ required: true, message: texts.warning }]}
          >
            <Select
              showSearch
              filterOption={filterByValue}
              className={styles.FormSelect}
              options={communes}
              placeholder={`${texts.commune} *`}
            />
          </Form.Item>
          <Form.Item
            name="customerAddress"
            rules={[{ required: true, message: texts.warning }]}
          >
            <Input className={styles.FormInput} placeholder={`${texts.address} *`} />
          </Form.Item>
        </>
      );
    }
    return <></>;
  };

  const validateConfirmEmail = (rule: RuleObject, value: string) => {
    const formEmail = form.getFieldValue('email') as string;
    const isMailEqual = value && formEmail && value.trim() === formEmail.trim();
    if (!isMailEqual) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Los mails deben ser iguales');
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (isInvoice) {
      resetDocumentTypeField();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      listCities();
    }
  }, [isInvoice]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    listCommunes();
  }, [selectedCityId]);

  return (
    <>
      <LoadingModal visible={isModalVisible || citiesLoading || communesLoading} />
      <Row justify="center">
        <Col xs={20} lg={15}>
          <h1 className={styles.Title}>{texts.contactDetails}</h1>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} lg={15}>
          <Form
            className={styles.Form}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onFinish={onFinish}
            form={form}
            initialValues={{
              documentType: false,
              billingDocumentType: BillingDocumentType.Voucher,
              customerType: CustomerType.Person,
            }}
          >
            <Form.Item
              name="billingDocumentType"
              rules={[{ required: true, message: texts.warning }]}
            >
              <Radio.Group
                onChange={(e) => {
                  if (e.target.value === BillingDocumentType.Invoice) {
                    setIsInvoice(true);
                  } else {
                    setIsInvoice(false);
                  }
                }}
              >
                <Radio value={BillingDocumentType.Voucher}>{texts.voucher}</Radio>
                <Radio value={BillingDocumentType.Invoice}>{texts.invoice}</Radio>
              </Radio.Group>
            </Form.Item>

            {renderCustomerTypeField()}

            {renderCustomerNameFields()}

            <Form.Item
              name="phone"
              rules={[
                { required: true, message: texts.warning },
                {
                  message: texts.invalidCellphone,
                  pattern: cellPhoneRegExp(),
                },
              ]}
            >
              <Input
                className={styles.FormInput}
                placeholder={`${texts.phone} *, Ej: +56912345678`}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: texts.warning },
                {
                  message: texts.invalidEmail,
                  pattern: emailRegExp(),
                },
              ]}
            >
              <Input className={styles.FormInput} placeholder={`${texts.email} *`} />
            </Form.Item>

            <Form.Item
              name="confirmEmail"
              rules={[
                { required: true, message: texts.warning },
                {
                  message: texts.invalidEmail,
                  pattern: emailRegExp(),
                },
                { validator: validateConfirmEmail },
              ]}
            >
              <Input
                className={styles.FormInput}
                placeholder={`${texts.confirmEmail} *`}
              />
            </Form.Item>

            {renderDocumentTypeField()}

            {renderDocumentNumberField()}

            {renderInvoiceFields()}

            <Form.Item className={styles.ParkPolicy} name="acceptParkPolitics">
              <Checkbox
                className={styles.Checkbox}
                onChange={() => setArePoliticsAccepted(!arePoliticsAccepted)}
              >
                {texts.agreed}
              </Checkbox>
            </Form.Item>
            <Row>
              <a
                className={styles.Href}
                href={texts.tosLink}
                target="_blank"
                rel="noreferrer"
              >
                {texts.TOS}
              </a>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ClientForm;
