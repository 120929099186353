import { gql, useQuery } from '@apollo/client';
import { NewsBanner } from 'apollo/generated/types';

const GET_NEWSBANNER = gql`
  query getNewsBanner {
    getNewsBanner {
      title
      body
      isVisible
    }
  }
`;

const useGetNewsBannerQuery = () => {
  return useQuery<{ getNewsBanner: NewsBanner }>(GET_NEWSBANNER);
};
export default useGetNewsBannerQuery;
