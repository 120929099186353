import Button from 'components/generalUI/Button';
import React, { FC } from 'react';
import updateDateLocaleES from 'utils/dates/updateDateLocaleES';
import useListParkAvailabilityQuery from 'apollo/resolvers/parkAvailability/list';
import useListEntranceTicketTypesQuery from 'apollo/resolvers/entranceTicketTypes/list';
import { ParkAvailability } from 'apollo/types';
import formatDateToMonthString from 'utils/dates/formatDateToMonthString';
import moment from 'moment';
import { Card, Col, Row } from 'antd';
import * as texts from 'assets/texts/shareLinks';
import LoadingModal from 'components/generalUI/LoadingModal';
import priceFormat from 'utils/formats/priceFormat';
import styles from './ScheduleAndPricesSection.module.scss';

const ScheduleAndPricesSection: FC = () => {
  updateDateLocaleES('es');
  const { data: parkAvailabilityData } = useListParkAvailabilityQuery();
  const { data: entranceTicketTypesData, loading: loadingEntranceTicketTypes } =
    useListEntranceTicketTypesQuery();

  const currentAvailability = parkAvailabilityData?.listParkAvailability.find(
    (availability) => {
      const date = new Date().toISOString();
      const dateToCompare = date ? moment(date) : moment();
      return dateToCompare.isBetween(availability.startDate, availability.endDate);
    }
  );

  const formatParkAvailability = (availability: ParkAvailability) => {
    const time = `${availability?.aperture} a ${availability?.closure}`;
    const months = `${formatDateToMonthString(
      availability?.startDate
    )} a ${formatDateToMonthString(availability?.endDate)}`;
    const date = new Date().toISOString();
    const dateToCompare = date ? moment(date) : moment();
    const isActualParkAvailability = dateToCompare.isBetween(
      availability.startDate,
      availability.endDate
    );
    return { time, months, isActualParkAvailability };
  };

  const generalEntranceTicket = entranceTicketTypesData?.listEntranceTicketTypes.find(
    (entranceTicketType) => entranceTicketType.id === '1'
  );

  if (!currentAvailability || !generalEntranceTicket) {
    return <LoadingModal visible />;
  }

  const { time, months } = formatParkAvailability(currentAvailability);
  const { adultValue } = generalEntranceTicket;

  return (
    <>
      <LoadingModal visible={loadingEntranceTicketTypes} />
      <Row className={styles.Container} justify="center" gutter={[0, 16]}>
        <Col span={24}>
          <h4 className={styles.Title}>{texts.purchaseSectionTitle}</h4>
        </Col>

        <Col span={24}>
          <Card className={styles.AvailabilityCard}>
            <Row className={styles.MonthsAvailability} justify="center">
              {months}
            </Row>
            <Row justify="center">{time} hrs</Row>
            <Row justify="center">
              Valor de la entrada desde {priceFormat.format(adultValue)}
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Button className={styles.PrimaryButton} href="/date" size="large">
                {texts.buyTicketsButton}
              </Button>
            </Col>
            <Col className={styles.BuyDisclaimer} span={24}>
              <div>{texts.buyDisclaimer}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ScheduleAndPricesSection;
