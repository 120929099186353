import Button from 'components/generalUI/Button';
import useGetEnrichedPurchaseQuery from 'apollo/resolvers/resultPurchase/get';
import Handlebars from 'handlebars';
import React, { FC, useState } from 'react';
import * as texts from 'assets/texts/successPurchase';
import priceFormat from 'utils/formats/priceFormat';
import { template } from './template';

interface ViewTicketsButtonProps {
  uuid: string;
}

const ViewTicketsButton: FC<ViewTicketsButtonProps> = (props: ViewTicketsButtonProps) => {
  const { uuid } = props;
  const { data, loading } = useGetEnrichedPurchaseQuery(uuid);
  const compiledTemplate = Handlebars.compile(template);

  const [openedWindow, setOpenedWindow] = useState<Window | null>(null);

  const openTicketsInNewWindow = () => {
    if (openedWindow && !openedWindow.closed) return;
    const win = window.open(
      '',
      'View tickets',
      'scrollbars=yes,resizable=yes,_blank, width=1200, height=1200'
    );

    const aviaryTicket = data?.getEnrichedPurchase?.purchase?.activityTimes?.find(
      (activity) => activity.name === 'Aviario'
    );
    const isAviary = data?.getEnrichedPurchase?.purchase?.activityTimes?.some(
      (activity) => activity.name === 'Aviario'
    );

    const purchase = data?.getEnrichedPurchase?.purchase;

    const purchaseActivityTickets = purchase?.activityTickets;
    const purchaseEntranceTickets = purchase?.entranceTickets;
    const purchaseGrillTickets = purchase?.grillTickets;
    const purchasePackPurchases = purchase?.packPurchases;

    const mappedActivityTickets = purchaseActivityTickets
      ? purchaseActivityTickets.map((activityTicket) => ({
          ...activityTicket,
          price: priceFormat.format(activityTicket.price),
          finalPrice: priceFormat.format(activityTicket.finalPrice),
        }))
      : [];
    const mappedEntranceTickets = purchaseEntranceTickets
      ? purchaseEntranceTickets.map((entranceTicket) => ({
          ...entranceTicket,
          price: priceFormat.format(entranceTicket.price),
          finalPrice: priceFormat.format(entranceTicket.finalPrice),
        }))
      : [];

    const mappedGrillTickets = purchaseGrillTickets
      ? purchaseGrillTickets.map((grillTicket) => ({
          ...grillTicket,
          price: priceFormat.format(grillTicket.price),
          finalPrice: priceFormat.format(grillTicket.finalPrice),
        }))
      : [];

    const mappedPackPurchases = purchasePackPurchases
      ? purchasePackPurchases.map((packPurchase) => ({
          ...packPurchase,
          price: priceFormat.format(packPurchase.price),
          finalPrice: priceFormat.format(packPurchase.finalPrice),
        }))
      : [];

    const totalPrice = data?.getEnrichedPurchase.totalPrice
      ? priceFormat.format(data?.getEnrichedPurchase?.totalPrice)
      : 0;

    setOpenedWindow(win);
    win?.document?.write(
      compiledTemplate({
        ...data?.getEnrichedPurchase,
        purchase: {
          ...data?.getEnrichedPurchase?.purchase,
          activityTickets: mappedActivityTickets,
          entranceTickets: mappedEntranceTickets,
          grillTickets: mappedGrillTickets,
          packPurchases: mappedPackPurchases,
          isAviary,
          aviaryTicket,
        },
        totalPrice,
        faqUrl: `/faq`,
        howToGetToTheParkUrl: `https://www.google.com/maps/place/Parque+Tricao/@-33.7049855,-71.6338541,17z/data=!3m1!4b1!4m5!3m4!1s0x966236f2787f2d2d:0x7aac3248e5307883!8m2!3d-33.7049855!4d-71.6316654`,
        downloadMapUrl:
          'https://tricao-file-storage-dev.s3.amazonaws.com/maps/Map_Tricao_2023.pdf',
        tosUrl:
          'https://tricao-production.s3.us-west-2.amazonaws.com/assets/policies/TOS_Tricao_2023.pdf',
      })
    );
    win?.focus();
  };

  return (
    <>
      <Button loading={loading} size="large" onClick={openTicketsInNewWindow}>
        {texts.viewTickets}
      </Button>
    </>
  );
};

export default ViewTicketsButton;
