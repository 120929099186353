import { gql, useQuery } from '@apollo/client';
import { Program } from 'apollo/generated/types';

const GET_PROGRAMS = gql`
  query getProgram($id: ID!) {
    getProgram(id: $id) {
      id
      title
      description
      subtitle
      coverImage {
        url
        id
      }
      secondaryImages {
        url
        id
      }
      hoursDuration
      targetGroup
      videoLink
      validForWeekends
    }
  }
`;
const useGetProgramQuery = (id: string) => {
  return useQuery<{ getProgram: Program }>(GET_PROGRAMS, {
    variables: { id },
  });
};
export default useGetProgramQuery;
