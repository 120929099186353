import { Col, Row } from 'antd';
import { Body } from 'components/NewsPost/Body';
import Header from 'components/NewsPost/Header';
import { Recommendations } from 'components/NewsPost/Recommendations';
import SubHeader from 'components/NewsPost/SubHeader';
import { useNewsPostViewModel } from 'components/NewsPost/newsPost.viewModel';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import styles from './NewsPost.module.scss';

const NewsPost: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useNewsPostViewModel();
  const containerStyle = isMobile ? styles.MobileContainer : styles.Container;

  return (
    <Row className={containerStyle}>
      <Col span={24}>
        {id && (
          <>
            <Header newsPostId={id} />
            <SubHeader newsPostId={id} />
            <Body newsPostId={id} />
            <Recommendations newsPostId={id} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default NewsPost;
