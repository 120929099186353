import { gql, useQuery } from '@apollo/client';
import { MappedPurchase, QueryListChangeablePurchasesArgs } from 'apollo/generated/types';

const LIST_CHANGEABLE_PURCHASES = gql`
  query listChangeablePurchases($accessToken: String!) {
    listChangeablePurchases(accessToken: $accessToken) {
      date
      id
      email
      includePacks
      uuid
      purchaseActivities {
        id
        name
        ticketCount
        activityTime {
          id
          endHour
          startHour
        }
      }
      purchaseGrills {
        id
        name
        ticketCount
        activityTime {
          id
          endHour
          startHour
        }
      }
      purchaseItems {
        adultTicketCount
        entranceTicketTypeId
        name
        otherTicketCount
      }
    }
  }
`;

const useListChangeablePurchasesQuery = (accessToken: string, skip: boolean) => {
  return useQuery<
    { listChangeablePurchases: MappedPurchase[] },
    QueryListChangeablePurchasesArgs
  >(LIST_CHANGEABLE_PURCHASES, {
    variables: { accessToken },
    skip,
    fetchPolicy: 'no-cache',
  });
};
export default useListChangeablePurchasesQuery;
