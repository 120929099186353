import React from 'react';
import ShareSocialMediaImage from 'assets/images/share-social-media-image.jpg';
import { Col, Row } from 'antd';
import * as texts from 'assets/texts/newsPosts';
import { NewsPosts as NewsPostsComponent } from 'components/NewsPosts';
import styles from './NewsPosts.module.scss';

const NewsPosts = () => {
  return (
    <>
      <div className={styles.ImageContainer}>
        <div className={styles.BackGroundImageContainer}>
          <img
            className={styles.Image}
            src={ShareSocialMediaImage}
            alt="Share Social Media"
          />
        </div>
      </div>
      <Row justify="center">
        <Col xs={20} sm={18} md={16} xxl={12}>
          <Row className={styles.TitleContainer} justify="center">
            <Col>
              <h1 className={styles.Title}>{texts.newsPosts}</h1>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <NewsPostsComponent />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default NewsPosts;
