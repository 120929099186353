import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import es from 'dayjs/locale/es';

const isDateToDisplayIsBefore = (stringDate: string, date: Date): string => {
  const stringDateDate = Number(stringDate.split('T')[0].split('-')[2]);
  if (date.getDate() < stringDateDate) {
    date.setDate(date.getDate() + 1);
  }
  const displayDate = capitalize(dayjs(date).locale(es).format('dddd D MMMM YYYY'));

  return displayDate;
};

export default isDateToDisplayIsBefore;
