export const title = 'Detalle de tu compra';
export const adults = 'Adultos';
export const others = 'Adulto mayor/niño';
export const tickets = 'Ticket(s)';
export const subTotal = 'Sub-total: ';
export const total = 'Total a pagar: ';
export const havePromotionCode = 'Tengo un código de descuento';
export const haveGiftCard = 'Tengo una gift card';
export const submitCode = 'Aplicar código';
export const pay = 'Pagar';
export const discountCode = 'Ingresa código de descuento';
export const incorrectCode = 'Código incorrecto o inválido';
export const giftCardCode = 'Ingresa código de gift card';
export const giftCardPass = 'Ingresa clave de gift card';
export const incorrectGiftCard = 'Combinación de código y clave de gift card inválida';
export const submitGiftCard = 'Aplicar gift card';
export const discount = 'Descuento';
export const newTotal = 'Nuevo Total: ';
export const correctCode = '¡Código ingresado con éxito!';
export const promotionCodeErrorWeekend =
  'El código de promoción no es válido para fin de semanas';
export const addAnotherEntrance = 'Agregar otra entrada';
export const orContinueBelow = 'o continúa con la compra más abajo';
export const contactDetails = 'Detalle del contacto';
export const companyName = 'Nombre de empresa o razón social';
export const firstName = 'Nombre';
export const lastName = 'Apellido';
export const warning = 'Campo obligatorio';
export const phone = 'Teléfono';
export const invalidCellphone = 'Ingrese un número válido';
export const email = 'Correo electrónico';
export const confirmEmail = 'Confirmar correo electrónico';
export const invalidEmail = 'Ingrese un email válido';
export const rut = 'RUT';
export const passport = 'Pasaporte';
export const documentNumber = 'Número de documento';
export const rutExample = 'Rut xx.xxx.xxx-x';
export const dni = 'DNI';
export const voucher = 'Boleta';
export const invoice = 'Factura';
export const billingAddress = 'Dirección de facturación';
export const company = 'Empresa';
export const person = 'Persona';
export const customerBusinessActivity = 'Giro comercial';
export const commune = 'Comuna';
export const city = 'Ciudad';
export const address = 'Dirección';
export const billingWarning =
  'Los siguientes datos también serán usados para la facturación.';
export const agreed = 'Acepto las políticas del Parque';
export const TOS = 'Términos y condiciones';
export const tosLink =
  'https://tricao-production.s3.us-west-2.amazonaws.com/assets/policies/TOS_Tricao_2023.pdf';
export const politicsWarning = 'Debes aceptar las políticas del Parque';
export const from = 'De:';
export const to = 'Para:';
export const giftCard = 'Gift Card';
export const grillWithoutEntrance =
  'Para comprar un quincho debes tener una entrada asociada.';
