/* eslint-disable import/prefer-default-export */
import ShareSocialMediaImage from 'assets/images/share-social-media-image.jpg';
import React, { FC, useEffect, useState } from 'react';
import { NewsPost } from 'apollo/types';
import useGetNewsPostQuery from 'apollo/resolvers/newsPosts/get';
import { Carousel, Col, Row } from 'antd';
import { InformationCard } from 'components/generalUI/InformationCard';
import LoadingModal from 'components/generalUI/LoadingModal';
import * as texts from 'assets/texts/newsPost';
import { useNewsPostViewModel } from '../newsPost.viewModel';
import styles from './Recommendations.module.scss';

export interface RecommendationsProps {
  newsPostId: string;
}

export const Recommendations: FC<RecommendationsProps> = ({
  newsPostId,
}: RecommendationsProps) => {
  const { isMobile, setNewsPostFromQuery } = useNewsPostViewModel();
  const [newsPost, setNewsPost] = useState<NewsPost>();
  const { data, loading } = useGetNewsPostQuery(newsPostId);

  const renderOtherPrograms = () => {
    if (isMobile) {
      return (
        <Carousel className={styles.Carousel} draggable swipeToSlide slidesToShow={1}>
          {newsPost &&
            newsPost.newsPostRecommendations.map((recommendedPost) => (
              <InformationCard
                key={recommendedPost.id}
                isProgram={false}
                title={recommendedPost.title}
                id={recommendedPost.id}
                imageUrl={recommendedPost.coverImage?.url}
                color="green"
                fixedHeight
              />
            ))}
        </Carousel>
      );
    }
    return (
      <Row>
        <Col span={24}>
          <Row justify="center" gutter={{ sm: 5, md: 20, lg: 30 }}>
            {newsPost &&
              newsPost.newsPostRecommendations.map((recommendedPost) => (
                <Col key={recommendedPost.id} xs={24} sm={8}>
                  <InformationCard
                    isProgram={false}
                    key={recommendedPost.id}
                    title={recommendedPost.title}
                    id={recommendedPost.id}
                    imageUrl={recommendedPost.coverImage?.url}
                    color="green"
                  />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    setNewsPostFromQuery(data, setNewsPost);
  }, [data]);

  return (
    <>
      <LoadingModal visible={loading} />
      <Row>
        <Col span={24}>
          <div className={styles.ImageContainer}>
            <div className={styles.BackGroundImageContainer}>
              <img
                className={styles.Image}
                src={ShareSocialMediaImage}
                alt="Share Social Media"
              />
            </div>
          </div>
          <Row className={styles.Container} justify="center">
            <Col xs={18} lg={16} xxl={14}>
              <h1 className={styles.Title}>{texts.recommendedPosts}</h1>
            </Col>
            {!loading && (
              <Col xs={18} lg={16} xxl={14}>
                {renderOtherPrograms()}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
