import { Carousel } from 'antd';
import { Place } from 'apollo/types';
import ScrollableItem from 'components/sharedComponents/ScrollableItem';

import React, { FC } from 'react';
import styles from './ListGrillImages.module.scss';

export interface ListGrillImagesProps {
  grill: Place;
}

const ListGrillImages: FC<ListGrillImagesProps> = ({ grill }: ListGrillImagesProps) => {
  const grillHasImages = grill.placeImages && grill.placeImages.length > 0;
  const { innerWidth: width } = window;

  const renderGrillImages = () => {
    return (
      <div className={styles.ScrollableList}>
        <Carousel
          slidesToShow={width > 768 ? 2 : 1}
          draggable
          swipeToSlide
          touchThreshold={50}
          focusOnSelect
          autoplay
        >
          {grill.placeImages?.map((grillImage) => {
            const { image } = grillImage;
            const url = image as string;
            return (
              <ScrollableItem
                scrollableImageClassName={styles.ScrollableImage}
                key={image}
                imageUrl={url}
                isForGrill
              />
            );
          })}
        </Carousel>
      </div>
    );
  };

  return (
    <>
      {grillHasImages && (
        <div className={styles.Container}>
          <div className={styles.ScrollableList}>{renderGrillImages()}</div>
        </div>
      )}
    </>
  );
};

export default ListGrillImages;
