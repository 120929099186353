import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { Activity } from 'apollo/types';
import parse from 'html-react-parser';
import * as texts from 'assets/texts/grills';
import styles from './GrillDetails.module.scss';

interface GrillDetailsProps {
  grills?: Activity[];
}

const GrillDetails: FC<GrillDetailsProps> = (props: GrillDetailsProps) => {
  const { grills } = props;
  const [selectedGrill, setSelectedGrill] = useState<Activity>();

  useEffect(() => {
    if (grills) setSelectedGrill(grills[0]);
  }, [grills]);

  return (
    <div className={styles.Container}>
      <Row justify="center">
        <Col xs={20} sm={15} lg={12}>
          <h1 className={styles.Title}>{texts.grillDetailTitle}</h1>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} sm={15} lg={12}>
          <p className={styles.Description}>{texts.grillDetailDescription}</p>
        </Col>
      </Row>
      <div className={styles.ScrollableList}>
        {grills &&
          grills.map((grill) => (
            <Button
              type="primary"
              onClick={() => setSelectedGrill(grill)}
              className={
                selectedGrill?.name === grill.name
                  ? styles.SelectedButton
                  : styles.UnselectedButton
              }
              key={grill.name}
            >
              <Row align="middle" justify="center">
                <p>{grill.name}</p>
              </Row>
            </Button>
          ))}
      </div>
      {selectedGrill && (
        <>
          <div className={styles.ImagesContainer}>
            {selectedGrill.activityImages.map((activityImage) => (
              <div className={styles.ActivityImage} key={activityImage.id}>
                <img alt={selectedGrill.name} src={activityImage.image || ''} />
              </div>
            ))}
          </div>
          <Row justify="center">
            <Col xs={20} sm={15} lg={12}>
              <p className={styles.GrillDescription}>{texts.grillDetailFooter}</p>
              <div className={styles.GrillDescription}>
                {parse(selectedGrill.description || '')}
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

GrillDetails.defaultProps = {
  grills: [],
};

export default GrillDetails;
