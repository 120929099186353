import { gql, useQuery } from '@apollo/client';
import { Activity, QueryListActivitiesArgs } from 'apollo/generated/types';

const LIST_ACTIVITIES_WITHOUT_TIMES = gql`
  query listActivities($activityTypeId: ID, $date: String!) {
    listActivities(date: $date, activityTypeId: $activityTypeId) {
      activityImages {
        id
        image
      }
      description
      enDescription
      enName
      id
      maxStock
      name
      paxPerTicket
      status
      value
    }
  }
`;
const useListActivitiesWithoutTimes = (date: string, activityTypeId?: string) => {
  return useQuery<{ listActivities: Activity[] }, QueryListActivitiesArgs>(
    LIST_ACTIVITIES_WITHOUT_TIMES,
    {
      variables: { date, activityTypeId },
    }
  );
};
export default useListActivitiesWithoutTimes;
