import { gql, useQuery } from '@apollo/client';
import {
  EntranceTicketType,
  QueryGetEntranceTicketTypeArgs,
} from 'apollo/generated/types';

const GET_ENTRANCE_TICKET_TYPE = gql`
  query getEntranceTicketType($id: ID!, $date: String!) {
    getEntranceTicketType(id: $id, date: $date) {
      name
      adultValue
      id
      maxStock
      otherValue
      remaining
      status
      description
      isBicycle
    }
  }
`;

const useGetEntranceTicketTypeQuery = (id: string, date: string) => {
  const query = useQuery<
    { getEntranceTicketType: EntranceTicketType },
    QueryGetEntranceTicketTypeArgs
  >(GET_ENTRANCE_TICKET_TYPE, {
    variables: { id, date },
  });
  return query.data?.getEntranceTicketType;
};
export default useGetEntranceTicketTypeQuery;
