import { DocumentNode, GraphQLSchema } from 'graphql';
import { makeExecutableSchema } from '@graphql-tools/schema';
import { mergeTypeDefs } from '@graphql-tools/merge';

const makeSchema = (
  generatedSchema: DocumentNode,
  extendedSchema: DocumentNode | undefined = undefined
): GraphQLSchema => {
  let typeDefs: DocumentNode;
  if (extendedSchema) {
    typeDefs = mergeTypeDefs([extendedSchema, generatedSchema]);
    return makeExecutableSchema({ typeDefs });
  }
  typeDefs = generatedSchema;
  return makeExecutableSchema({ typeDefs });
};

export default makeSchema;
