import React, { FC, useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import dayjs from 'dayjs';
import es from 'dayjs/locale/es';
import useGetEnrichedPurchaseQuery from 'apollo/resolvers/resultPurchase/get';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as texts from '../../../assets/texts/successPurchase';
import styles from './SuccessPurchaseCard.module.scss';
import ViewTicketsButton from './ViewTicketsButton';

dayjs.extend(customParseFormat);

interface SuccessPurchaseCardProps {
  purchaseId: string;
  email: string;
  uuid: string;
}

const SuccessPurchaseCard: FC<SuccessPurchaseCardProps> = (
  props: SuccessPurchaseCardProps
) => {
  const { purchaseId, email, uuid } = props;
  const { data } = useGetEnrichedPurchaseQuery(uuid);
  const [displayDate, setDisplayDate] = useState<string>('');

  const isGiftCardPurchase = !!data?.getEnrichedPurchase.purchase?.giftCard;

  useEffect(() => {
    const visitDate = data?.getEnrichedPurchase.purchase?.visitDate;
    if (visitDate) {
      const date = dayjs(visitDate, 'DD/MM/YYYY').locale(es).format('D MMMM YYYY');
      setDisplayDate(date);
    }
  }, [data]);

  const waitingForYouText = () => {
    if (isGiftCardPurchase) {
      return <>{texts.waitingForYouGiftCard}</>;
    }
    return (
      <>
        {texts.waitingForYou} <b>{displayDate}</b>
      </>
    );
  };

  const cardMainDescriptionText = () => {
    if (isGiftCardPurchase) {
      return (
        <p className={styles.CardDescription}>
          {texts.purchaseMainDescriptionGiftCard} <b>{email}</b>
        </p>
      );
    }
    return (
      <>
        <p className={styles.CardDescription}>{texts.purchaseMainDescription}</p>
      </>
    );
  };
  return (
    <Col>
      <Card className={styles.Card}>
        <Row justify="center">
          <h3 className={styles.CardTitle}>{waitingForYouText()}</h3>
        </Row>
        <Row justify="center">
          <p className={styles.CardTitle}>
            {texts.orderNumber} <b>#{purchaseId}</b>
          </p>
        </Row>
        <Row>{cardMainDescriptionText()}</Row>
        {!isGiftCardPurchase && (
          <Row>
            <p className={styles.CardDescription}>
              {texts.downloadTicketsDescription} <b>{email}</b>
            </p>
          </Row>
        )}
        {!isGiftCardPurchase && (
          <Row>
            <p className={styles.CardDescription}>{texts.purchaseSecondaryDescription}</p>
          </Row>
        )}
        {!isGiftCardPurchase && (
          <Row justify="space-around">
            <ViewTicketsButton uuid={uuid} />
          </Row>
        )}
      </Card>
    </Col>
  );
};

export default SuccessPurchaseCard;
