import React, { FC, useState } from 'react';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import * as texts from 'assets/texts/navbar';
import styles from './NavMenu.module.scss';

const NavMenu: FC = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const hideDrawer = () => {
    setVisible(false);
  };

  return (
    <div className={styles.Menu}>
      <MenuOutlined onClick={showDrawer} className={styles.MenuOutlined} />
      <Drawer
        className={styles.Drawer}
        placement="left"
        onClose={hideDrawer}
        visible={visible}
      >
        <div className={styles.DrawerContainer}>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/">
              {texts.home}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/somos">
              {texts.aboutUs}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/#places" onClick={hideDrawer}>
              {texts.spaces}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/#activities" onClick={hideDrawer}>
              {texts.activities}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/programs/1" onClick={hideDrawer}>
              {texts.programs}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/corporateEvents" onClick={hideDrawer}>
              {texts.corporateEvents}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/giftCard">
              {texts.giftCards}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/faq">
              {texts.faq}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/newsPosts" onClick={hideDrawer}>
              {texts.newsPosts}
            </a>
          </h2>
          <h2 className={styles.DrawerTitle}>
            <a className={styles.DrawerItem} href="/#contactUs">
              {texts.contact}
            </a>
          </h2>
        </div>
      </Drawer>
    </div>
  );
};

export default NavMenu;
