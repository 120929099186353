import { Col, Row } from 'antd';
import React, { FC } from 'react';
import ParkRulesImage from 'assets/images/not-allowed-icons.png';
import * as texts from 'assets/texts/parkRules';
import styles from './ParkRules.module.scss';

const ParkRules: FC = () => {
  return (
    <Row className={styles.Container} justify="center">
      <Col span={24}>
        <p className={styles.ParkRulesTitle}>{texts.notAllowed}</p>
        <img src={ParkRulesImage} alt="Park Rules" className={styles.Image} />
        <p className={styles.ParkRulesTitle}>{texts.disclaimer}</p>
      </Col>
    </Row>
  );
};

export default ParkRules;
