import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Typography, Select, Button } from 'antd';
import setDateHeaderProps from 'utils/dates/setDateHeaderProps';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import styles from '../OpenDayPicker.module.scss';
import { OpenDayPickerHeaderProps } from '../types';

const OpenDayPickerHeader: FC<OpenDayPickerHeaderProps> = ({
  value,
  onChange,
  lastOpenDayDate,
  setSelectedMonth,
  selectedMonth,
}) => {
  const {
    months,
    selectedMonth: monthFromCalendar,
    currentYear: yearFromValue,
  } = setDateHeaderProps(value);
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const lastOpenMonth = (lastOpenDayDate && lastOpenDayDate.getMonth()) || currentMonth;
  const lastOpenYear = (lastOpenDayDate && lastOpenDayDate.getFullYear()) || currentYear;
  const [selectedYear, setSelectedYear] = useState(yearFromValue);
  const [isDecreaseButtonDisabled, setIsDecreaseButtonDisabled] = useState(false);
  const [isIncreaseButtonDisabled, setIsIncreaseButtonDisabled] = useState(false);

  const { Title } = Typography;

  const onManualDateChange = () => {
    const month = value.month();
    const year = value.year();
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  // Function for increasing/decreasing months by clicking the arrows
  const onChangeMonth = (direction: 'prev' | 'next') => {
    if (selectedMonth === undefined) {
      return;
    }
    if (
      (direction === 'prev' && isDecreaseButtonDisabled) ||
      (direction === 'next' && isIncreaseButtonDisabled)
    ) {
      return;
    }

    let newMonth = selectedMonth;

    if (direction === 'prev') {
      newMonth -= 1;
    } else if (direction === 'next') {
      newMonth += 1;
    }

    const newValue = value.clone();
    newValue.month(newMonth);
    onChange(newValue);
    setSelectedMonth(newMonth);
  };

  // Function for selecting a month from the dropdown
  const selectMonth = (month: string) => {
    const newValue = value.clone();
    newValue.month(parseInt(month, 10));
    onChange(newValue);
    setSelectedMonth(parseInt(month, 10));
  };

  // Function for selecting a year from the dropdown
  const selectYear = (year: string) => {
    const newValue = value.clone();
    newValue.year(parseInt(year, 10));
    if (year === String(currentYear)) {
      setSelectedMonth(currentMonth);
      newValue.month(currentMonth);
    } else if (year === String(lastOpenYear)) {
      setSelectedMonth(0);
      newValue.month(0);
    }
    onChange(newValue);
    setSelectedYear(parseInt(year, 10));
  };

  const monthsAfterSelectedMonth = () => {
    const monthsAfter = [];
    for (let index = 0; index < 12; index += 1) {
      if (currentMonth > index && currentYear === selectedYear) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (lastOpenMonth < index && lastOpenYear === selectedYear) {
        // eslint-disable-next-line no-continue
        continue;
      }
      monthsAfter.push(
        <Select.Option className="month-item" key={index}>
          {months[index]}
        </Select.Option>
      );
    }
    return monthsAfter;
  };

  const yearsAfterSelectedYear = () => {
    const yearsAfter = [];
    for (let index = currentYear; index <= lastOpenYear; index += 1) {
      yearsAfter.push(
        <Select.Option className="year-item" key={`${index}`}>
          {index}
        </Select.Option>
      );
    }
    return yearsAfter;
  };

  // Function to check if the arrows should be disabled
  const checkForDisabledButtons = () => {
    if (selectedMonth === undefined) {
      return;
    }
    const isOnInitialMonth =
      selectedYear === currentYear && selectedMonth === currentMonth;
    if (isOnInitialMonth) {
      setIsDecreaseButtonDisabled(true);
    } else {
      setIsDecreaseButtonDisabled(false);
    }

    const isOnLastMonth =
      selectedYear === lastOpenYear && selectedMonth === lastOpenMonth;
    if (isOnLastMonth) {
      setIsIncreaseButtonDisabled(true);
    } else {
      setIsIncreaseButtonDisabled(false);
    }
  };

  const renderIconButton = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      return (
        <CaretLeftOutlined
          className={
            isDecreaseButtonDisabled
              ? styles.DisabledMonthCounterIcon
              : styles.MonthCounterIcon
          }
        />
      );
    }
    if (direction === 'next') {
      return (
        <CaretRightOutlined
          className={
            isIncreaseButtonDisabled
              ? styles.DisabledMonthCounterIcon
              : styles.MonthCounterIcon
          }
        />
      );
    }

    return null;
  };

  useEffect(() => {
    setSelectedMonth(monthFromCalendar);
  }, [monthFromCalendar]);

  useEffect(() => {
    setSelectedMonth(monthFromCalendar);
  }, [monthFromCalendar]);

  useEffect(() => {
    onManualDateChange();
  }, [value]);

  useEffect(() => {
    checkForDisabledButtons();
  }, [selectedMonth, selectedYear]);

  return (
    <div>
      <Title level={4}>Elige la fecha de tu visita</Title>
      <Row justify="space-between" align="middle">
        <Col>
          <Button
            type="link"
            className={styles.MonthCounterButton}
            onClick={() => onChangeMonth('prev')}
            icon={renderIconButton('prev')}
          />
        </Col>
        <Col className={styles.OpenDayPickerHeaderCol}>
          <Row>
            <Select
              className={styles.OpenDayPickerMonthSelect}
              bordered={false}
              size="large"
              dropdownMatchSelectWidth={false}
              value={String(selectedMonth)}
              suffixIcon={null}
              onChange={selectMonth}
            >
              {monthsAfterSelectedMonth()}
            </Select>
          </Row>
          <Row justify="center">
            <Select
              className={styles.OpenDayPickerYearSelect}
              bordered={false}
              size="small"
              dropdownMatchSelectWidth={false}
              value={String(selectedYear)}
              onChange={selectYear}
            >
              {yearsAfterSelectedYear()}
            </Select>
          </Row>
        </Col>
        <Col>
          <Button
            type="link"
            className={styles.MonthCounterButton}
            onClick={() => onChangeMonth('next')}
            icon={renderIconButton('next')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OpenDayPickerHeader;
