/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Input, message, Checkbox } from 'antd';
import Button from 'components/generalUI/Button';
import LoadingModal from 'components/generalUI/LoadingModal';
import { GiftCardWithRemainingAmount } from 'apollo/types';
import * as texts from 'assets/texts/purchaseSummary';
import useGetGiftCardQuery from 'apollo/resolvers/giftCards/get';
import { getOrCreatePurchaseData } from 'storage/utils/getOrCreatePurchaseData';
import styles from './GiftCardInput.module.scss';

interface GiftCardProps {
  setGiftCard: React.Dispatch<React.SetStateAction<GiftCardWithRemainingAmount | null>>;
  date: string;
}

const GiftCardInput: FC<GiftCardProps> = ({ setGiftCard, date }: GiftCardProps) => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [getGiftCard, { data, loading }] = useGetGiftCardQuery();
  const [isFormVisible, setIsFormVisible] = useState(false);

  const isDataFromGetGiftCardQueryValid = () => {
    if (data) {
      if (data.getGiftCard) {
        setGiftCard(data?.getGiftCard);
        const purchase = getOrCreatePurchaseData(date);
        const giftCard = { giftCardCode: code, giftCardPassword: password };
        purchase.giftCard = giftCard;
        sessionStorage.setItem('purchase', JSON.stringify(purchase));
        message.success(texts.correctCode);
      } else {
        message.error(texts.incorrectGiftCard);
      }
    }
  };

  const isButtonDisabled = !code || !password;
  const handleVisibilityChange = () => {
    setIsFormVisible(!isFormVisible);
  };

  useEffect(() => isDataFromGetGiftCardQueryValid(), [data]);
  return (
    <>
      <Row justify="center">
        <Col xs={20} lg={15} className={styles.Title}>
          <Checkbox onChange={handleVisibilityChange}>{texts.haveGiftCard}</Checkbox>
        </Col>
      </Row>
      <Row justify="center" hidden={!isFormVisible}>
        <LoadingModal visible={loading} />
        <Col className={styles.GiftCard} xs={20} lg={15}>
          <Row justify="space-between" gutter={[8, 8]}>
            <Col xs={24} lg={12}>
              <Input
                className={styles.Input}
                placeholder={texts.giftCardCode}
                onChange={(event) => setCode(event.target.value)}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Input
                className={styles.Input}
                placeholder={texts.giftCardPass}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col className={styles.GiftCard} xs={20} lg={15}>
          <Button
            type="primary"
            className={styles.SubmitButton}
            kind="secondary"
            disabled={isButtonDisabled}
            onClick={() => {
              getGiftCard({ variables: { code, password } });
            }}
          >
            {texts.submitGiftCard}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default GiftCardInput;
