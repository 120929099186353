import React, { FC } from 'react';
import { Card, Col, Row } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import * as texts from 'assets/texts/purchase';
import getIsWebpayErrorSearchParam from 'utils/webpay/getPurchaseErrorSearchParam';
import styles from './PurchaseWebpayError.module.scss';

const PurchaseError: FC = () => {
  const isWebpayError = getIsWebpayErrorSearchParam();

  return (
    <div className={styles.Container}>
      <Row justify="center">
        <Col>
          <Card className={styles.Card}>
            <WarningOutlined className={styles.WarningIcon} />
            <p className={styles.CardText}>
              {isWebpayError
                ? texts.purchaseWebpayErrorMessage
                : texts.purchaseErrorMessage}
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseError;
