export const title = 'Cambio de tu entrada';
export const enterData =
  'Ingresa los siguientes datos para solicitar el cambio de entrada';
export const email = 'Correo';
export const documentNumber = 'Rut';
export const footerText =
  '* Aquí puedes modificar la fecha de tu visita y/o el horario de ingreso al Aviario con, al menos, 24 horas de anticipación a la fecha indicada en tu ticket. ';
export const footerTextAvailability =
  '* Los cambios de fecha y hora, están sujetos a disponibilidad.';
export const sentEmailText =
  'Hemos enviado un mail a tu correo electrónico para continuar el proceso de cambio de fecha u horario de tu(s) entrada(s).';

export const adults = 'Adultos';
export const others = 'Niño/Adulto mayor';
export const tickets = 'tickets';
export const changeDate = 'Cambio de fecha';
export const changeTime = 'Solo cambio de horario';
export const noPurchasesMessage = 'No tienes compras disponibles para cambio de fecha';
export const purchaseChangeButton = 'Cambios de fechas u horarios';
export const purchaseHourChangeButton = 'Cambios de horarios';
export const confirmDateTitle = 'Confirma el cambio de fecha de tu entrada';
export const confirmButton = 'Confirmar';
export const orderNumber = 'Número de orden: #';
export const warning = 'Campo obligatorio';
export const invalidEmail = 'Email Inválido';
export const rut = 'RUT';
export const passport = 'Pasaporte';
export const dni = 'DNI';
export const rutExample = 'Rut xx.xxx.xxx-x';
export const send = 'Enviar';
export const selectPurchase = 'Selecciona la compra que quieres cambiar de fecha';
export const selectGrill = 'Selecciona un quincho';
export const selectGrillError = 'Debes seleccionar un quincho para continuar';
