import { gql, useQuery } from '@apollo/client';
import { Place } from 'apollo/generated/types';

const LIST_PLACES = gql`
  query listPlaces {
    listPlaces {
      id
      mainDescription
      name
      placeMainImage
      secondaryDescription
      placeImages {
        image
      }
    }
  }
`;
const useListPlacesQuery = () => {
  return useQuery<{ listPlaces: Place[] }>(LIST_PLACES, {});
};
export default useListPlacesQuery;
