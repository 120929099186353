import { Col, Row } from 'antd';
import { NewsPost } from 'apollo/types';
import React, { FC, useEffect, useState } from 'react';
import LoadingModal from 'components/generalUI/LoadingModal';
import useGetNewsPostQuery from 'apollo/resolvers/newsPosts/get';
import * as texts from '../../../assets/texts/newsPost';
import styles from './SubHeader.module.scss';
import { useNewsPostViewModel } from '../newsPost.viewModel';

export interface SubHeaderProps {
  newsPostId: string;
}

const SubHeader: FC<SubHeaderProps> = ({ newsPostId }: SubHeaderProps) => {
  const { isMobile, setNewsPostFromQuery } = useNewsPostViewModel();

  const [newsPost, setNewsPost] = useState<NewsPost>();
  const containerStyle = isMobile ? styles.ContainerMobile : styles.Container;

  const { data, loading } = useGetNewsPostQuery(newsPostId);

  useEffect(() => {
    setNewsPostFromQuery(data, setNewsPost);
  }, [data]);

  return (
    <>
      <LoadingModal visible={loading} />
      {newsPost && (
        <Row className={containerStyle} justify="center">
          <Col xs={18} lg={16} xxl={14}>
            <Row justify="space-evenly">
              <Col xs={24} md={12}>
                <p className={isMobile ? styles.TextMobile : styles.Text}>
                  {texts.author}: <b>{newsPost.author}</b>
                </p>
              </Col>
              <Col xs={24} sm={12}>
                <p className={styles.TextRight}>
                  {newsPost.readTime} {texts.minutesToRead}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SubHeader;
