import React, { FC } from 'react';
import { RightOutlined } from '@ant-design/icons';
import styles from './ExpandedIcon.module.scss';

interface Props {
  isActive?: boolean;
}

const ExpandedIcon: FC<Props> = ({ isActive }: Props) => {
  return <RightOutlined className={styles.expandedIcon} rotate={isActive ? 90 : 0} />;
};

ExpandedIcon.defaultProps = {
  isActive: false,
};

export default ExpandedIcon;
