import React, { ReactElement } from 'react';
import ImageAndTitle from 'components/WeAre/ImageAndTitle';
import Drop from 'components/sharedComponents/Drop';
import DirectorWords from 'components/WeAre/DirectorWords';
import SquareInformation from 'components/WeAre/SquareInformation';
import BussinessValues from 'components/WeAre/BussinessValues';
import History from 'components/WeAre/History';
import * as texts from 'assets/texts/weAre';
import style from './WeAre.module.scss';

const WeAre = (): ReactElement => {
  return (
    <div className={style.Container}>
      <ImageAndTitle />
      <Drop mainMessage={texts.mainMessage} />
      <DirectorWords />
      <SquareInformation />
      <BussinessValues />
      <History />
    </div>
  );
};

export default WeAre;
