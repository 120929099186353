import { gql, useQuery } from '@apollo/client';
import {
  EntranceTicketType,
  QueryListEntranceTicketTypesForPurchaseArgs,
} from 'apollo/generated/types';

const LIST_ENTRANCE_TICKET_TYPES_FOR_PURCHASE = gql`
  query listEntranceTicketTypesForPurchase($date: String!) {
    listEntranceTicketTypesForPurchase(date: $date) {
      adultValue
      description
      id
      maxStock
      name
      otherValue
      remaining
      status
      visibility
      entranceImages {
        image
      }
    }
  }
`;

const useListEntranceTicketTypesForPurchaseQuery = (date: string) => {
  return useQuery<
    { listEntranceTicketTypesForPurchase: EntranceTicketType[] },
    QueryListEntranceTicketTypesForPurchaseArgs
  >(LIST_ENTRANCE_TICKET_TYPES_FOR_PURCHASE, {
    variables: { date },
  });
};
export default useListEntranceTicketTypesForPurchaseQuery;
