/* eslint-disable import/prefer-default-export */

import { NewsPost, NewsPostContent } from 'apollo/types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Breakpoints } from 'utils/styles/breakpoints';
import useMaxBreakpoint from 'utils/styles/useMaxBreakpoint';

export const useNewsPostViewModel = () => {
  const history = useHistory();

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const useIsScreenMobile = () => {
    const currentBreakpoint = useMaxBreakpoint();
    if (currentBreakpoint <= Breakpoints.XS) {
      return true;
    }
    return false;
  };

  const isScreenMobile = useIsScreenMobile();

  const setNewsPostFromQuery = (
    data:
      | {
          getNewsPost: NewsPost;
        }
      | undefined,
    setNewsPost: (newsPost: NewsPost) => void
  ) => {
    if (data) {
      setNewsPost(data.getNewsPost);
    }
  };

  const orderContent = (content: NewsPostContent[]) => {
    const arrayToOrder = [...content];
    const orderedContent = arrayToOrder.sort((a, b) => a.order - b.order);
    return orderedContent;
  };

  const handleLeftIconClick = () => {
    history.push('/newsPosts');
  };

  useEffect(() => {
    setIsMobile(isScreenMobile);
  }, [isScreenMobile]);

  return {
    setNewsPostFromQuery,
    handleLeftIconClick,
    isMobile,
    orderContent,
  };
};
