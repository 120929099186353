export const giftCardTitle = 'Gift Card Tricao';
export const giftCardSubtitle =
  'Escoge una gift card digital para regalar a quien tu quieras.';
export const giftNatureTitle = '¡Regala naturaleza!';
export const giftNatureText =
  'Gift Card Parque Tricao te invita a regalar una experiencia auténtica y única a quien tú quieras. Con variadas actividades al aire libre y hermosos espacios para recorrer, este obsequio permite compartir una aventura rodeada de flora y fauna, creando recuerdos que perdurarán en el tiempo.';
export const configureGiftCardTitle = 'Configura tu Gift Card';
export const giftCardFormTitle = 'Datos de la Gift Card';
export const giftCardFromNameField = 'De:';
export const giftCardToNameField = 'Para:';
export const giftCardAmountField = 'Monto:';
export const requiredName = 'Nombre requerido';
export const requiredAmount = 'Monto requerido';
export const buyGiftCard = 'Comprar';
// eslint-disable-next-line no-template-curly-in-string
export const amountMustBeBetween = 'Monto debe ser entre 3.000 y 300.000';
export const detailsAndConditions = 'Detalles y Condiciones Gift Card';
export const detailsAndConditionsText =
  '1. La Gift Card tiene una vigencia de un año desde la fecha de compra. <br>2. El monto cargado en la Gift Card está destinado exclusivamente para la compra de servicios ofrecidos a través de la página web, tales como entradas y quinchos, según disponibilidad. <br>3. El saldo ingresado no es aplicable para servicios físicos como el bazar, restaurante del parque, entre otros. <br>4. En caso de no utilizar el monto completo durante una compra, el saldo restante puede ser empleado en compras posteriores, dentro del plazo de vigencia establecido. Para ello, se debe utilizar el mismo código y clave proporcionados junto a la Gift Card.<br> 5. No se realizarán reembolsos totales ni parciales por saldo no usado dentro del plazo de vigencia, ni por extravío de código y clave para hacer uso de la Gift Card. <br>6. La Gift Card corresponde a un servicio virtual, no se emite una tarjeta física. <br>7. Parque Tricao no asume responsabilidad por el uso indebido de la Gift Card.';
