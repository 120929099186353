import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Button, Carousel } from 'antd';
import Binoculars from 'assets/images/activityIcon.png';
import { ThingToDo } from 'apollo/types';
import useMaxBreakpoint from 'utils/styles/useMaxBreakpoint';
import { Breakpoints } from 'utils/styles/breakpoints';
import ActivityDetails from './ActivityDetails';
import styles from './Activities.module.scss';

interface PlaceProps {
  thingsToDo: ThingToDo[];
}

const ThingsToDo: FC<PlaceProps> = (props: PlaceProps) => {
  const { thingsToDo } = props;
  const [selectedThingToDoId, setSelectedThingToDoId] = useState<number>();
  const [slidesToShow, setSlidesToShow] = useState<number>(3);
  const currentBreakpoint = useMaxBreakpoint();

  const setSlideToShow = () => {
    if (currentBreakpoint <= Breakpoints.XS) {
      setSlidesToShow(2);
    } else if (currentBreakpoint <= Breakpoints.SM) {
      setSlidesToShow(3);
    } else if (currentBreakpoint <= Breakpoints.MD) {
      setSlidesToShow(4);
    } else if (currentBreakpoint <= Breakpoints.LG) {
      setSlidesToShow(5);
    } else {
      setSlidesToShow(6);
    }
  };

  useEffect(() => {
    setSlideToShow();
  }, [currentBreakpoint]);

  return (
    <div className={styles.Container}>
      <div className={styles.ScrollableList}>
        <Carousel
          dots={false}
          slidesToShow={slidesToShow}
          draggable
          swipeToSlide
          touchThreshold={50}
          focusOnSelect
          infinite
          autoplay
        >
          {thingsToDo?.map((thingToDo, index) => {
            const thingsToDoIcon = thingToDo.icon as string | undefined;
            return (
              <Button
                type="primary"
                onClick={() => setSelectedThingToDoId(index)}
                className={
                  index === selectedThingToDoId
                    ? styles.SelectedButton
                    : styles.UnselectedButton
                }
                key={thingToDo.name}
              >
                <Row align="middle" justify="center">
                  <Col span={24}>
                    {thingsToDoIcon ? (
                      // eslint-disable-next-line react/no-danger
                      <div dangerouslySetInnerHTML={{ __html: thingsToDoIcon }} />
                    ) : (
                      <>
                        <img alt="activity Icon" src={Binoculars} />
                        <br />
                      </>
                    )}
                    <p>{thingToDo.name}</p>
                  </Col>
                </Row>
              </Button>
            );
          })}
        </Carousel>
      </div>
      <div>
        <ActivityDetails
          thingToDo={selectedThingToDoId ? thingsToDo[selectedThingToDoId] : undefined}
        />
      </div>
    </div>
  );
};

export default ThingsToDo;
