import React, { useState, useEffect, FC } from 'react';
import { Place } from 'apollo/types';
import useListPlacesQuery from 'apollo/resolvers/places/list';
import { Row, Col } from 'antd';
import LoadingModal from 'components/generalUI/LoadingModal';
import * as texts from 'assets/texts/home';
import styles from './Places.module.scss';
import PlacesCards from './PlacesCards';
import TricaoMap from './TricaoMap';

const Places: FC = () => {
  const { data, loading } = useListPlacesQuery();

  const [places, setPlaces] = useState<Place[]>();

  const sortPlacesWhereGrillIsThirdPlace = (placesToSort: Place[] | undefined) => {
    if (!placesToSort) {
      return [];
    }

    const sortedPlacesWhereGrillIsThird = [...placesToSort];

    const grillPlace = sortedPlacesWhereGrillIsThird.find(
      (place) => place.name === 'Quinchos'
    );

    const removedElement = sortedPlacesWhereGrillIsThird.splice(
      sortedPlacesWhereGrillIsThird.indexOf(grillPlace as Place),
      1
    );

    sortedPlacesWhereGrillIsThird.splice(2, 0, removedElement[0]);

    return sortedPlacesWhereGrillIsThird;
  };

  useEffect(() => {
    if (data) {
      setPlaces(data.listPlaces);
    }
  }, [data]);

  return (
    <div id="places">
      <LoadingModal visible={loading} />
      <Row className={styles.Container}>
        <Col className={styles.InfoContainer} span={24}>
          <h1 className={styles.Title}>{texts.placesInfoTitle}</h1>
        </Col>
        <Col span={24}>
          <div className={styles.MapCardsContainer}>
            <Col className={styles.MapContainer} sm={24} md={12}>
              <TricaoMap />
            </Col>
            <Col className={styles.CardsContainer} sm={24} md={12}>
              <PlacesCards places={sortPlacesWhereGrillIsThirdPlace(places)} />
            </Col>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Places;
