import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styles from './ScrollableItem.module.scss';

interface Props {
  imageUrl: string;
  title?: string;
  subTitle?: string;
  isForGrill?: boolean;
  scrollableImageClassName: string;
  index?: number;
}

const ScrollableItem: FC<Props> = ({
  imageUrl,
  title,
  subTitle,
  isForGrill,
  scrollableImageClassName,
  index,
}: Props) => {
  const getImageClassName = () => {
    if (index === undefined) {
      return scrollableImageClassName;
    }

    return index % 2 === 0
      ? `${scrollableImageClassName} ${styles.RotateRight}`
      : `${scrollableImageClassName} ${styles.RotateLeft}`;
  };

  return (
    <div className={isForGrill ? styles.GrillScrollableItem : styles.ScrollableItem}>
      <Row justify="end" align="middle">
        <Col push={1} span={23}>
          <div className={styles.ScrollableImage}>
            <img className={getImageClassName()} src={imageUrl} alt={title} />
          </div>
        </Col>
        {title && subTitle && (
          <Col pull={23} span={1}>
            <div className={styles.TextContainer}>
              <h3 className={styles.Title}>{title}</h3>
              <p className={styles.SubTitle}>{subTitle}</p>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

ScrollableItem.defaultProps = {
  title: undefined,
  subTitle: undefined,
  isForGrill: false,
  index: undefined,
};

export default ScrollableItem;
