import { gql, useQuery } from '@apollo/client';
import { Program } from 'apollo/generated/types';

const LIST_PROGRAMS = gql`
  query listPrograms {
    listPrograms {
      id
      title
      subtitle
      description
      coverImage {
        url
      }
    }
  }
`;
const useListProgramsQuery = () => {
  return useQuery<{ listPrograms: Program[] }>(LIST_PROGRAMS, {});
};
export default useListProgramsQuery;
