import { gql, useQuery } from '@apollo/client';
import { ThingToDo } from 'apollo/generated/types';

const LIST_THINGS_TO_DO = gql`
  query listThingsToDo {
    listThingsToDo {
      id
      name
      description
      icon
      thingToDoImages {
        image
      }
    }
  }
`;
const useListThingsToDoQuery = () => {
  return useQuery<{ listThingsToDo: ThingToDo[] }>(LIST_THINGS_TO_DO, {});
};
export default useListThingsToDoQuery;
