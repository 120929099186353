import { Col, Row } from 'antd';
import React, { FC, useState } from 'react';
import LoadingModal from 'components/generalUI/LoadingModal';
import GrillDetails from 'components/Grills/GrillDetails';
import Activities from 'components/sharedComponents/Activities';
import Header from 'components/sharedComponents/Header';
import * as texts from 'assets/texts/grills';
import useListActivitiesWithoutTimes from 'apollo/resolvers/activities/listWithoutTimes';
import getNow from 'utils/dates/getNow';
import styles from './Grills.module.scss';

const Grills: FC = () => {
  const [now] = useState(getNow());

  const { data: grills, loading } = useListActivitiesWithoutTimes(now, '2');

  return (
    <div className={styles.Container}>
      <Header
        title={texts.title}
        image={texts.backGround}
        letter="A"
        description={texts.description}
      />
      <LoadingModal visible={loading} />
      <GrillDetails grills={grills ? grills.listActivities : []} />
      <Row justify="center">
        <Col xs={20} sm={15} lg={10}>
          <h2 className={styles.Description}>{texts.vacancyReminder}</h2>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} sm={15} lg={10}>
          <ul className={styles.List}>
            <li>{texts.grillValueText}</li>
            <li>{texts.garbageReminder}</li>
            <li>{texts.fireOnGrill}</li>
            <li>{texts.extras}</li>
          </ul>
        </Col>
      </Row>
      <Activities />
    </div>
  );
};

export default Grills;
