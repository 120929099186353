import { gql, useMutation } from '@apollo/client';
import { MutationSendMailProgramArgs, Success } from 'apollo/types';

const SEND_EMAIL = gql`
  mutation sendMailProgram($input: SendMailProgramInput!) {
    sendMailProgram(input: $input) {
      success
    }
  }
`;

const useSendEmailProgramMutation = () => {
  return useMutation<{ sendMailProgram: Success }, MutationSendMailProgramArgs>(
    SEND_EMAIL,
    {}
  );
};

export default useSendEmailProgramMutation;
