import React from 'react';
import { useLocation } from 'react-router-dom';

const webpayQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const getIsWebpayErrorSearchParam = (): boolean => {
  const query = webpayQuery();
  const isWebpayErrorString = query.get('isWebpayError');
  const isWebpayError = isWebpayErrorString === 'true';

  return isWebpayError;
};

export default getIsWebpayErrorSearchParam;
