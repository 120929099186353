import React, { useState, useEffect, useRef, FC } from 'react';
import { Col, Row, Modal } from 'antd';
import { chunk } from 'lodash';
import * as texts from 'assets/texts/home';
import fetchInstagramFeed from 'utils/services/instagram';
import Feed from './Feed';
import styles from './InstaFeed.module.scss';
import { Feeds, InstaFeedProps } from './types';
import Post from './Post';

const InstaFeeds: FC<InstaFeedProps> = ({ token, ...props }) => {
  const [feeds, setFeedsData] = useState<Feeds[]>([]);
  const [permalink, setPermalink] = useState<string>('');
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  // use useRef to store the latest value of the prop without firing the effect
  const tokenProp = useRef(token);
  tokenProp.current = token;

  function setPost(perma_link: string) {
    setPermalink(perma_link);
    if (window.innerWidth <= 768) {
      setModalVisibility(true);
    }
  }

  const fetchInstagramFeedData = async () => {
    try {
      const response = await fetchInstagramFeed();
      setFeedsData(response.data.data);
      return response;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchInstagramFeedData();
  }, [props.limit]);
  const chunkedFeeds = chunk(feeds, 3);

  return (
    <div className={styles.Container}>
      <Row justify="space-around">
        <Col xs={18} sm={16} md={12} lg={10} xl={8}>
          <p className={styles.MainText}>{texts.tricaoLife}</p>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col xs={18} sm={16} md={12} lg={10} xl={8}>
          <p className={styles.SecondaryText}>{texts.followUs}</p>
        </Col>
      </Row>
      <Row className={styles.FeedContainer}>
        <Col className={styles.PostsContainer} xs={{ span: 24 }} sm={{ span: 12 }}>
          {chunkedFeeds.map((feedArray) => (
            <Row key={feedArray[0].id}>
              {feedArray.map((feed, index) => (
                <Col span={8} key={feedArray[index].id}>
                  <Feed
                    key={feedArray[index].id}
                    className={permalink === feed.permalink ? 'selected' : ''}
                    feed={feed}
                    callBackFunction={(perma_link: string) => {
                      setPost(perma_link);
                    }}
                  />
                </Col>
              ))}
            </Row>
          ))}
        </Col>
        {permalink && process.env.REACT_APP_POST_INSTAGRAM_TOKEN && (
          <>
            <Col className={styles.InstagramPost}>
              <Row>
                <Post
                  permalink={permalink}
                  access_token={process.env.REACT_APP_POST_INSTAGRAM_TOKEN}
                />
              </Row>
            </Col>
            <Col>
              <Modal
                footer={null}
                className={styles.InstagramPostModalBody}
                closable={false}
                visible={modalVisibility}
                onCancel={() => {
                  setModalVisibility(false);
                  setPermalink('');
                }}
              >
                <Post
                  permalink={permalink}
                  access_token={process.env.REACT_APP_POST_INSTAGRAM_TOKEN}
                />
              </Modal>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default InstaFeeds;
