import { gql, useMutation } from '@apollo/client';
import {
  CorporateEventContactOutput,
  MutationSendCorporateEventsEmailArgs,
} from 'apollo/types';

const SEND_EMAIL = gql`
  mutation sendCorporateEventsEmail($input: CorporateEventContactInput!) {
    sendCorporateEventsEmail(input: $input) {
      response
    }
  }
`;

const useSendCorporateEventsEmail = () => {
  return useMutation<
    { sendCorporateEventsEmail: CorporateEventContactOutput },
    MutationSendCorporateEventsEmailArgs
  >(SEND_EMAIL, {});
};

export default useSendCorporateEventsEmail;
