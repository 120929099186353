import Button from 'components/generalUI/Button';
import React, { FC } from 'react';
import { Col, Row } from 'antd';
import TikTokBoxedIcon from 'assets/images/tiktok-boxed-icon.svg';
import YoutubeBoxedIcon from 'assets/images/youtube-boxed-icon copy.svg';
import * as texts from 'assets/texts/shareLinks';
import styles from './RedirectionButtonsSection.module.scss';

const RedirectionButtonsSection: FC = () => {
  return (
    <Row className={styles.Container} justify="center" gutter={[0, 12]}>
      <Col span={24}>
        <Button className={styles.SecondaryButton} href="/somos" kind="ghost">
          {texts.tricaoPark}
        </Button>
      </Col>
      <Col span={24}>
        <Button className={styles.SecondaryButton} href="/place/3" kind="ghost">
          {texts.aviary}
        </Button>
      </Col>
      <Col span={24}>
        <Button className={styles.SecondaryButton} href="/place/4" kind="ghost">
          {texts.vivarium}
        </Button>
      </Col>
      <Col span={24}>
        <Button className={styles.SecondaryButton} href="/prices" kind="ghost">
          {texts.scheduleAndPrices}
        </Button>
      </Col>
      <Col span={24}>
        <Button className={styles.SecondaryButton} href="/somos" kind="ghost">
          {texts.rehabilitationCenter}
        </Button>
      </Col>

      <Col span={24}>
        <Row className={styles.SocialMediaContainer} justify="center" gutter={[0, 8]}>
          <Col span={24}>{texts.followUs}</Col>
          <Col span={24}>
            <Row justify="center" gutter={[16, 0]}>
              <Col>
                <a href="https://www.tiktok.com/@parquetricao?is_from_webapp=1&sender_device=pc">
                  <img src={TikTokBoxedIcon} alt="tiktok" />
                </a>
              </Col>
              <Col>
                <a href="https://www.youtube.com/watch?v=nAzfUkn_ml4&list=PLLkPT7eQTByG4-hxMR3odM1T7OIj_FBL4&index=1">
                  <img src={YoutubeBoxedIcon} alt="youtube" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RedirectionButtonsSection;
