import React, { FC } from 'react';
import { Col, Row, Tooltip } from 'antd';
import Button from 'components/generalUI/Button';
import BottomAffix from 'components/generalUI/BottomAffix';
import priceFormat from 'utils/formats/priceFormat';
import styles from './BottomSummary.module.scss';

interface Props {
  total: number;
  totalMessage: string;
  buttonMessage: string;
  buttonOnClick: React.MouseEventHandler<HTMLElement>;
  isAffix: boolean;
  isDisabled?: boolean;
  disabledMessage?: string;
}

const BottomSummary: FC<Props> = ({
  total,
  totalMessage,
  buttonMessage,
  buttonOnClick,
  isAffix,
  isDisabled,
  disabledMessage,
}: Props) => {
  const renderPaymentButton = () => {
    return (
      <Col xs={11} md={4} xl={3}>
        <Button
          className={styles.ContinueButton}
          onClick={buttonOnClick}
          disabled={isDisabled}
        >
          {buttonMessage}
        </Button>
      </Col>
    );
  };
  const content = (
    <Row className={styles.TotalContainer} align="middle" justify="center">
      <Col className={styles.TotalDetail} xs={11} md={16} xl={13}>
        <p className={styles.SubTotal}>{totalMessage}</p>
        <h2>{priceFormat.format(total)}</h2>
      </Col>
      {isDisabled ? (
        <Tooltip overlayClassName={styles.AlertTooltip} title={disabledMessage}>
          {renderPaymentButton()}
        </Tooltip>
      ) : (
        <>{renderPaymentButton()}</>
      )}
    </Row>
  );
  return isAffix ? <BottomAffix>{content}</BottomAffix> : content;
};

BottomSummary.defaultProps = {
  isDisabled: false,
  disabledMessage: '',
};

export default BottomSummary;
