import { gql, useMutation } from '@apollo/client';
import { AuthenticateUserInput } from 'apollo/types';

const AUTHENTICATE_USER = gql`
  mutation authenticateUser(
    $email: String!
    $documentIsPassport: Boolean!
    $documentNumber: String!
  ) {
    authenticateUser(
      input: {
        email: $email
        documentIsPassport: $documentIsPassport
        documentNumber: $documentNumber
      }
    ) {
      success
    }
  }
`;

const useAuthenticateUserMutation = () => {
  return useMutation<{ authenticateUser: { success: boolean } }, AuthenticateUserInput>(
    AUTHENTICATE_USER
  );
};
export default useAuthenticateUserMutation;
