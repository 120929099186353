import useListParkAvailabilityQuery from 'apollo/resolvers/parkAvailability/list';
import { ParkAvailability } from 'apollo/types';
import LoadingModal from 'components/generalUI/LoadingModal';
import React, { ReactElement, useEffect, useState } from 'react';
import { title, content } from '../../assets/texts/faq';
import Collapse from '../../components/Faq/Collapse';
import styles from './Faq.module.scss';

const Faq = (): ReactElement => {
  const [parkAvailability, setParkAvailability] = useState<ParkAvailability[]>([]);
  const { data: parkAvailabilityData, loading } = useListParkAvailabilityQuery();

  const setParkAvailabilityFromQuery = () => {
    if (parkAvailabilityData) {
      setParkAvailability(parkAvailabilityData.listParkAvailability);
    }
  };

  useEffect(() => {
    setParkAvailabilityFromQuery();
  }, [parkAvailabilityData]);

  return (
    <>
      {parkAvailability.length > 0 ? (
        <>
          <h1 className={styles.title}>{title}</h1>
          <Collapse content={content(parkAvailability)} />
        </>
      ) : (
        <LoadingModal visible={loading} />
      )}
    </>
  );
};

export default Faq;
