/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect, useState } from 'react';
import { Card, Collapse } from 'antd';
import priceFormat from 'utils/formats/priceFormat';
import * as texts from 'assets/texts/schedulesAndPrices';
import placeholderImage from 'assets/images/placeholder-image.png';
import useListGrillsQuery from 'apollo/resolvers/grills/list';
import { Place } from 'apollo/types';
import useGetPlaceQuery from 'apollo/resolvers/places/get';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import styles from './GrillCard.module.scss';

const GRILL_PLACE_ID = '12';

const GrillCard: FC = () => {
  const { Meta, Grid } = Card;
  const [firstGrill, setFirstGrill] = useState<Place>();
  const [place, setPlace] = useState<Place>();
  const [panelHeaderText, setPanelHeaderText] = React.useState<string>(
    texts.whatIncludes
  );

  const { data: grillsData } = useListGrillsQuery();
  const { data: placeData } = useGetPlaceQuery(GRILL_PLACE_ID);

  const firstParagraphDescription = (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: place?.secondaryDescription || '' }}
    />
  );
  const placeholder = (
    <img className={styles.CardImage} src={placeholderImage} alt="placeholder" />
  );
  const title = (
    <>
      <h3>{place?.name}</h3>
      <hr className={styles.BodyHorizontalLine} />
    </>
  );

  const handlePanelClick = (key: string | string[]) => {
    const isPanelOpen = key.length > 0;
    setPanelHeaderText(isPanelOpen ? texts.showLess : texts.whatIncludes);
  };

  useEffect(() => {
    if (grillsData?.listGrills) {
      setFirstGrill(grillsData.listGrills[0]);
    }
  }, [grillsData]);

  useEffect(() => {
    if (placeData?.getPlace) {
      setPlace(placeData?.getPlace);
    }
  }, [placeData]);

  return (
    <div className={styles.Container}>
      <Card
        className={styles.Card}
        bordered={false}
        cover={
          place?.placeMainImage ? (
            <img
              className={styles.CardImage}
              alt="Place"
              src={place?.placeMainImage || ''}
            />
          ) : (
            placeholder
          )
        }
      >
        <Meta className={styles.CardText} title={title} />

        <Grid style={{ width: '100%' }} className={styles.PriceDetail} hoverable={false}>
          <p>{texts.value}</p>
          <h3 className={styles.Price}>
            {priceFormat.format(firstGrill?.activities?.value || 0)}
          </h3>
        </Grid>
        <Grid className={styles.DescriptionSpace} hoverable={false}>
          <Collapse ghost onChange={handlePanelClick}>
            <CollapsePanel
              className={styles.ShowMoreCollapse}
              key="1"
              header={panelHeaderText}
            >
              <p className={styles.Description}>{firstParagraphDescription}</p>
            </CollapsePanel>
          </Collapse>
        </Grid>
      </Card>
    </div>
  );
};

export default GrillCard;
