import React, { FC, useState, useEffect } from 'react';
import { Col, Collapse, Radio, RadioChangeEvent, Row, Spin, Tooltip } from 'antd';
import { Activity } from 'apollo/types';
import priceFormat from 'utils/formats/priceFormat';
import useListActivitiesQuery from 'apollo/resolvers/activities/list';
import * as texts from 'assets/texts/selectTickets';
import ExpandedIcon from 'components/Faq/Collapse/ExpandedIcon';
import { ActivityPurchase } from 'storage/types';
import getDateSearchParam from 'utils/dates/getDateSearchParam';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './SelectGrill.module.scss';

interface SelectGrillProps {
  title: string;
  setGrillData: React.Dispatch<React.SetStateAction<ActivityPurchase | null>>;
  setGrillTotal?: React.Dispatch<React.SetStateAction<number>>;
  grillId: string | undefined;
  initiallyOpen?: boolean;
  showPrice?: boolean;
}

const SelectGrill: FC<SelectGrillProps> = (props: SelectGrillProps) => {
  const { setGrillData, setGrillTotal, grillId, showPrice, title, initiallyOpen } = props;
  const { Panel } = Collapse;

  const date = getDateSearchParam();
  const { data, loading } = useListActivitiesQuery(date, '2');

  const [activityId, setActivityId] = useState<string>(
    grillId ? grillId.toString() : '0'
  );
  const [grills, setGrills] = useState<Activity[]>();

  useEffect(() => {
    if (data) {
      setGrills(data.listActivities);
    }
  }, [data]);

  let defaultActiveKey: string[] = [];
  if (initiallyOpen) {
    defaultActiveKey = ['grills'];
  } else if (grillId) {
    defaultActiveKey = ['grills'];
  }

  const isGrillAlreadyBooked = (grill: Activity) => {
    if (grill.activityTimes.length) {
      if (grill.activityTimes[0].remaining && grill.activityTimes[0].remaining > 0) {
        return false;
      }
    }
    return true;
  };

  const onChange = (event: RadioChangeEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setActivityId(event.target.value);
    const grill = grills?.find(({ id }) => id === event.target.value);

    if (grill) {
      const newActivity = {
        id: grill.id,
        ticketCount: 1,
        activityTimeId: grill.activityTimes[0].id,
        name: grill.name,
        value: grill.value,
      };
      setGrillData(newActivity);
    } else setGrillData(null);
    if (setGrillTotal) {
      setGrillTotal(grill?.value || 0);
    }
  };

  const renderTooltipTitle = () => {
    return (
      <>
        <p>
          <span>{texts.grillInformation}</span>
        </p>
        <p className={styles.MoreInformationContainer}>
          <a href="/places/12" className={styles.Href}>
            {texts.moreInformation}
          </a>
        </p>
      </>
    );
  };

  const renderGrillHeader = () => {
    return (
      <Row className={styles.FullWidth}>
        <Col span={24}>
          <Row align="middle">
            <Col span={20}>
              <h2 className={styles.CollapseTitle}>{title}</h2>
            </Col>
            <Col span={4}>
              <Tooltip title={renderTooltipTitle()}>
                <span className={styles.TooltipContainer}>
                  <InfoCircleOutlined className={styles.CollapseIcon} />
                </span>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Collapse
      className={styles.Collapse}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition="right"
      expandIcon={ExpandedIcon}
    >
      <Panel key="grills" header={renderGrillHeader()}>
        <Row justify="center" className={styles.FullWidth}>
          <Col span={24} className={styles.FullWidth}>
            <Spin spinning={loading} />
          </Col>
        </Row>
        {grills && grills.length ? (
          <>
            {showPrice && (
              <h2 className={styles.PriceDetail}>
                {priceFormat.format(grills[0].value || 0)}
              </h2>
            )}
            <Radio.Group onChange={(event) => onChange(event)} value={activityId}>
              <Radio className={styles.RadioButton} value="0" key="0">
                <p>{texts.none}</p>
              </Radio>
              {grills.map(
                (grill) =>
                  grill &&
                  grill.status && (
                    <Radio
                      disabled={isGrillAlreadyBooked(grill)}
                      className={styles.RadioButton}
                      key={grill.id}
                      value={grill.id}
                    >
                      <p>
                        {grill.name} {isGrillAlreadyBooked(grill) && texts.reserved}
                      </p>
                    </Radio>
                  )
              )}
            </Radio.Group>
          </>
        ) : (
          texts.noGrills
        )}
      </Panel>
    </Collapse>
  );
};

SelectGrill.defaultProps = {
  setGrillTotal: undefined,
  showPrice: true,
  initiallyOpen: false,
};

export default SelectGrill;
