import { GiftCardPurchase } from 'storage/types';
import React, { FC } from 'react';
import priceFormat from 'utils/formats/priceFormat';
import * as texts from 'assets/texts/purchaseSummary';
import { Row, Col, Card } from 'antd';
import styles from './GiftCardSummaryCard.module.scss';

interface Props {
  item: GiftCardPurchase;
}

const GiftCardSummaryCard: FC<Props> = ({ item }: Props) => {
  return (
    <Row justify="center">
      <Col xs={20} lg={15}>
        <Card className={styles.Card}>
          <h2 className={styles.Title}>{texts.giftCard}</h2>
          <p>
            {texts.from} {item.from}
          </p>
          <p>
            {texts.to} {item.to}
          </p>
          <p className={styles.SubTotal}>
            {texts.subTotal}
            {priceFormat.format(Number(item.amount))}
          </p>
        </Card>
      </Col>
    </Row>
  );
};

export default GiftCardSummaryCard;
