import { Col, Row } from 'antd';
import useGetProgramQuery from 'apollo/resolvers/programs/get';
import { Program } from 'apollo/types';
import React, { FC, useEffect, useState } from 'react';
import LoadingModal from 'components/generalUI/LoadingModal';
import * as texts from '../../../assets/texts/program';
import { useProgramViewModel } from '../program.viewModel';
import styles from './SubHeader.module.scss';

export interface SubHeaderProps {
  programId: string;
}

const SubHeader: FC<SubHeaderProps> = ({ programId }: SubHeaderProps) => {
  const { setProgramFromQuery, isMobile } = useProgramViewModel();
  const [program, setProgram] = useState<Program>();

  const containerStyle = isMobile ? styles.ContainerMobile : styles.Container;
  const { data, loading } = useGetProgramQuery(programId);

  useEffect(() => {
    setProgramFromQuery(data, setProgram);
  }, [data]);

  return (
    <>
      <LoadingModal visible={loading} />
      {program && (
        <Row className={containerStyle} justify="center">
          <Col xs={18} lg={16} xxl={14}>
            <Row justify="space-between">
              <Col xs={24} sm={12}>
                <p className={isMobile ? styles.TextMobile : styles.Text}>
                  {texts.estimatedDuration}:{' '}
                  <b>
                    {program.hoursDuration} {texts.hours}
                  </b>
                </p>
              </Col>
              <Col xs={24} sm={12}>
                <p className={styles.TextRight}>
                  {texts.age}: <b>{program.targetGroup}</b>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SubHeader;
