import { gql, useQuery } from '@apollo/client';
import { OpenDay, QueryListOpenDaysArgs } from 'apollo/generated/types';

const LIST_OPEN_DAYS = gql`
  query listOpenDays($startDate: String, $endDate: String) {
    listOpenDays(endDate: $endDate, startDate: $startDate) {
      day
      id
    }
  }
`;

const useListOpenDaysQuery = (startDate?: string, endDate?: string) => {
  return useQuery<{ listOpenDays: OpenDay[] }, QueryListOpenDaysArgs>(LIST_OPEN_DAYS, {
    variables: { startDate, endDate },
  });
};

export default useListOpenDaysQuery;
