import React, { ReactElement } from 'react';
import { Col, Row } from 'antd';
import { squareInformation } from 'assets/texts/weAre';
import parse from 'html-react-parser';
import styles from './SquareInformation.module.scss';

const SquareInformation = (): ReactElement => {
  return (
    <Row className={styles.Container}>
      {squareInformation.map(({ title, icon, text }) => {
        return (
          <Col xs={24} sm={8} key={title}>
            <div className={styles.SquareContainer}>
              <div className={styles.TitleContainer}>
                <img src={icon} alt="titleIcon" className={styles.Icon} />
                <h2 className={styles.Title}>{title}</h2>
              </div>
              <p className={styles.Text}>{parse(text)}</p>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default SquareInformation;
