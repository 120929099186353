import React, { FC, useEffect, useState } from 'react';
import { Program } from 'apollo/types';
import useGetProgramQuery from 'apollo/resolvers/programs/get';
import LoadingModal from 'components/generalUI/LoadingModal';
import { Col, Row } from 'antd';
import YoutubeIcon from 'assets/images/youtube-black.svg';
import * as texts from 'assets/texts/program';
import { useProgramViewModel } from '../program.viewModel';
import styles from './Body.module.scss';
import { ImageGallery } from './ImageGallery';
import { Form } from './Form';

/* eslint-disable import/prefer-default-export */
export interface BodyProps {
  programId: string;
}

export const Body: FC<BodyProps> = ({ programId }: BodyProps) => {
  const { setProgramFromQuery } = useProgramViewModel();
  const [program, setProgram] = useState<Program>();

  const { data, loading } = useGetProgramQuery(programId);

  useEffect(() => {
    setProgramFromQuery(data, setProgram);
  }, [data]);

  return (
    <>
      <LoadingModal visible={loading} />
      {program && (
        <>
          <Row justify="center">
            <Col xs={18} md={14} lg={12}>
              <Row>
                <Col span={24}>
                  <div
                    className={styles.TextContainer}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: program.description }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center" className={styles.Row}>
            {program.videoLink && (
              <Col xs={18} md={14} lg={12}>
                <p className={styles.YoutubeText}>
                  <a
                    className={styles.Href}
                    href={program.videoLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {texts.youtubeLink} {program.title}
                  </a>
                  <a href={program.videoLink} target="_blank" rel="noreferrer">
                    <img
                      src={YoutubeIcon}
                      alt="youtube"
                      className={styles.SocialMediaIcon}
                    />
                  </a>
                </p>
              </Col>
            )}
          </Row>
          <Row justify="center">
            <Col xs={18} md={14} lg={12}>
              <ImageGallery programId={programId} />
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={18} md={14} lg={12}>
              <Form programId={programId} validForWeekends={program.validForWeekends} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
