/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  AWSDate: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  AWSDateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  AWSEmail: any;
  /** The AWSIPAddress scalar type represents a valid IPv4 or IPv6 address string. */
  AWSIPAddress: any;
  /** The AWSJSON scalar type represents a valid json object serialized as a string. */
  AWSJSON: any;
  /** AWSPhone */
  AWSPhone: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  AWSTime: any;
  /** The AWSTimestamp scalar type represents the number of seconds that have elapsed since 1970-01-01T00:00Z. Timestamps are serialized and deserialized as numbers. Negative values are also accepted and these represent the number of seconds till 1970-01-01T00:00Z. */
  AWSTimestamp: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  AWSURL: any;
};

export type Activity = {
  __typename?: 'Activity';
  activityImages: Array<ActivityImage>;
  activityTimes: Array<ActivityTime>;
  activityTypeId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  hasSingleTimetable: Scalars['Boolean'];
  id: Scalars['ID'];
  maxStock: Scalars['Int'];
  name: Scalars['String'];
  paxPerTicket: Scalars['Int'];
  place: Place;
  status: Scalars['Boolean'];
  value: Scalars['Int'];
};

export type ActivityImage = {
  __typename?: 'ActivityImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
};

export type ActivityTicket = {
  __typename?: 'ActivityTicket';
  activityTimeId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['Int']>;
  validityDate?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ActivityTicketsInput = {
  activityId: Scalars['ID'];
  activityTimeId: Scalars['ID'];
  newActivityTimeId: Scalars['ID'];
  newDate: Scalars['String'];
  ticketCount: Scalars['Int'];
};

export type ActivityTime = {
  __typename?: 'ActivityTime';
  activity?: Maybe<Activity>;
  dailyAvailability?: Maybe<Scalars['Int']>;
  endHour: Scalars['String'];
  id: Scalars['ID'];
  isWinterTime?: Maybe<Scalars['Boolean']>;
  openActivityTimetablesPerDay?: Maybe<Array<Maybe<OpenActivityTimetablesPerDay>>>;
  parkAvailabilityId?: Maybe<Scalars['ID']>;
  remaining?: Maybe<Scalars['Int']>;
  startHour: Scalars['String'];
};

export type ActivityType = {
  __typename?: 'ActivityType';
  activities?: Maybe<Array<Maybe<Activity>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type AgeRange = {
  __typename?: 'AgeRange';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AuthenticateUserInput = {
  documentIsPassport: Scalars['Boolean'];
  documentNumber: Scalars['String'];
  email: Scalars['String'];
};

export type BillingDocument = {
  __typename?: 'BillingDocument';
  documentNumber?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  type: BillingDocumentType;
};

export enum BillingDocumentType {
  Invoice = 'Invoice',
  Voucher = 'Voucher',
}

export type BillingSystemCity = {
  __typename?: 'BillingSystemCity';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type BillingSystemCommune = {
  __typename?: 'BillingSystemCommune';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type BillingSystemInputArgs = {
  billingDocumentType: BillingDocumentType;
  customerAddress?: InputMaybe<Scalars['String']>;
  customerBusinessActivity?: InputMaybe<Scalars['String']>;
  customerCityId?: InputMaybe<Scalars['Int']>;
  customerCommuneId?: InputMaybe<Scalars['Int']>;
  customerType?: InputMaybe<CustomerType>;
};

export type Client = {
  __typename?: 'Client';
  ageRange?: Maybe<AgeRange>;
  documentIsPassport?: Maybe<Scalars['Boolean']>;
  documentNumber: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  frequentVisitor?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type ClientReccurency = {
  __typename?: 'ClientReccurency';
  recurrentAnualClients: Scalars['Int'];
  totalAnualIndividualClients: Scalars['Int'];
  totalClients: Scalars['Int'];
};

export type Code = {
  __typename?: 'Code';
  code: Scalars['String'];
  id: Scalars['ID'];
  isUsed: Scalars['Boolean'];
  purchaseId?: Maybe<Scalars['Int']>;
};

export type ConfigurationParameter = {
  __typename?: 'ConfigurationParameter';
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ConfigurationParameters = {
  __typename?: 'ConfigurationParameters';
  cancellationPolicy: ConfigurationParameter;
  oversold: ConfigurationParameter;
  parkCapacity: ConfigurationParameter;
  risk: ConfigurationParameter;
  terms: ConfigurationParameter;
};

export type ContactUsInput = {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  theme: Scalars['String'];
};

export type ContactUsOutput = {
  __typename?: 'ContactUsOutput';
  response: Scalars['Boolean'];
};

export type CorporateEventContactInput = {
  amountOfPeople: Scalars['Int'];
  companyName: Scalars['String'];
  companyRut: Scalars['String'];
  contactName: Scalars['String'];
  email: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  tripDate: Scalars['String'];
  tripHour: Scalars['String'];
};

export type CorporateEventContactOutput = {
  __typename?: 'CorporateEventContactOutput';
  response: Scalars['Boolean'];
};

export type CreatePurchaseActivitiesInput = {
  activityTimeId: Scalars['ID'];
  id: Scalars['ID'];
  ticketCount: Scalars['Int'];
};

export type CreatePurchaseInputArgs = {
  activities: Array<CreatePurchaseActivitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  entrances: Array<GetTotalPriceEntrancesInput>;
  giftCard?: InputMaybe<UseGiftCardInput>;
  method?: InputMaybe<Scalars['String']>;
  packs: Array<CreatePurchasePacksInput>;
  totalPrice: Scalars['Int'];
};

export type CreatePurchasePacksInput = {
  activities: Array<CreatePurchaseActivitiesInput>;
  adultTicketCount: Scalars['Int'];
  id: Scalars['ID'];
  otherTicketCount: Scalars['Int'];
};

export enum CustomerType {
  Company = 'Company',
  Person = 'Person',
}

export type DetailedReservation = {
  __typename?: 'DetailedReservation';
  activityTickets: Array<DetailedReservationActivityTicket>;
  client: Client;
  entranceTickets: Array<DetailedReservationEntranceTicket>;
  purchaseId: Scalars['ID'];
};

export type DetailedReservationActivityTicket = {
  __typename?: 'DetailedReservationActivityTicket';
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  startHour: Scalars['String'];
  state: Scalars['Int'];
  subTotal: Scalars['Int'];
  validityDate: Scalars['String'];
};

export type DetailedReservationEntranceTicket = {
  __typename?: 'DetailedReservationEntranceTicket';
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  state: Scalars['Int'];
  ticketTypeId: Scalars['ID'];
  ticketTypeName: Scalars['String'];
  value: Scalars['Int'];
  visitDate: Scalars['String'];
};

export type DisablePurchaseHistory = {
  __typename?: 'DisablePurchaseHistory';
  createdAt?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userLastName?: Maybe<Scalars['String']>;
};

export type EditGrillResponse = {
  __typename?: 'EditGrillResponse';
  billingSystemProductId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mainDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  place?: Maybe<Place>;
  placeMainImage?: Maybe<Scalars['String']>;
  secondaryDescription?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  value: Scalars['Int'];
};

export type EditManyGrillsResponse = {
  __typename?: 'EditManyGrillsResponse';
  count?: Maybe<Scalars['Int']>;
};

export type EnrichedClient = {
  __typename?: 'EnrichedClient';
  activityTicketsCount?: Maybe<Scalars['Int']>;
  ageRange?: Maybe<AgeRange>;
  documentIsPassport?: Maybe<Scalars['Boolean']>;
  documentNumber: Scalars['String'];
  email: Scalars['String'];
  entranceTicketsCount?: Maybe<Scalars['Int']>;
  firstName: Scalars['String'];
  frequentVisitor?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  purchasesCount?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchase = {
  __typename?: 'EnrichedPurchase';
  activityTickets?: Maybe<Array<EnrichedPurchaseActivityTickets>>;
  activityTimes?: Maybe<Array<EnrichedPurchaseActivityTimes>>;
  createdAt?: Maybe<Scalars['String']>;
  entranceTickets?: Maybe<Array<EnrichedPurchaseEntranceTickets>>;
  giftCard?: Maybe<GiftCard>;
  giftCardPurchase?: Maybe<GiftCardPurchase>;
  grillTickets?: Maybe<Array<EnrichedPurchaseActivityTickets>>;
  id: Scalars['ID'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  packPurchases?: Maybe<Array<EnrichedPurchasePackPurchases>>;
  preDiscountPrice?: Maybe<Scalars['Int']>;
  visitDate?: Maybe<Scalars['String']>;
};

export type EnrichedPurchaseActivityTickets = {
  __typename?: 'EnrichedPurchaseActivityTickets';
  finalPrice: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  percentageDiscount?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchaseActivityTimes = {
  __typename?: 'EnrichedPurchaseActivityTimes';
  activityTime: ActivityTime;
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type EnrichedPurchaseBackOffice = {
  __typename?: 'EnrichedPurchaseBackOffice';
  billingDocument?: Maybe<BillingDocument>;
  client?: Maybe<Client>;
  disabledPurchaseHistory?: Maybe<DisablePurchaseHistory>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  preDiscountPrice?: Maybe<Scalars['Int']>;
  purchase?: Maybe<EnrichedPurchase>;
  purchaseQrScans: Array<Maybe<Scalars['String']>>;
  totalPrice?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchaseEntranceTickets = {
  __typename?: 'EnrichedPurchaseEntranceTickets';
  finalPrice: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  percentageDiscount?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchaseMailer = {
  __typename?: 'EnrichedPurchaseMailer';
  client?: Maybe<Client>;
  disabledPurchaseHistory?: Maybe<DisablePurchaseHistory>;
  purchase?: Maybe<EnrichedPurchase>;
  purchaseQrScans: Array<Maybe<Scalars['String']>>;
  qrCodeImage?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchasePackPurchases = {
  __typename?: 'EnrichedPurchasePackPurchases';
  finalPrice: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  percentageDiscount?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type EntranceImage = {
  __typename?: 'EntranceImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type EntranceSaleReport = {
  __typename?: 'EntranceSaleReport';
  entrancesByNameMonth: Array<GroupedByNameMonth>;
  packsEntrancesByNameMonth: Array<PacksGroupedByNameMonth>;
};

export type EntranceTicket = {
  __typename?: 'EntranceTicket';
  endPeriodTime: Scalars['String'];
  entranceTicketTypeName?: Maybe<Scalars['String']>;
  entranceTicketTypesId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isValid?: Maybe<Scalars['Boolean']>;
  purchaseId?: Maybe<Scalars['ID']>;
  qrCode?: Maybe<Scalars['String']>;
  startPeriodTime: Scalars['String'];
  state?: Maybe<Scalars['Int']>;
  typePersonId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Int']>;
};

export type EntranceTicketType = {
  __typename?: 'EntranceTicketType';
  adultValue: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  entranceImages: Array<EntranceImage>;
  entranceTickets?: Maybe<Array<Maybe<EntranceTicket>>>;
  id: Scalars['ID'];
  isBicycle?: Maybe<Scalars['Boolean']>;
  maxStock: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  otherValue: Scalars['Int'];
  remaining: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type EntranceTicketTypeBackOffice = {
  __typename?: 'EntranceTicketTypeBackOffice';
  adultValue: Scalars['Int'];
  billingSystemProductId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  entranceImages: Array<EntranceImage>;
  id: Scalars['ID'];
  isBicycle?: Maybe<Scalars['Boolean']>;
  maxStock?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  otherValue: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type EntranceTicketsInput = {
  adultTicketCount: Scalars['Int'];
  entranceTicketTypeId?: InputMaybe<Scalars['ID']>;
  newDate: Scalars['String'];
  otherTicketCount: Scalars['Int'];
};

export type Experience = {
  __typename?: 'Experience';
  activityImages?: Maybe<Array<Maybe<ActivityImage>>>;
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export enum FilterType {
  BillingSystemDocumentNumber = 'billingSystemDocumentNumber',
  BillingSystemDocumentType = 'billingSystemDocumentType',
  Date = 'date',
  Disabled = 'disabled',
  DocumentNumber = 'documentNumber',
  Email = 'email',
  EndDate = 'endDate',
  FullName = 'fullName',
  Phone = 'phone',
  PurchaseId = 'purchaseId',
  Rut = 'rut',
  StartDate = 'startDate',
  TicketType = 'ticketType',
}

export type GetNewsPostInput = {
  id: Scalars['ID'];
};

export type GetTotalPriceActivitiesInput = {
  id: Scalars['ID'];
  ticketCount: Scalars['Int'];
};

export type GetTotalPriceEntrancesInput = {
  adultTicketCount: Scalars['Int'];
  id: Scalars['ID'];
  otherTicketCount: Scalars['Int'];
};

export type GetTotalPriceInput = {
  activities: Array<GetTotalPriceActivitiesInput>;
  code?: InputMaybe<Scalars['String']>;
  entrances: Array<GetTotalPriceEntrancesInput>;
  packs: Array<GetTotalPricePacksInput>;
};

export type GetTotalPricePacksInput = {
  adultTicketCount: Scalars['Int'];
  id: Scalars['ID'];
  otherTicketCount: Scalars['Int'];
};

export type GiftCard = {
  __typename?: 'GiftCard';
  code: Scalars['String'];
  from: Scalars['String'];
  id: Scalars['ID'];
  password: Scalars['String'];
  purchase?: Maybe<Purchase>;
  to: Scalars['String'];
  totalAmount: Scalars['Int'];
};

export type GiftCardPurchase = {
  __typename?: 'GiftCardPurchase';
  amountUsed: Scalars['Int'];
  giftCardId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GiftCardPurchaseInput = {
  from: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  to: Scalars['String'];
  totalPrice: Scalars['Int'];
};

export type GiftCardWithRemainingAmount = {
  __typename?: 'GiftCardWithRemainingAmount';
  expirationDate: Scalars['String'];
  from: Scalars['String'];
  id: Scalars['ID'];
  purchase?: Maybe<Purchase>;
  remainingAmount: Scalars['Int'];
  to: Scalars['String'];
  totalAmount: Scalars['Int'];
};

export type Grill = {
  __typename?: 'Grill';
  clientEmail?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Grills = {
  __typename?: 'Grills';
  count: Scalars['Int'];
  reservedGrills: Array<Grill>;
  stock: Scalars['Int'];
};

export type GroupByDay = {
  __typename?: 'GroupByDay';
  weekEndEntrances: Scalars['Int'];
  workDayEntrances: Scalars['Int'];
};

export type GroupedByNameMonth = {
  __typename?: 'GroupedByNameMonth';
  entranceTicketTypeId: Scalars['ID'];
  entrancesByMonth: Array<Scalars['Int']>;
  name: Scalars['String'];
};

export type ListBillingSystemCommunesArgs = {
  billingSystemCityId: Scalars['ID'];
};

export type ListEntranceImageInput = {
  entranceTicketTypeId: Scalars['ID'];
};

export type ListNewsPostsInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type ListNewsPostsResponse = {
  __typename?: 'ListNewsPostsResponse';
  count: Scalars['Int'];
  newsPosts: Array<NewsPost>;
};

export type ListPurchasesBackofficeResponse = {
  __typename?: 'ListPurchasesBackofficeResponse';
  count: Scalars['Int'];
  purchases: Array<TicketPurchase>;
};

export type MappedPurchase = {
  __typename?: 'MappedPurchase';
  date: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  includePacks: Scalars['Boolean'];
  purchaseActivities: Array<MappedPurchaseActivity>;
  purchaseGrills: Array<MappedPurchaseActivity>;
  purchaseItems: Array<MappedPurchaseItem>;
  uuid: Scalars['ID'];
};

export type MappedPurchaseActivity = {
  __typename?: 'MappedPurchaseActivity';
  activityTime: ActivityTime;
  id: Scalars['ID'];
  name: Scalars['String'];
  ticketCount: Scalars['Int'];
};

export type MappedPurchaseItem = {
  __typename?: 'MappedPurchaseItem';
  adultTicketCount: Scalars['Int'];
  entranceTicketTypeId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  otherTicketCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  authenticateUser: Success;
  createGiftCardPurchase?: Maybe<WebpayTransactionResponse>;
  createPurchase?: Maybe<WebpayTransactionResponse>;
  sendCorporateEventsEmail?: Maybe<CorporateEventContactOutput>;
  sendEmail?: Maybe<ContactUsOutput>;
  sendMailProgram: Success;
  updateTicketsDateTime?: Maybe<Success>;
};

export type MutationAuthenticateUserArgs = {
  input: AuthenticateUserInput;
};

export type MutationCreateGiftCardPurchaseArgs = {
  input: UpsertClientAndCreateGiftCardTransactionInput;
};

export type MutationCreatePurchaseArgs = {
  input: UpsertClientAndCreateTransactionInput;
};

export type MutationSendCorporateEventsEmailArgs = {
  input: CorporateEventContactInput;
};

export type MutationSendEmailArgs = {
  input: ContactUsInput;
};

export type MutationSendMailProgramArgs = {
  input: SendMailProgramInput;
};

export type MutationUpdateTicketsDateTimeArgs = {
  input: UpdateTicketsDateTimeInput;
};

export type NewsBanner = {
  __typename?: 'NewsBanner';
  body: Scalars['String'];
  isVisible: Scalars['Boolean'];
  title: Scalars['String'];
};

export type NewsPost = {
  __typename?: 'NewsPost';
  author?: Maybe<Scalars['String']>;
  closingImages?: Maybe<Array<NewsPostImage>>;
  contents?: Maybe<Array<NewsPostContent>>;
  coverImage?: Maybe<NewsPostImage>;
  featured: Scalars['Boolean'];
  id: Scalars['ID'];
  mainImage?: Maybe<NewsPostImage>;
  newsPostRecommendations: Array<NewsPostRecommendation>;
  publicationDate: Scalars['String'];
  readTime: Scalars['Float'];
  status: Scalars['Boolean'];
  title: Scalars['String'];
  viewCount: Scalars['Int'];
};

export type NewsPostContent = {
  __typename?: 'NewsPostContent';
  content: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type NewsPostImage = {
  __typename?: 'NewsPostImage';
  caption?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: NewsPostImageType;
  uploadUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum NewsPostImageType {
  ClosingImage = 'ClosingImage',
  CoverImage = 'CoverImage',
  MainImage = 'MainImage',
}

export type NewsPostRecommendation = {
  __typename?: 'NewsPostRecommendation';
  coverImage: NewsPostImage;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type Newsletter = {
  __typename?: 'Newsletter';
  email: Scalars['String'];
  id: Scalars['ID'];
  status: Scalars['Boolean'];
};

export type Occupation = {
  __typename?: 'Occupation';
  maxCapacity: Scalars['Int'];
  occupationByMonth: Array<Scalars['Int']>;
  occupationPercentageByMonth: Array<Scalars['Float']>;
  total: Scalars['Int'];
};

export type OpenActivityTimetablesPerDay = {
  __typename?: 'OpenActivityTimetablesPerDay';
  id: Scalars['ID'];
};

export type OpenDay = {
  __typename?: 'OpenDay';
  day: Scalars['String'];
  id: Scalars['ID'];
};

export type Pack = {
  __typename?: 'Pack';
  activities: Array<Activity>;
  adultValue: Scalars['Int'];
  billingSystemProductId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  entranceTicketTypes: Array<EntranceTicketType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  otherValue: Scalars['Int'];
  packImages: Array<PackImage>;
  remaining: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type PackImage = {
  __typename?: 'PackImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type PacksGroupedByNameMonth = {
  __typename?: 'PacksGroupedByNameMonth';
  entrancesByMonthAndName: Array<GroupedByNameMonth>;
  name: Scalars['String'];
};

export type ParkAvailability = {
  __typename?: 'ParkAvailability';
  aperture: Scalars['String'];
  closure: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['ID'];
  isWinterTime: Scalars['Boolean'];
  startDate: Scalars['String'];
};

export type ParkSalesByGroupDay = {
  __typename?: 'ParkSalesByGroupDay';
  entrancesByMonth: Array<GroupByDay>;
  packsEntrancesByMonth: Array<GroupByDay>;
};

export type Place = {
  __typename?: 'Place';
  activities?: Maybe<Activity>;
  id: Scalars['ID'];
  mainDescription: Scalars['String'];
  name: Scalars['String'];
  placeImages?: Maybe<Array<PlaceImage>>;
  placeMainImage?: Maybe<Scalars['String']>;
  secondaryDescription: Scalars['String'];
  thingsToDo?: Maybe<Array<Maybe<ThingToDo>>>;
};

export type PlaceImage = {
  __typename?: 'PlaceImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  coverImage?: Maybe<ProgramImage>;
  description: Scalars['String'];
  hoursDuration: Scalars['Int'];
  id: Scalars['ID'];
  secondaryImages?: Maybe<Array<ProgramImage>>;
  status: Scalars['Boolean'];
  subtitle: Scalars['String'];
  targetGroup: Scalars['String'];
  title: Scalars['String'];
  validForWeekends: Scalars['Boolean'];
  videoLink?: Maybe<Scalars['String']>;
};

export type ProgramImage = {
  __typename?: 'ProgramImage';
  id: Scalars['ID'];
  programImageType: ProgramImageType;
  uploadUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum ProgramImageType {
  Cover = 'Cover',
  Secondary = 'Secondary',
}

export type PromotionCode = {
  __typename?: 'PromotionCode';
  codes: Array<Code>;
  createdAt?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  forWeekends?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  promotionCodeActivities: Array<PromotionCodeActivity>;
  promotionCodeEntrances: Array<PromotionCodeEntrance>;
  promotionCodePacks: Array<PromotionCodePack>;
  status: Scalars['Boolean'];
  suffix?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type PromotionCodeActivity = {
  __typename?: 'PromotionCodeActivity';
  activityId: Scalars['ID'];
  id: Scalars['ID'];
  maxQuantity: Scalars['Int'];
  percentageDiscount: Scalars['Float'];
};

export type PromotionCodeEntrance = {
  __typename?: 'PromotionCodeEntrance';
  entranceTicketTypeId: Scalars['ID'];
  id: Scalars['ID'];
  maxQuantity: Scalars['Int'];
  percentageDiscount: Scalars['Float'];
};

export type PromotionCodePack = {
  __typename?: 'PromotionCodePack';
  id: Scalars['ID'];
  maxQuantity: Scalars['Int'];
  packsId: Scalars['ID'];
  percentageDiscount: Scalars['Float'];
};

export type PromotionOptions = {
  __typename?: 'PromotionOptions';
  activityTypes?: Maybe<Array<ActivityType>>;
  entranceTicketTypes?: Maybe<Array<EntranceTicketType>>;
  packs?: Maybe<Array<Pack>>;
};

export type Purchase = {
  __typename?: 'Purchase';
  client?: Maybe<Client>;
  clientID?: Maybe<Scalars['ID']>;
  code?: Maybe<Code>;
  createdAt?: Maybe<Scalars['String']>;
  giftCard?: Maybe<GiftCard>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<Scalars['Int']>;
  qrCode?: Maybe<Scalars['String']>;
  state?: Maybe<PurchaseState>;
};

export enum PurchaseState {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
}

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  getConfigurationParameters: ConfigurationParameters;
  getEnrichedPurchase?: Maybe<EnrichedPurchaseMailer>;
  getEntranceTicketType: EntranceTicketType;
  getGiftCard?: Maybe<GiftCardWithRemainingAmount>;
  getNewsBanner?: Maybe<NewsBanner>;
  getNewsPost: NewsPost;
  getPackForPurchase: Pack;
  getPlace: Place;
  getProgram: Program;
  getPromotionCode?: Maybe<PromotionCode>;
  getTotalPrice: TotalPrice;
  listActivities?: Maybe<Array<Maybe<Activity>>>;
  listBillingSystemCities: Array<BillingSystemCity>;
  listBillingSystemCommunes: Array<BillingSystemCommune>;
  listChangeablePurchases: Array<Maybe<MappedPurchase>>;
  listEntranceTicketTypes?: Maybe<Array<Maybe<EntranceTicketType>>>;
  listEntranceTicketTypesForPurchase?: Maybe<Array<Maybe<EntranceTicketType>>>;
  listGrills: Array<Place>;
  listNewsPosts: ListNewsPostsResponse;
  listOpenDays: Array<Maybe<OpenDay>>;
  listPacks?: Maybe<Array<Pack>>;
  listPacksForPurchase: Array<SummaryPack>;
  listParkAvailability?: Maybe<Array<Maybe<ParkAvailability>>>;
  listPlaces: Array<Place>;
  listPrograms: Array<Program>;
  listThingsToDo: Array<ThingToDo>;
};

export type QueryGetEnrichedPurchaseArgs = {
  isInvalid?: InputMaybe<Scalars['Boolean']>;
  uuid: Scalars['ID'];
};

export type QueryGetEntranceTicketTypeArgs = {
  date: Scalars['String'];
  id: Scalars['ID'];
};

export type QueryGetGiftCardArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
};

export type QueryGetNewsPostArgs = {
  input: GetNewsPostInput;
};

export type QueryGetPackForPurchaseArgs = {
  date: Scalars['String'];
  id: Scalars['ID'];
};

export type QueryGetPlaceArgs = {
  id: Scalars['ID'];
};

export type QueryGetProgramArgs = {
  id: Scalars['ID'];
};

export type QueryGetPromotionCodeArgs = {
  code: Scalars['String'];
};

export type QueryGetTotalPriceArgs = {
  input: GetTotalPriceInput;
};

export type QueryListActivitiesArgs = {
  activityTypeId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
};

export type QueryListBillingSystemCommunesArgs = {
  input: ListBillingSystemCommunesArgs;
};

export type QueryListChangeablePurchasesArgs = {
  accessToken: Scalars['String'];
};

export type QueryListEntranceTicketTypesForPurchaseArgs = {
  date: Scalars['String'];
};

export type QueryListNewsPostsArgs = {
  input: ListNewsPostsInput;
};

export type QueryListOpenDaysArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryListPacksForPurchaseArgs = {
  date: Scalars['String'];
};

export type ReportClientRecurrency = {
  __typename?: 'ReportClientRecurrency';
  clientReccurePurchaseCounter: Array<Scalars['Int']>;
  clientReccurency: ClientReccurency;
};

export type ReportEntranceTickets = {
  __typename?: 'ReportEntranceTickets';
  entranceSaleReport: EntranceSaleReport;
};

export type ReportGrill = {
  __typename?: 'ReportGrill';
  grillOccupation: Occupation;
};

export type ReportPark = {
  __typename?: 'ReportPark';
  parkOccupation: Occupation;
};

export type ReportSalesByGroupDay = {
  __typename?: 'ReportSalesByGroupDay';
  parkSalesByGroupDay: ParkSalesByGroupDay;
};

export type SendMailProgramInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message: Scalars['String'];
  phoneNumber: Scalars['String'];
  programId: Scalars['ID'];
  reservationDate: Scalars['String'];
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type SummarizedReservation = {
  __typename?: 'SummarizedReservation';
  activityTicketsSummarized: Array<SummarizedReservationActivityTicket>;
  client: Client;
  entranceTicketsSummarized: Array<SummarizedReservationEntranceTicket>;
  purchaseId: Scalars['ID'];
};

export type SummarizedReservationActivityTicket = {
  __typename?: 'SummarizedReservationActivityTicket';
  name: Scalars['String'];
  startHour: Scalars['String'];
  ticketCount: Scalars['Int'];
  total: Scalars['Int'];
  validityDate: Scalars['String'];
};

export type SummarizedReservationEntranceTicket = {
  __typename?: 'SummarizedReservationEntranceTicket';
  state: Scalars['Boolean'];
  ticketCount: Scalars['Int'];
  ticketTypeName: Scalars['String'];
  totalValue: Scalars['Int'];
  visitDate: Scalars['String'];
};

export type SummaryPack = {
  __typename?: 'SummaryPack';
  adultValue: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  entranceTicketTypes: Array<EntranceTicketType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  otherValue: Scalars['Int'];
  packImages: Array<PackImage>;
  remaining: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type ThingToDoImage = {
  __typename?: 'ThingToDoImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type TicketPurchase = {
  __typename?: 'TicketPurchase';
  disabledPurchaseHistory?: Maybe<DisablePurchaseHistory>;
  documentNumber: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  purchaseId: Scalars['ID'];
  ticketType: Scalars['String'];
  visitDate: Scalars['String'];
};

export type TotalPrice = {
  __typename?: 'TotalPrice';
  totalPrice: Scalars['Int'];
};

export type UpdateTicketsDateTimeInput = {
  accessToken: Scalars['String'];
  activityTickets: Array<ActivityTicketsInput>;
  entranceTickets: Array<EntranceTicketsInput>;
  purchaseId: Scalars['ID'];
};

export type UpsertClientAndCreateGiftCardTransactionInput = {
  billingSystemArguments: BillingSystemInputArgs;
  clientArguments: UpsertClientArgs;
  giftCardPurchaseArguments: GiftCardPurchaseInput;
};

export type UpsertClientAndCreateTransactionInput = {
  billingSystemArguments: BillingSystemInputArgs;
  clientArguments: UpsertClientArgs;
  purchaseArguments: CreatePurchaseInputArgs;
};

export type UpsertClientArgs = {
  documentIsPassport: Scalars['Boolean'];
  documentNumber: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type UseGiftCardInput = {
  giftCardCode: Scalars['String'];
  giftCardPassword: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
};

export enum UserRole {
  Admin = 'Admin',
  Gatekeeper = 'Gatekeeper',
}

export type WebPayCreateGiftCardPurchaseResponse = {
  __typename?: 'WebPayCreateGiftCardPurchaseResponse';
  createGiftCardPurchase?: Maybe<WebpayTransactionResponse>;
};

export type WebPayCreatePurchaseResponse = {
  __typename?: 'WebPayCreatePurchaseResponse';
  createPurchase?: Maybe<WebpayTransactionResponse>;
};

export type WebpayTransactionResponse = {
  __typename?: 'WebpayTransactionResponse';
  token?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ActivitiesReservations = {
  __typename?: 'activitiesReservations';
  aviaryReservation: ActivityReservations;
  grillsReservation: ActivityReservations;
  nurseryGardenReservation: ActivityReservations;
};

export type ActivityReservations = {
  __typename?: 'activityReservations';
  availableTimes: Scalars['Int'];
  count: Scalars['Int'];
  stock: Scalars['Int'];
};

export type DisabledPurchaseHistory = {
  __typename?: 'disabledPurchaseHistory';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  purchaseId: Scalars['ID'];
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName: Scalars['String'];
};

export type EntranceTicketsGrouped = {
  __typename?: 'entranceTicketsGrouped';
  adultCount: Scalars['Int'];
  count: Scalars['Int'];
  entranceTicketTypeName?: Maybe<Scalars['String']>;
  kidsOrElderlyCount: Scalars['Int'];
  stock: Scalars['Int'];
  visitedAdultCount: Scalars['Int'];
  visitedCount: Scalars['Int'];
  visitedKidsOrElderlyCount: Scalars['Int'];
};

export type ExperienceTickets = {
  __typename?: 'experienceTickets';
  availableTimes: Scalars['Int'];
  name: Scalars['String'];
  stockPerTime: Scalars['Int'];
  times: Array<ExperienceTime>;
  totalCount: Scalars['Int'];
};

export type ExperienceTime = {
  __typename?: 'experienceTime';
  count?: Maybe<Scalars['Int']>;
  startEndHour?: Maybe<Scalars['String']>;
};

export type IsStillChangeable = {
  __typename?: 'isStillChangeable';
  isStillChangeable: Scalars['Boolean'];
};

export type ListClientsBackofficeResponse = {
  __typename?: 'listClientsBackofficeResponse';
  clients: Array<EnrichedClient>;
  count: Scalars['Int'];
};

export type ThingToDo = {
  __typename?: 'thingToDo';
  description: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  thingToDoImages?: Maybe<Array<ThingToDoImage>>;
};

export type TicketsCount = {
  __typename?: 'ticketsCount';
  entrances: Array<EntranceTicketsGrouped>;
  experiences: Array<ExperienceTickets>;
  grills?: Maybe<Grills>;
};

export type TodaysTicketData = {
  __typename?: 'todaysTicketData';
  activitiesReservations: ActivitiesReservations;
  adultCount: Scalars['Int'];
  count: Scalars['Int'];
  kidsOrElderlyCount: Scalars['Int'];
  stock: Scalars['Int'];
  visitedAdultCount: Scalars['Int'];
  visitedCount: Scalars['Int'];
  visitedKidsOrElderlyCount: Scalars['Int'];
};
