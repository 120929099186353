import { Col, Row } from 'antd';
import SuccessPurchaseImage from 'assets/images/success-purchase-image.png';
import SuccessPurchaseCard from 'components/SuccessPurchase/SuccessPurchaseCard';
import React, { FC } from 'react';
import * as texts from 'assets/texts/successPurchase';
import PurchaseSteps from 'components/generalUI/PurchaseSteps';
import getWebpayTokenSearchParams from 'utils/webpay/getWebpayTokenSearchParams';
import refreshPurchaseData from 'storage/utils/refreshPurchaseData';
import Places from 'components/sharedComponents/Places';
import ActualParkAvailability from 'components/sharedComponents/ActualParkAvailability';
import styles from './SuccessPurchase.module.scss';

const PurchaseSuccess: FC = () => {
  const { purchaseId, date, email, uuid } = getWebpayTokenSearchParams();

  refreshPurchaseData(date || '');
  sessionStorage.removeItem('selectedPurchase');

  return (
    <div className={styles.Container}>
      <PurchaseSteps total={4} current={3} />
      <Row justify="center">
        <Col>
          <h1 className={styles.Title}>{texts.title}</h1>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col span={24}>
          <img
            src={SuccessPurchaseImage}
            alt="SuccessPurchaseImage"
            className={styles.SuccessImage}
          />
        </Col>
      </Row>
      <Row justify="space-around">
        <SuccessPurchaseCard
          purchaseId={purchaseId}
          email={email || ''}
          uuid={uuid || ''}
        />
      </Row>
      <Row justify="center">
        <Col span={20}>
          <p className={styles.ReminderText}>{texts.reminder}</p>
        </Col>
      </Row>
      <Places />
      <ActualParkAvailability date={date || undefined} />
    </div>
  );
};

export default PurchaseSuccess;
