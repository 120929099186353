import React from 'react';
import { useLocation } from 'react-router-dom';

const dateQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const getGiftCardSearchParams = (): {
  from: string;
  to: string;
  amount: string;
} | null => {
  const query = dateQuery();
  const from = query.get('from');
  const to = query.get('to');
  const amount = query.get('amount');
  if (!from || !to || !amount) {
    return null;
  }

  return { from, to, amount };
};

export default getGiftCardSearchParams;
