import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import Button from 'components/generalUI/Button';
import OpenDayPicker from 'components/sharedComponents/OpenDayPicker';
import PurchaseSteps from 'components/generalUI/PurchaseSteps';
import * as texts from 'assets/texts/purchaseChange';

import ActualParkAvailability from 'components/sharedComponents/ActualParkAvailability';
import styles from './SelectDate.module.scss';

const SelectDate: FC = () => {
  const { token } = useParams<{ token: string }>();
  const redirectUrl = token ? `confirmChange/${token}` : 'sell';
  const actualDate = new Date().toISOString().split('T')[0];

  return (
    <div className={styles.Container}>
      <PurchaseSteps total={4} current={token ? 2 : 0} />
      <OpenDayPicker startDate={actualDate} redirectUrlType={redirectUrl} />
      {!token && (
        <Row justify="center">
          <Col span={15}>
            <Button kind="secondary" href="/purchaseChange">
              {texts.purchaseChangeButton}
            </Button>
          </Col>
        </Row>
      )}
      <ActualParkAvailability />
    </div>
  );
};

export default SelectDate;
