import React, { FC } from 'react';
import ApolloWrapperProvider from 'apollo/Provider';
import Layout from 'components/generalUI/MainLayout';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import Routes from 'routes';
import posthog from 'posthog-js';

const App: FC = () => {
  const postHogApiKey = process.env.REACT_APP_POSTHOG_API_KEY || '';

  posthog.init(postHogApiKey, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'always',
  });

  return (
    <ConfigProvider locale={esES}>
      <ApolloWrapperProvider>
        <Layout>
          <Routes />
        </Layout>
      </ApolloWrapperProvider>
    </ConfigProvider>
  );
};

export default App;
