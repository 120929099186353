import { PurchaseData } from 'storage/types';
import { createNewPurchaseData } from './getOrCreatePurchaseData';

const refreshPurchaseStore = (date: string): PurchaseData => {
  const purchase = createNewPurchaseData(date);
  const purchaseJson = JSON.stringify(purchase);
  sessionStorage.setItem('purchase', purchaseJson);
  return purchase;
};

export default refreshPurchaseStore;
