import {
  ActivityTicketsInput,
  MappedPurchase,
  UpdateTicketsDateTimeInput,
} from 'apollo/types';
import { ActivityPurchase, PackOrEntrancePurchase } from 'storage/types';

const mapPurchaseChangeInput = (
  purchase: MappedPurchase,
  purchaseData: PackOrEntrancePurchase,
  accessToken: string,
  grillData: ActivityPurchase | undefined
): UpdateTicketsDateTimeInput => {
  const purchaseActivities = purchase.purchaseActivities.map((purchaseActivity) => {
    return {
      newDate: purchase.date,
      ticketCount: purchaseActivity.ticketCount,
      activityTimeId: purchaseActivity.activityTime.id,
      activityId: purchaseActivity.id,
      newActivityTimeId:
        purchaseData.activities.find((activity) => activity.id === purchaseActivity.id)
          ?.activityTimeId || '',
    };
  });

  let purchaseGrills: ActivityTicketsInput[] = [];
  if (grillData) {
    purchaseGrills = purchase.purchaseGrills.map((purchaseGrill) => {
      return {
        newDate: purchase.date,
        ticketCount: purchaseGrill.ticketCount,
        activityTimeId: purchaseGrill.activityTime.id,
        activityId: grillData.id,
        newActivityTimeId: grillData.activityTimeId,
      };
    });
  }

  return {
    accessToken,
    purchaseId: purchase.id,
    entranceTickets: purchase.purchaseItems.map((purchaseItem) => {
      const mappedEntranceTicket = {
        adultTicketCount: purchaseItem.adultTicketCount,
        otherTicketCount: purchaseItem.otherTicketCount,
        newDate: purchase.date,
        entranceTicketTypeId: purchaseItem.entranceTicketTypeId || '0',
      };
      return mappedEntranceTicket;
    }),
    activityTickets: [...purchaseActivities, ...purchaseGrills],
  };
};

export default mapPurchaseChangeInput;
