import React, { FC, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Button from 'components/generalUI/Button';
import { MappedPurchase } from 'apollo/types';
import * as texts from 'assets/texts/purchaseChange';
import PurchaseCard from '../PurchaseCard';
import styles from './ClientPurchases.module.scss';

interface ClientPurchasesProps {
  changeablePurchases: MappedPurchase[];
  token: string;
}

const ClientPurchases: FC<ClientPurchasesProps> = (props: ClientPurchasesProps) => {
  const { changeablePurchases, token } = props;
  const [selectedPurchase, setPurchase] = useState<MappedPurchase | undefined>();

  const setFirstPurchase = () => {
    if (changeablePurchases.length) {
      const [purchase] = changeablePurchases;
      setPurchase(purchase);
    }
  };

  useEffect(() => {
    setFirstPurchase();
  }, []);

  return (
    <>
      {!changeablePurchases.length ? (
        <Row justify="center">
          <Col xs={22} md={20} xl={16}>
            <h3>{texts.noPurchasesMessage}</h3>
          </Col>
        </Row>
      ) : (
        <>
          <Row justify="center">
            <Col span={18}>
              <h3>{texts.selectPurchase}</h3>
            </Col>
          </Row>
          {changeablePurchases.map((purchase) => (
            <Row className={styles.Row} justify="center" key={purchase.id}>
              <Col span={18}>
                <PurchaseCard
                  mappedPurchase={purchase}
                  setPurchase={setPurchase}
                  isSelected={selectedPurchase?.id === purchase.id}
                />
              </Col>
            </Row>
          ))}
          <Row justify="center">
            <Col xs={22} md={20} xl={16}>
              <Button
                className={styles.ChangeDateButton}
                disabled={!selectedPurchase}
                onClick={() =>
                  sessionStorage.setItem(
                    'selectedPurchase',
                    JSON.stringify(selectedPurchase || '')
                  )
                }
                href={`/date/${token}`}
              >
                {texts.changeDate}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ClientPurchases;
