import { gql, useLazyQuery } from '@apollo/client';
import { PromotionCode, QueryGetPromotionCodeArgs } from 'apollo/generated/types';

const GET_PROMOTION_CODE = gql`
  query getPromotionCode($code: String!) {
    getPromotionCode(code: $code) {
      expirationDate
      id
      status
      suffix
      forWeekends
      promotionCodeActivities {
        activityId
        id
        maxQuantity
        percentageDiscount
      }
      promotionCodeEntrances {
        entranceTicketTypeId
        id
        maxQuantity
        percentageDiscount
      }
      promotionCodePacks {
        id
        maxQuantity
        packsId
        percentageDiscount
      }
    }
  }
`;

const useGetPromotionCodeQuery = () => {
  const queryResponse = useLazyQuery<
    { getPromotionCode: PromotionCode },
    QueryGetPromotionCodeArgs
  >(GET_PROMOTION_CODE);
  return queryResponse;
};
export default useGetPromotionCodeQuery;
