import React, { FC } from 'react';
import { Collapse, Row, Col } from 'antd';
import styles from './Collapse.module.scss';
import ExpandedIcon from './ExpandedIcon';
import { Props } from './types';

const { Panel } = Collapse;

const CollapseComponent: FC<Props> = ({ content }: Props) => {
  return (
    <div className={styles.container}>
      <Row justify="space-around">
        <Col xs={20} sm={18} md={16} lg={14} xl={12}>
          <Collapse
            bordered={false}
            className={styles.collapse}
            accordion
            expandIconPosition="right"
            expandIcon={ExpandedIcon}
          >
            {content.map((item) => {
              return (
                <Panel
                  header={item.question}
                  key={item.question}
                  className={styles.panel}
                >
                  <p
                    key={item.answer}
                    className={styles.panelText}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default CollapseComponent;
