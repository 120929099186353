export const title = '¡Compra exitosa!';
export const waitingForYou = 'Te esperamos el';
export const waitingForYouGiftCard = 'Compraste satisfactoriamente tu Gift Card';
export const orderNumber = ' Tu número de orden es:';
export const purchaseMainDescription =
  'En tu ticket encontrarás recomendaciones y un mapa del parque para hacer tu visita una experiencia memorable.';
export const purchaseMainDescriptionGiftCard =
  'Te enviaremos los detalles de tu compra a tu correo electrónico:';
export const downloadTicketsDescription =
  'Descarga tus entradas aquí o en tu correo electrónico:';
export const purchaseSecondaryDescription =
  'Si vienes en grupo, es ideal que cada miembro tenga la entrada, ya que será requerida en cada una de las actividades.';
export const reminder =
  '¡NO OLVIDES TRAER BLOQUEADOR SOLAR, LENTES DE SOL Y REPELENTE DE INSECTOS!';
export const viewTickets = 'Ver tickets';
export const pdfFileName = 'EntradasParqueTricao';
