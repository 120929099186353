import { ActivityPurchase } from 'storage/types';
import React, { FC } from 'react';
import priceFormat from 'utils/formats/priceFormat';
import * as texts from 'assets/texts/purchaseSummary';
import { Row, Col, Card } from 'antd';
import styles from './ActivityOrGrillSummaryCard.module.scss';

interface Props {
  item: ActivityPurchase;
  isGrill: boolean;
}

const ActivityOrGrillSummaryCard: FC<Props> = ({ item, isGrill }: Props) => {
  return (
    <Row justify="center">
      <Col xs={20} lg={15}>
        <Card className={styles.Card}>
          <h2 className={styles.Title}>{item.name}</h2>
          {!isGrill && (
            <p>
              {item.ticketCount} {texts.tickets}
            </p>
          )}
          {item.value && (
            <p className={styles.SubTotal}>
              {texts.subTotal}
              {priceFormat.format(item.value * (item.ticketCount || 1))}
            </p>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default ActivityOrGrillSummaryCard;
