import { CreatePurchaseInputArgs, UseGiftCardInput } from 'apollo/types';
import { PurchaseData } from 'storage/types';
import purchaseActivities from './purchase/purchaseActivities';
import purchaseEntrance from './purchase/purchaseEntrance';
import purchasePacks from './purchase/purchasePacks';

const purchaseDataToPurchaseInput = (
  purchaseData: PurchaseData,
  date: string,
  paymentMethod: string
): CreatePurchaseInputArgs => {
  const activities = purchaseData.grill ? [purchaseData.grill] : [];
  const giftCardArg = purchaseData.giftCard
    ? (purchaseData.giftCard as UseGiftCardInput)
    : undefined;
  const purchaseInput: CreatePurchaseInputArgs = {
    activities: purchaseActivities(activities),
    date,
    entrances: purchaseEntrance(purchaseData.entrances),
    packs: purchasePacks(purchaseData.packs),
    totalPrice: purchaseData.totalPrice,
    method: paymentMethod,
    code: purchaseData.code,
    giftCard: giftCardArg,
  };
  return purchaseInput;
};

export default purchaseDataToPurchaseInput;
