export const title = 'Quinchos';
export const description =
  'Un espacio íntimo y familiar perfecto para almorzar rodeado de la flora nativa que crece en las quebradas del parque. Cada uno de los espacios se diferencia del otro, haciendo de esta una experiencia especial.';
export const grillDetailTitle = 'Recorriendo nuestros quinchos';
export const grillDetailDescription =
  'Contamos con 10 quinchos, los cuales  cuentan con parrilla a gas, bolsa de basura, que luego tendrás que llevarte, y el equipamiento necesario para que puedas pasar un día en Parque Tricao acompañado de tus amigos y familia. Todos los quinchos tienen una capacidad de 25 personas.';

export const vacancyReminder =
  'No olvides que los cupos para visitar el parque y el aviario son limitados. Para visitar el aviario, los tickets sólo se venden a través de esta plataforma web, según la disponibilidad de horarios.';
export const grillValueText = 'El valor de la entrada es de $40.000';
export const garbageReminder = 'No olvides botar la basura en lugares indicados.';
export const fireOnGrill = 'Solo está permitido hacer fuego en la parrilla.';
export const extras =
  'El sector cuenta con baños, mesas, sillas y parrilla a gas (incluido).';
export const backGround =
  'https://tricao-production.s3.us-west-2.amazonaws.com/uploads/activity_image/image/155/a.jpg';

export const grillDetailFooter = 'Este quincho cuenta con:';
export const grillListTitle = 'Recorriendo nuestros quinchos';
