import React, { FC, useState, useEffect } from 'react';
import { Card, Collapse, Divider, Tooltip } from 'antd';
import Button from 'components/generalUI/Button';
import { Pack, EntranceTicketType, ConfigurationParameter } from 'apollo/types';
import SelectActivityTime from 'components/SelectTickets/SelectActivityTime';
import parse from 'html-react-parser';
import priceFormat from 'utils/formats/priceFormat';
import * as texts from 'assets/texts/selectTickets';
import { PackOrEntrancePurchase } from 'storage/types';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import styles from './SelectTicketsCard.module.scss';

interface SelectTicketsCardProps {
  selectedItem: Pack | EntranceTicketType;
  subTotal: number;
  setSubTotal: React.Dispatch<React.SetStateAction<number>>;
  purchaseData: PackOrEntrancePurchase;
  setPurchaseData: React.Dispatch<React.SetStateAction<PackOrEntrancePurchase>>;
  oversold?: ConfigurationParameter;
}

const SelectTicketsCard: FC<SelectTicketsCardProps> = (props: SelectTicketsCardProps) => {
  const { selectedItem, subTotal, setSubTotal, purchaseData, setPurchaseData, oversold } =
    props;
  const { Meta, Grid } = Card;

  const [panelHeaderText, setPanelHeaderText] = React.useState<string>(texts.showLess);
  const [remainingTickets, setRemainingTickets] = useState(selectedItem.remaining);
  const [adultTickets, setAdultTickets] = useState(purchaseData.adultTicketCount);
  const [otherTickets, setOtherTickets] = useState(purchaseData.otherTicketCount);

  const finalOversold = selectedItem.remaining ? (oversold?.value as number) : 0;

  const handlePanelClick = (key: string | string[]) => {
    const isPanelOpen = key.length > 0;
    setPanelHeaderText(isPanelOpen ? texts.showLess : texts.whatIncludes);
  };

  useEffect(() => {
    setSubTotal(
      adultTickets * selectedItem.adultValue + otherTickets * selectedItem.otherValue
    );
    setRemainingTickets(
      selectedItem.remaining - adultTickets - otherTickets + finalOversold
    );

    purchaseData.id = selectedItem.id;
    purchaseData.name = selectedItem.name || '';
    purchaseData.adultTicketCount = adultTickets;
    purchaseData.otherTicketCount = otherTickets;
    purchaseData.adultValue = selectedItem.adultValue;
    purchaseData.otherValue = selectedItem.otherValue;
    setPurchaseData(purchaseData);
  }, [adultTickets, otherTickets]);

  return (
    <>
      <Card className={styles.Card}>
        <Meta
          title={<h2 className={styles.Title}>{selectedItem.name}</h2>}
          description={
            <Collapse ghost onChange={handlePanelClick} defaultActiveKey={1}>
              <CollapsePanel
                className={styles.ShowMoreCollapse}
                key="1"
                header={panelHeaderText}
              >
                <div className={styles.Description}>
                  {parse(selectedItem.description as string)}
                </div>
              </CollapsePanel>
            </Collapse>
          }
        />
        <Divider className={styles.Divider} />
        <Grid className={styles.GridItem} hoverable={false}>
          <div className={styles.Item}>
            <p>{texts.others}</p>
            <h3>{priceFormat.format(selectedItem.otherValue)}</h3>
            <div className={styles.Tickets}>
              <Button
                disabled={!otherTickets}
                className={styles.TicketButton}
                onClick={() => setOtherTickets(otherTickets - 1)}
              >
                {texts.minus}
              </Button>
              <p className={styles.TicketValue}>{otherTickets}</p>
              <Tooltip title={texts.certificateMessage} placement="top">
                <Button
                  disabled={!remainingTickets}
                  className={styles.TicketButton}
                  onClick={() => setOtherTickets(otherTickets + 1)}
                >
                  {texts.plus}
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className={styles.Item}>
            <p>{texts.adults}</p>
            <h3>{priceFormat.format(selectedItem.adultValue)}</h3>
            <div className={styles.Tickets}>
              <Button
                disabled={!adultTickets}
                className={styles.TicketButton}
                onClick={() => setAdultTickets(adultTickets - 1)}
              >
                {texts.minus}
              </Button>
              <p className={styles.TicketValue}>{adultTickets}</p>
              <Button
                disabled={!remainingTickets}
                className={styles.TicketButton}
                onClick={() => setAdultTickets(adultTickets + 1)}
              >
                {texts.plus}
              </Button>
            </div>
          </div>
          {!remainingTickets && (
            <div className={styles.OversoldContainer}>
              <p>{texts.oversold}</p>
            </div>
          )}
        </Grid>
        <div className={styles.SubTotalContainer}>
          <p className={styles.SubTotalText}>{texts.subTotal}</p>
          <h2 className={styles.SubTotal}>{priceFormat.format(subTotal)}</h2>
        </div>
      </Card>
      {'activities' in selectedItem &&
        selectedItem.activities.map((activity, index) => (
          <SelectActivityTime
            key={activity.name}
            activity={activity}
            ticketCount={adultTickets + otherTickets}
            purchaseData={purchaseData}
            setPurchaseData={setPurchaseData}
            index={index}
          />
        ))}
    </>
  );
};

SelectTicketsCard.defaultProps = {
  oversold: { name: 'oversold', value: 0 },
};

export default SelectTicketsCard;
