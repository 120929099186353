import { Col, Row } from 'antd';
import React, { FC } from 'react';
import * as texts from 'assets/texts/programs';
import ProgramsComponent from 'components/Programs';
import ShareSocialMediaImage from 'assets/images/share-social-media-image.jpg';
import styles from './Programs.module.scss';

const Programs: FC = () => {
  return (
    <>
      <div className={styles.ImageContainer}>
        <div className={styles.BackGroundImageContainer}>
          <img
            className={styles.Image}
            src={ShareSocialMediaImage}
            alt="Share Social Media"
          />
        </div>
      </div>
      <Row justify="center">
        <Col xs={20} sm={22} md={20} lg={18} xxl={16}>
          <Row className={styles.TitleContainer} justify="center">
            <Col>
              <h1 className={styles.Title}>{texts.programs}</h1>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={22} sm={22} md={20} lg={18} xxl={16}>
              <ProgramsComponent />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Programs;
