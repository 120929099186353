import React, { FC } from 'react';
import { Affix as BaseAffix, AffixProps } from 'antd';
import styles from './BottomAffix.module.scss';

const BottomAffix: FC<AffixProps> = (props: AffixProps) => {
  const { children, ...otherProps } = props;
  return (
    <BaseAffix {...otherProps} className={styles.Affix} offsetBottom={0}>
      {children}
    </BaseAffix>
  );
};

export default BottomAffix;
