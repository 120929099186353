import React, { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import { MappedPurchase, Activity } from 'apollo/types';
import { ActivityPurchase, PackOrEntrancePurchase } from 'storage/types';
import useListActivitiesQuery from 'apollo/resolvers/activities/list';
import useUpdateTicketsDateTimeMutation from 'apollo/resolvers/purchases/updateTicketDateTime';
import LoadingModal from 'components/generalUI/LoadingModal';
import PurchaseCard from 'components/PurchaseChange/PurchaseCard';
import PurchaseSteps from 'components/generalUI/PurchaseSteps';
import SelectActivityTime from 'components/SelectTickets/SelectActivityTime';
import Button from 'components/generalUI/Button';
import getDateSearchParam from 'utils/dates/getDateSearchParam';
import mapPurchaseChangeInput from 'utils/helpers/mapPurchaseChangeInput';
import * as texts from 'assets/texts/purchaseChange';
import SelectGrill from 'components/SelectTickets/SelectGrill';
import styles from './ConfirmChange.module.scss';

const ConfirmChange: FC = () => {
  const date = getDateSearchParam();
  const selectedPurchase = JSON.parse(
    sessionStorage.getItem('selectedPurchase') || '{}'
  ) as MappedPurchase;
  selectedPurchase.date = date;
  const history = useHistory();
  const [purchaseActivities, setPurchaseActivities] = useState<Activity[]>([]);
  const [purchaseData, setPurchaseData] = useState<PackOrEntrancePurchase>({
    id: '',
    adultTicketCount: 0,
    otherTicketCount: 0,
    activities: [],
    name: '',
    adultValue: 0,
    otherValue: 0,
  });
  const [grillData, setGrillData] = useState<ActivityPurchase | null>(null);

  const { token } = useParams<{ token: string }>();

  const { data, loading } = useListActivitiesQuery(
    date,
    undefined,
    !selectedPurchase?.purchaseActivities.length
  );

  const [
    updateActivityTicketsDateTime,
    { data: updateTicketsData, loading: updateTicketsLoading },
  ] = useUpdateTicketsDateTimeMutation();

  const setPurchaseActivitiesInfo = () => {
    if (data && selectedPurchase.purchaseActivities) {
      const activities = data.listActivities.filter((act) => {
        const activity = selectedPurchase.purchaseActivities.find(
          ({ id }) => id === act.id
        );
        return activity;
      });
      setPurchaseActivities([...activities]);
    }
  };

  useEffect(() => {
    setPurchaseActivitiesInfo();
  }, [data]);

  const changePurchase = async () => {
    if (selectedPurchase.purchaseGrills.length && !grillData) {
      await message.error(texts.selectGrillError);
      return;
    }
    const input = mapPurchaseChangeInput(
      selectedPurchase,
      purchaseData,
      token,
      grillData || undefined
    );
    await updateActivityTicketsDateTime({ variables: { input } });
  };

  useEffect(() => {
    if (updateTicketsData?.updateTicketsDateTime.success) {
      history.push(
        `/success?date=${date}&purchaseId=${selectedPurchase.id}&email=${selectedPurchase.email}&uuid=${selectedPurchase.uuid}`
      );
    }
  }, [updateTicketsData]);

  return (
    <div>
      <LoadingModal visible={loading || updateTicketsLoading} />
      <PurchaseSteps total={4} current={2} />
      <Row justify="center">
        <Col span={18}>
          <h1 className={styles.Title}>{texts.confirmDateTitle}</h1>
        </Col>
      </Row>
      {selectedPurchase && token && (
        <Row justify="center">
          <Col span={18}>
            <PurchaseCard
              mappedPurchase={selectedPurchase}
              isSelected
              showGrills={false}
            />
          </Col>
        </Row>
      )}
      {purchaseActivities &&
        purchaseActivities.map((purchaseActivity, index) => (
          <Row justify="center" key={purchaseActivity.id}>
            <Col span={18}>
              <SelectActivityTime
                activity={purchaseActivity}
                ticketCount={
                  selectedPurchase.purchaseActivities.find(
                    ({ id }) => id === purchaseActivity.id
                  )?.ticketCount || 0
                }
                purchaseData={purchaseData}
                setPurchaseData={setPurchaseData}
                index={index}
              />
            </Col>
          </Row>
        ))}
      {selectedPurchase.purchaseGrills.length ? (
        <Row justify="center">
          <Col span={18}>
            <SelectGrill
              setGrillData={setGrillData}
              grillId={grillData ? grillData.id : undefined}
              showPrice={false}
              title={texts.selectGrill}
              initiallyOpen
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row justify="center">
        <Col xs={22} md={20} xl={16}>
          <Button onClick={changePurchase} className={styles.ChangeDateButton}>
            {texts.confirmButton}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmChange;
