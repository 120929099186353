import React, { FC } from 'react';
import { Row, Col } from 'antd';
import Button from 'components/generalUI/Button';
import * as texts from 'assets/texts/home';
import ReactPlayer from 'react-player';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import styles from './VideoSlogan.module.scss';

const VideoSlogan: FC = () => {
  const [isPlaying, setIsPlaying] = React.useState(true);
  return (
    <div>
      <Row align="bottom" justify="space-around" className={styles.Container}>
        <Col span={24}>
          <div className={styles.VideoContainer}>
            <ReactPlayer
              url={process.env.REACT_APP_HOME_VIDEO_URL}
              playing={isPlaying}
              muted
              loop
              style={{
                minWidth: '100%',
                minHeight: '500px',
              }}
            />
            <div className={styles.PlayPauseContainer}>
              <Button
                shape="circle"
                icon={
                  isPlaying ? (
                    <CaretRightOutlined className={styles.PlayPauseIcon} />
                  ) : (
                    <PauseOutlined className={styles.PlayPauseIcon} />
                  )
                }
                onClick={() => setIsPlaying((prevIsPlaying) => !prevIsPlaying)}
                className={styles.PlayPauseButton}
              />
            </div>
          </div>
        </Col>
        <div className={styles.SloganContainer}>
          <Row justify="center">
            <Col xs={20} sm={24}>
              <h1 className={styles.Slogan}>
                {`${texts.sloganPartOne} ${texts.sloganPartTwo} ${texts.sloganPartThree}`}
              </h1>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default VideoSlogan;
