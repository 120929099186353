import TricaoLogo from 'assets/images/tricao-logo.png';
import React, { FC } from 'react';
import { Col, Row } from 'antd';
import styles from './ShareLinkNavbar.module.scss';

const ShareLinksNavbar: FC = () => {
  return (
    <Row justify="center" align="middle" className={styles.Container}>
      <Col xs={10} sm={8} className={styles.LogoContainer}>
        <img src={TricaoLogo} alt="Tricao Logo" className={styles.Logo} />
      </Col>
    </Row>
  );
};

export default ShareLinksNavbar;
