import React, { FC } from 'react';
import { Row, Card, Col } from 'antd';
import PlaceIcon from 'assets/images/place-icon.svg';
import styles from './PlaceCard.module.scss';

interface Props {
  imageUrl: string;
  title: string;
}

const PlaceCard: FC<Props> = ({ imageUrl, title }: Props) => {
  return (
    <Card
      className={styles.PlaceCard}
      hoverable
      cover={<img className={styles.PlaceImage} src={imageUrl} alt={title} />}
    >
      <Card.Meta
        title={
          <Row justify="space-between">
            <Col span={1}>
              <img src={PlaceIcon} alt="PlaceIcon" />
            </Col>
            <Col span={22}>
              <span className={styles.Title}>{title}</span>
            </Col>
          </Row>
        }
      />
    </Card>
  );
};

export default PlaceCard;
