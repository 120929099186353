import { OpenDay } from 'apollo/types';

const getLastOpenDay = (openDays: OpenDay[] | undefined) => {
  const lastOpenDay = openDays?.reduce((maxOpenDay: OpenDay, currentOpenDay) => {
    const currentMaxDate = maxOpenDay ? maxOpenDay.day : null;
    const currentDate = currentOpenDay.day;

    return !currentMaxDate || currentDate > currentMaxDate ? currentOpenDay : maxOpenDay;
  });
  return lastOpenDay;
};

export default getLastOpenDay;
