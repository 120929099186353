import { Carousel, Col, Image, Row } from 'antd';
import useGetProgramQuery from 'apollo/resolvers/programs/get';
import { Program } from 'apollo/types';
import { useProgramViewModel } from 'components/Program/program.viewModel';
import LoadingModal from 'components/generalUI/LoadingModal';
import React, { FC, useEffect, useState } from 'react';
import * as texts from '../../../../assets/texts/program';
import styles from './ImageGallery.module.scss';

export interface ImageGalleryProps {
  programId: string;
}

export const ImageGallery: FC<ImageGalleryProps> = ({ programId }: ImageGalleryProps) => {
  const { setProgramFromQuery, isMobile } = useProgramViewModel();
  const [program, setProgram] = useState<Program>();

  const { data, loading } = useGetProgramQuery(programId);

  useEffect(() => {
    setProgramFromQuery(data, setProgram);
  }, [data]);

  const notProgramSecondaryImages =
    !program || !program.secondaryImages || program.secondaryImages?.length === 0;
  if (notProgramSecondaryImages) {
    return <></>;
  }

  return (
    <>
      <LoadingModal visible={loading} />
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <h1 className={styles.Title}>{texts.imageGallery}</h1>
            </Col>
          </Row>
          {!isMobile ? (
            <Row justify="center" gutter={{ sm: 5, md: 20 }}>
              {program.secondaryImages?.map((image) => (
                <Col className={styles.ImagesContainer} key={image.id} span={12}>
                  <Image src={image.url} alt="" className={styles.Image} />
                </Col>
              ))}
            </Row>
          ) : (
            <Carousel className={styles.ImagesContainer} swipeToSlide slidesToShow={1}>
              {program.secondaryImages?.map((image) => (
                <div key={image.id} className={styles.ImageContainerCarousel}>
                  <Image src={image.url} alt="" className={styles.Image} />
                </div>
              ))}
            </Carousel>
          )}
        </Col>
      </Row>
    </>
  );
};
