import React, { FC } from 'react';
import { Space } from 'antd';
import useListParkAvailabilityQuery from 'apollo/resolvers/parkAvailability/list';
import moment from 'moment';
import updateDateLocaleES from 'utils/dates/updateDateLocaleES';
import formatDateToMonthString from 'utils/dates/formatDateToMonthString';
import { ParkAvailability } from 'apollo/types';
import styles from './ActualParkAvailability.module.scss';

interface ParkAvailabilityProps {
  date?: string;
}

const ActualParkAvailability: FC<ParkAvailabilityProps> = ({
  date,
}: ParkAvailabilityProps) => {
  updateDateLocaleES('es');

  const { data: parkAvailabilityData } = useListParkAvailabilityQuery();

  const formatParkAvailability = (availability: ParkAvailability) => {
    const time = `${availability?.aperture} a ${availability?.closure}`;
    const months = `${formatDateToMonthString(
      availability?.startDate
    )} a ${formatDateToMonthString(availability?.endDate)}`;
    const dateToCompare = date ? moment(date) : moment();
    const isActualParkAvailability = dateToCompare.isBetween(
      availability.startDate,
      availability.endDate
    );
    return { time, months, isActualParkAvailability };
  };

  return (
    <Space className={styles.Container} size="large">
      {parkAvailabilityData?.listParkAvailability?.map((availability) => {
        const { time, months, isActualParkAvailability } =
          formatParkAvailability(availability);
        return (
          <div
            className={
              isActualParkAvailability
                ? styles.ActualParkAvailability
                : styles.NotActualParkAvailability
            }
            key={availability.id}
          >
            {months}
            <br />
            {time}
          </div>
        );
      })}
    </Space>
  );
};

ActualParkAvailability.defaultProps = {
  date: undefined,
};

export default ActualParkAvailability;
