/* eslint-disable @typescript-eslint/no-misused-promises */
import { Card, Form, FormInstance, Input, InputNumber } from 'antd';
import React, { FC } from 'react';
import * as texts from 'assets/texts/giftCard';
import { useHistory } from 'react-router-dom';
import priceFormat from 'utils/formats/priceFormat';
import Button from 'components/generalUI/Button';
import styles from './GiftCardForm.module.scss';

interface GiftCardFormProps {
  form: FormInstance<{
    from: string;
    to: string;
    amount: number;
  }>;
}

const validateMessages = {
  number: {
    range: texts.amountMustBeBetween,
  },
};

const GiftCardForm: FC<GiftCardFormProps> = ({ form }: GiftCardFormProps) => {
  const { from, to, amount } = form.getFieldsValue();
  const history = useHistory();

  const onSubmit = async () => {
    await form.validateFields();
    history.push(`/summary?from=${from}&to=${to}&amount=${amount}`);
    form.resetFields();
  };

  return (
    <div className={styles.CardContainer}>
      <Card className={styles.Card}>
        <h3 className={styles.CardTitle}>{texts.giftCardFormTitle}</h3>
        <Form form={form} layout="vertical" validateMessages={validateMessages}>
          <p className={styles.FormLabel}>{texts.giftCardFromNameField}</p>
          <Form.Item
            name="from"
            rules={[{ required: true, message: texts.requiredName }]}
          >
            <Input maxLength={25} className={styles.Input} />
          </Form.Item>
          <p className={styles.FormLabel}>{texts.giftCardToNameField}</p>
          <Form.Item name="to" rules={[{ required: true, message: texts.requiredName }]}>
            <Input maxLength={25} className={styles.Input} />
          </Form.Item>
          <p className={styles.FormLabel}>{texts.giftCardAmountField}</p>
          <Form.Item
            name="amount"
            rules={[
              { required: true, message: texts.requiredAmount },
              { type: 'number', min: 3000, max: 300000 },
            ]}
          >
            <InputNumber
              prefix="$"
              step={1000}
              className={styles.Input}
              formatter={(value) => {
                const formattedValue = priceFormat
                  .format(Number(value))
                  .replace(/\$/g, '');
                return formattedValue;
              }}
              parser={(value) => {
                if (!value) {
                  return 0;
                }
                const parsedValue = value.replace(/\./g, '');
                return parsedValue;
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button className={styles.FormButton} shape="round" onClick={onSubmit}>
              {texts.buyGiftCard}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default GiftCardForm;
