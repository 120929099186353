import { gql, useQuery } from '@apollo/client';
import { Pack } from 'apollo/generated/types';

const LIST_PACKS = gql`
  query listPacks {
    listPacks {
      id
      name
      adultValue
      otherValue
      description
      status
      visibility
      activities {
        id
        name
      }
      entranceTicketTypes {
        id
        name
      }
      packImages {
        image
      }
    }
  }
`;

const useListPacksQuery = () => {
  return useQuery<{ listPacks: Pack[] }>(LIST_PACKS, {});
};
export default useListPacksQuery;
