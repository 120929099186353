import { Row, Col, Card } from 'antd';
import React, { FC } from 'react';
import GiftCardDescriptionImage from 'assets/images/gift-card-description.jpg';
import * as texts from 'assets/texts/giftCard';
import ConfigureYourGiftCard from 'components/GiftCard/ConfigureYourGiftCard';
import styles from './GiftCard.module.scss';

const GiftCard: FC = () => {
  return (
    <div>
      <Row justify="center" align="middle">
        <Col span={24}>
          <h2 className={styles.Title}>{texts.giftCardTitle}</h2>
        </Col>
        <Col span={18}>
          <h3 className={styles.SubTitle}>{texts.giftCardSubtitle}</h3>
        </Col>
      </Row>

      <Row align="middle" className={styles.Row}>
        <Col xs={{ span: 20 }} sm={{ span: 12, offset: 0, push: 2 }}>
          <div className={styles.CardContainer}>
            <Card className={styles.Card}>
              <h1 className={styles.CardTitle}>{texts.giftNatureTitle}</h1>
              <p className={styles.CardSubtitle}>{texts.giftNatureText}</p>
            </Card>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={10} className={styles.ImageContainer}>
          <img
            src={GiftCardDescriptionImage}
            alt="ShareSocialMedia"
            className={styles.MainImage}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ConfigureYourGiftCard />
        </Col>
      </Row>
    </div>
  );
};

export default GiftCard;
