import { Col, Row, Image } from 'antd';
import React, { FC } from 'react';
import { NewsPostImage } from 'apollo/types';
import styles from './CloseImages.module.scss';

export interface CloseImagesProps {
  images: NewsPostImage[];
}

export const CloseImages: FC<CloseImagesProps> = ({ images }: CloseImagesProps) => {
  return (
    <Row justify="center" gutter={{ sm: 40, md: 60, lg: 80 }}>
      {images.map((image) => (
        <Col className={styles.ImagesContainer} key={image.id} sm={12} xs={24}>
          <div className={styles.ImageContainer}>
            <Image src={image.url} alt="" className={styles.Image} />
          </div>
        </Col>
      ))}
    </Row>
  );
};
