import axios, { AxiosResponse } from 'axios';
import { InstagramPostResponse } from 'components/Home/InstaFeed/types';

const limit = 9;
const token = process.env.REACT_APP_FEED_INSTAGRAM_TOKEN || '';

const INSTAGRAM_API_URL = `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,permalink,thumbnail_url&limit=${limit}&access_token=${token}`;

const fetchInstagramFeed = (): Promise<AxiosResponse<InstagramPostResponse>> => {
  return new Promise((resolve, reject) => {
    try {
      const response = axios.get(INSTAGRAM_API_URL);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export default fetchInstagramFeed;
