import { gql, useQuery } from '@apollo/client';
import {
  EnrichedPurchaseMailer,
  QueryGetEnrichedPurchaseArgs,
} from 'apollo/generated/types';

const GET_ENRICHED_PURCHASE = gql`
  query getEnrichedPurchase($uuid: ID!) {
    getEnrichedPurchase(uuid: $uuid) {
      totalPrice
      year
      purchase {
        id
        visitDate
        createdAt
        activityTickets {
          productId
          price
          quantity
          percentageDiscount
          name
          finalPrice
        }
        entranceTickets {
          productId
          price
          quantity
          percentageDiscount
          name
          finalPrice
        }
        packPurchases {
          productId
          price
          quantity
          percentageDiscount
          name
          finalPrice
        }
        grillTickets {
          productId
          price
          quantity
          percentageDiscount
          name
          finalPrice
        }
        activityTimes {
          id
          name
          activityTime {
            startHour
            endHour
          }
        }
        giftCard {
          id
        }
      }
      qrCodeImage
      client {
        firstName
        lastName
        email
      }
    }
  }
`;
const useGetEnrichedPurchaseQuery = (uuid: string) => {
  return useQuery<
    { getEnrichedPurchase: EnrichedPurchaseMailer },
    QueryGetEnrichedPurchaseArgs
  >(GET_ENRICHED_PURCHASE, {
    variables: { uuid },
  });
};
export default useGetEnrichedPurchaseQuery;
