import { CreatePurchaseActivitiesInput } from 'apollo/types';
import { ActivityPurchase } from 'storage/types';

const purchaseActivities = (
  activities: ActivityPurchase[]
): CreatePurchaseActivitiesInput[] => {
  const mappedActivities: CreatePurchaseActivitiesInput[] = [];
  activities.forEach((activity: ActivityPurchase) => {
    const mappedActivity: CreatePurchaseActivitiesInput = {
      activityTimeId: activity.activityTimeId,
      id: activity.id,
      ticketCount: activity.ticketCount,
    };
    mappedActivities.push(mappedActivity);
  });
  return mappedActivities;
};

export default purchaseActivities;
