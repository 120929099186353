/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Col, Empty, Row } from 'antd';
import { Program } from 'apollo/generated/types';
import useListProgramsQuery from 'apollo/resolvers/programs/list';
import { InformationCard } from 'components/generalUI/InformationCard';
import LoadingModal from 'components/generalUI/LoadingModal';
import React, { FC, useEffect, useState } from 'react';
import * as texts from 'assets/texts/programs';
import styles from './Programs.module.scss';

const Programs: FC = () => {
  const [programs, setPrograms] = useState<Program[]>([]);

  const { data, loading } = useListProgramsQuery();

  const setProgramsFromQuery = () => {
    if (data) {
      setPrograms(data.listPrograms);
    }
  };

  useEffect(() => {
    setProgramsFromQuery();
  }, [data]);

  return (
    <>
      <LoadingModal visible={loading} />
      {programs && programs.length > 0 ? (
        <Row justify="center" gutter={{ sm: 15, md: 30, lg: 80 }}>
          {programs.map((program) => (
            <Col
              key={program.id}
              className={styles.InformationCardColumn}
              xs={24}
              sm={12}
            >
              <InformationCard
                title={program.title}
                subtitle={program.subtitle}
                id={program.id}
                imageUrl={program.coverImage?.url}
                isProgram
                color="green"
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Row justify="center">
          <Col>
            <Empty description={texts.noAvailablePrograms} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Programs;
