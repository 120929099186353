import { InMemoryCache, makeVar } from '@apollo/client';
import { CustomError } from 'apollo/types';
import { EntranceTicketType, Pack } from './generated/types';

export const useMocksCache = makeVar<boolean>(false);
export const showErrorModal = makeVar<boolean>(false);
export const errorData = makeVar<CustomError | null>(null);
export const selectedItem = makeVar<Pack | EntranceTicketType | null>(null);

const cache = new InMemoryCache({});

export default cache;
