import React, { FC } from 'react';
import { Steps, Row, Col } from 'antd';
import { StepsProps } from 'antd/lib/steps';
import './PurchaseSteps.scss';
import _ from 'lodash';

interface CustomStepsProps extends StepsProps {
  total: number;
}

const PurchaseSteps: FC<CustomStepsProps> = (props: CustomStepsProps) => {
  const { total, current, ...otherProps } = props;
  const { Step } = Steps;

  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={20} md={16} lg={10}>
        <div className="stepsWrapper">
          <Steps
            current={current}
            labelPlacement="vertical"
            {...otherProps}
            responsive={false}
          >
            {_.times(total, (i) => (
              <Step key={i} />
            ))}
          </Steps>
        </div>
      </Col>
    </Row>
  );
};

export default PurchaseSteps;
