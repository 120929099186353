import ShareSocialMediaImage from 'assets/images/share-social-media-image.jpg';
import { Carousel, Col, Row } from 'antd';
import useListProgramsQuery from 'apollo/resolvers/programs/list';
import { Program } from 'apollo/types';
import { InformationCard } from 'components/generalUI/InformationCard';
import LoadingModal from 'components/generalUI/LoadingModal';
import React, { FC, useEffect, useState } from 'react';
import * as texts from 'assets/texts/program';
import { useProgramViewModel } from '../program.viewModel';
import styles from './OtherPrograms.module.scss';

export interface OtherProgramsProps {
  programId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const OtherPrograms: FC<OtherProgramsProps> = ({
  programId,
}: OtherProgramsProps) => {
  const { isMobile } = useProgramViewModel();
  const [programs, setPrograms] = useState<Program[]>([]);
  const { data, loading } = useListProgramsQuery();

  const setProgramsFromQuery = () => {
    if (data) {
      const filteredPrograms = data.listPrograms.filter(
        (program) => program.id !== programId
      );
      setPrograms(filteredPrograms);
    }
  };

  const renderOtherPrograms = () => {
    if (isMobile) {
      return (
        <Carousel className={styles.Carousel} draggable swipeToSlide slidesToShow={1}>
          {programs.map((program) => (
            <div key={program.id} className={styles.CardContainer}>
              <InformationCard
                title={program.title}
                subtitle={program.subtitle}
                id={program.id}
                imageUrl={program.coverImage?.url}
                isProgram
                color="green"
              />
            </div>
          ))}
        </Carousel>
      );
    }
    return (
      <Row className={styles.Row} justify="center" gutter={{ sm: 5, md: 20, lg: 30 }}>
        {programs.map((program) => (
          <Col key={program.id} xs={24} sm={8}>
            <InformationCard
              key={program.id}
              title={program.title}
              subtitle={program.subtitle}
              id={program.id}
              imageUrl={program.coverImage?.url}
              isProgram
              color="green"
            />
          </Col>
        ))}
      </Row>
    );
  };

  useEffect(() => {
    setProgramsFromQuery();
  }, [data]);

  return (
    <>
      <LoadingModal visible={loading} />
      <Row>
        <Col span={24}>
          <div className={styles.ImageContainer}>
            <div className={styles.BackGroundImageContainer}>
              <img
                className={styles.Image}
                src={ShareSocialMediaImage}
                alt="Share Social Media"
              />
            </div>
          </div>
          <Row className={styles.Container} justify="center">
            <Col xs={18} lg={16} xxl={14}>
              <h1 className={styles.Title}>{texts.otherPrograms}</h1>
            </Col>
            <Col xs={18} lg={16} xxl={14}>
              {renderOtherPrograms()}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
