import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Input, Card, Form, Radio } from 'antd';
import LoadingModal from 'components/generalUI/LoadingModal';
import ClientPurchases from 'components/PurchaseChange/ClientPurchases';
import useListChangeablePurchasesQuery from 'apollo/resolvers/purchases/listChangeablePurchases';
import * as texts from 'assets/texts/purchaseChange';
import useAuthenticateUserMutation from 'apollo/resolvers/clients/authenticateUser';
import emailRegExp from 'utils/validations/emailRegExp';
import dniValidator from 'utils/validations/dniValidator';
import rutFormValidator from 'utils/validations/rutFormValidator';
import rutFormat from 'utils/formats/rutFormat';
import Button from 'components/generalUI/Button';
import styles from './PurchaseChange.module.scss';
import { PurchaseChangeFormData } from './types';

const PurchaseChange: FC = () => {
  const { token } = useParams<{ token: string }>();
  const [form] = Form.useForm();
  const { Item } = Form;

  const [userAuthenticationSuccess, setUserAuthenticationSuccess] =
    useState<boolean>(false);
  const [authenticateUser, { loading: isEmailVerificationLoading }] =
    useAuthenticateUserMutation();
  const [isPassport, setIsPassport] = useState<boolean>(false);

  const onFinish = (formData: PurchaseChangeFormData) => {
    const { email, documentIsPassport, documentNumber } = formData;
    authenticateUser({ variables: { email, documentIsPassport, documentNumber } })
      .then((response) => {
        if (response.data) {
          setUserAuthenticationSuccess(response.data.authenticateUser.success);
        } else {
          setUserAuthenticationSuccess(false);
        }
      })
      .catch(() => {
        setUserAuthenticationSuccess(false);
      });
  };

  const { data, loading: loadingPurchases } = useListChangeablePurchasesQuery(
    token,
    !token
  );

  const renderDocumentIsPassportField = () => {
    return (
      <Item name="documentIsPassport">
        <Radio.Group
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          onChange={(e) => setIsPassport(e.target.value)}
        >
          <Radio value={false}>{texts.rut}</Radio>
          <Radio value>{texts.passport}</Radio>
        </Radio.Group>
      </Item>
    );
  };

  const renderDocumentNumberField = () => {
    if (isPassport) {
      return (
        <Item
          name="documentNumber"
          rules={[
            { required: true, message: texts.warning },
            { validator: dniValidator },
          ]}
        >
          <Input className={styles.FormInput} placeholder={`${texts.dni} *`} />
        </Item>
      );
    }
    return (
      <Form.Item
        name="documentNumber"
        rules={[
          { required: true, message: texts.warning },
          { validator: rutFormValidator },
        ]}
        normalize={rutFormat}
      >
        <Input className={styles.FormInput} placeholder={`${texts.rutExample} *`} />
      </Form.Item>
    );
  };

  return (
    <div>
      <LoadingModal visible={isEmailVerificationLoading || loadingPurchases} />
      <Row justify="center">
        <Col>
          <h1 className={styles.Title}>{texts.title}</h1>
        </Col>
      </Row>
      {data ? (
        <ClientPurchases
          changeablePurchases={data.listChangeablePurchases}
          token={token}
        />
      ) : (
        <>
          <Row justify="center">
            <Col xs={20} sm={18} lg={14} xl={12}>
              <Card className={styles.Card}>
                {userAuthenticationSuccess ? (
                  <>
                    <p>{texts.sentEmailText}</p>
                  </>
                ) : (
                  <>
                    <p>{texts.enterData}</p>
                    <Form
                      form={form}
                      onFinish={onFinish}
                      initialValues={{
                        documentIsPassport: false,
                      }}
                    >
                      <Item
                        name="email"
                        rules={[
                          { required: true, message: texts.warning },
                          {
                            message: texts.invalidEmail,
                            pattern: emailRegExp(),
                          },
                        ]}
                      >
                        <Input placeholder={texts.email} />
                      </Item>
                      {renderDocumentIsPassportField()}
                      {renderDocumentNumberField()}
                      <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.SubmitButton}
                      >
                        {texts.send}
                      </Button>
                    </Form>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <Row justify="center">
            <Col className={styles.Footer} xs={20} sm={18} lg={14} xl={12}>
              <p>
                {texts.footerText}
                <br />
                <br />
                {texts.footerTextAvailability}
              </p>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default PurchaseChange;
