import { gql, useQuery } from '@apollo/client';
import { Place, QueryGetPlaceArgs } from 'apollo/generated/types';

const GET_PLACE = gql`
  query getPlace($id: ID!) {
    getPlace(id: $id) {
      id
      mainDescription
      name
      placeImages {
        id
        image
      }
      placeMainImage
      secondaryDescription
      thingsToDo {
        id
        name
        description
        thingToDoImages {
          id
          image
        }
      }
    }
  }
`;
const useGetPlaceQuery = (id: string) => {
  return useQuery<{ getPlace: Place }, QueryGetPlaceArgs>(GET_PLACE, {
    variables: { id },
  });
};
export default useGetPlaceQuery;
