import { GetTotalPriceEntrancesInput } from 'apollo/types';
import { PackOrEntrancePurchase } from 'storage/types';

const purchaseEntrance = (
  entrances: PackOrEntrancePurchase[]
): GetTotalPriceEntrancesInput[] => {
  const mappedEntrances: GetTotalPriceEntrancesInput[] = [];
  entrances.forEach((entrance: PackOrEntrancePurchase) => {
    const mappedEntrance: GetTotalPriceEntrancesInput = {
      adultTicketCount: entrance.adultTicketCount,
      id: entrance.id,
      otherTicketCount: entrance.otherTicketCount,
    };
    mappedEntrances.push(mappedEntrance);
  });
  return mappedEntrances;
};

export default purchaseEntrance;
