import { gql, useLazyQuery } from '@apollo/client';
import { ListNewsPostsResponse, QueryListNewsPostsArgs } from 'apollo/generated/types';

const LIST_NEWS_POSTS = gql`
  query listNewsPosts($input: ListNewsPostsInput!) {
    listNewsPosts(input: $input) {
      newsPosts {
        id
        title
        featured
        coverImage {
          id
          url
        }
      }
      count
    }
  }
`;
const useLazyListNewsPostsQuery = () => {
  return useLazyQuery<{ listNewsPosts: ListNewsPostsResponse }, QueryListNewsPostsArgs>(
    LIST_NEWS_POSTS
  );
};
export default useLazyListNewsPostsQuery;
