import { gql, useMutation } from '@apollo/client';
import { MutationUpdateTicketsDateTimeArgs } from 'apollo/generated/types';

const UPDATE_TICKETS_DATE_TIME = gql`
  mutation updateTicketsDateTime($input: UpdateTicketsDateTimeInput!) {
    updateTicketsDateTime(input: $input) {
      success
    }
  }
`;

const useUpdateTicketsDateTimeMutation = () => {
  return useMutation<
    { updateTicketsDateTime: { success: boolean } },
    MutationUpdateTicketsDateTimeArgs
  >(UPDATE_TICKETS_DATE_TIME, {});
};
export default useUpdateTicketsDateTimeMutation;
