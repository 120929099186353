import LoadingModal from 'components/generalUI/LoadingModal';
import React, { FC, useState } from 'react';
import InstagramEmbed from 'react-instagram-embed';
import { InstaPostProps } from '../types';

const Post: FC<InstaPostProps> = ({ permalink, access_token }) => {
  const [modalVisibility, setModalVisibility] = useState<boolean>(true);
  return (
    <>
      <LoadingModal visible={modalVisibility} />
      <InstagramEmbed
        url={permalink}
        clientAccessToken={access_token}
        hideCaption
        onLoading={() => setModalVisibility(true)}
        onSuccess={() => setModalVisibility(false)}
      />
    </>
  );
};

export default Post;
