import { gql, useLazyQuery } from '@apollo/client';
import { BillingSystemCity } from 'apollo/generated/types';

const LIST_BILLING_SYSTEM_CITIES = gql`
  query listBillingSystemCities {
    listBillingSystemCities {
      id
      name
    }
  }
`;

const useListBillingSystemCitiesLazyQuery = () => {
  return useLazyQuery<{ listBillingSystemCities: BillingSystemCity[] }>(
    LIST_BILLING_SYSTEM_CITIES
  );
};

export default useListBillingSystemCitiesLazyQuery;
