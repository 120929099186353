import React from 'react';
import { useLocation } from 'react-router-dom';

const dateQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const getDateSearchParam = (): string => {
  const query = dateQuery();
  const dateString = query.get('date');
  const date = dateString ?? new Date().toISOString();

  return date;
};

export default getDateSearchParam;
