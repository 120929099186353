import React, { FC, useEffect, useState } from 'react';
import useGetPlaceQuery from 'apollo/resolvers/places/get';
import { useParams } from 'react-router-dom';
import Activities from 'components/sharedComponents/Activities';
import Places from 'components/sharedComponents/Places';
import ShowPlace from 'components/ShowPlace';
import idToLetter from 'utils/helpers/idToLetter';
import { Place, ThingToDo } from 'apollo/types';
import useListGrillsQuery from 'apollo/resolvers/grills/list';
import ListGrills from 'components/Grills/ListGrills';
import styles from './Place.module.scss';

const PlaceComponent: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetPlaceQuery(id);
  const { data: grillsData } = useListGrillsQuery();
  const [place, setPlace] = useState<Place | null>(null);
  const [thingsToDo, setThingsToDo] = useState<ThingToDo[] | null>(null);
  const [grills, setGrills] = useState<Place[]>();

  const getGrills = () => {
    if (grillsData) {
      setGrills(grillsData.listGrills);
    }
  };

  const renderGrills = () => {
    if (place?.name === 'Quinchos' && grills) {
      return <ListGrills grills={grills} />;
    }
    return <></>;
  };

  const getPlace = () => {
    if (data && data.getPlace) {
      setPlace(data.getPlace);
    }
  };

  useEffect(() => {
    getPlace();
  }, [data]);

  useEffect(() => {
    getGrills();
  }, [grillsData]);

  useEffect(() => {
    if (place && place.thingsToDo && place.thingsToDo.length > 0) {
      setThingsToDo(place.thingsToDo as ThingToDo[]);
    }
  }, [place]);

  return (
    <div className={styles.Container}>
      {place && (
        <ShowPlace place={place} loading={loading} letter={idToLetter(place.id)} />
      )}
      {renderGrills()}
      {thingsToDo && <Activities thingsToDo={thingsToDo} />}
      <Places />
    </div>
  );
};

export default PlaceComponent;
