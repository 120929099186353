import React, { ReactElement } from 'react';
import { historyTitle, historyText, yearsDescriptions } from 'assets/texts/weAre';
import historyIcon from 'assets/images/history-icon.svg';
import parse from 'html-react-parser';
import styles from './History.module.scss';
import Timeline from './Timeline';

const BussinessValues = (): ReactElement => {
  return (
    <div className={styles.Container}>
      <div className={styles.TitleContainer}>
        <img src={historyIcon} alt="messageIcon" className={styles.Icon} />
        <h2 className={styles.Title}>{historyTitle}</h2>
      </div>
      <div className={styles.TextContainer}>
        <p className={styles.Text}>{parse(historyText)}</p>
      </div>
      <Timeline yearsDescriptions={yearsDescriptions} />
    </div>
  );
};

export default BussinessValues;
