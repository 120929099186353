import React, { useEffect, useState } from 'react';
import { Input, Form, message, Row, Col, Select, DatePicker } from 'antd';
import Button from 'components/generalUI/Button';
import * as texts from 'assets/texts/corporateEvents';
import emailRegExp from 'utils/validations/emailRegExp';
import { addDays } from 'utils/dates/isDayInArray';
import { DefaultOptionType } from 'antd/lib/select';
import cellPhoneRegExp from 'utils/validations/cellPhoneRegExp';
import rutFormValidator from 'utils/validations/rutFormValidator';
import rutFormat from 'utils/formats/rutFormat';
import useListParkAvailabilityQuery from 'apollo/resolvers/parkAvailability/list';
import LoadingModal from 'components/generalUI/LoadingModal';
import useSendCorporateEventsEmail from 'apollo/resolvers/contactUs/sendCorporateEventsEmail';
import useListOpenDaysQuery from 'apollo/resolvers/openDays/list';
import isWeekendDate from 'utils/dates/isWeekendDate';
import styles from './Form.module.scss';

const OfficeTripsForm = () => {
  const currentDate = new Date().toISOString().split('T')[0];

  const { data: parkAvailabilityData, loading: loadingParkAvailability } =
    useListParkAvailabilityQuery();

  const { data: openDaysData, loading: loadingOpenDays } =
    useListOpenDaysQuery(currentDate);

  const [form] = Form.useForm();
  const [sendCorporateEventsEmail] = useSendCorporateEventsEmail();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [openDays, setOpenDays] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedHour, setSelectedHour] = useState<string>();

  const setOpenDaysFromQueryData = () => {
    if (!openDaysData || !openDaysData.listOpenDays) {
      return;
    }

    const days = openDaysData.listOpenDays.map((day) => day.day.split('T')[0]);
    setOpenDays(days);
  };

  const isDisabledDate = (date: moment.Moment) => {
    const dateDate = date.toDate();
    const currentDateMinusOne = addDays(new Date(), -1);
    if (dateDate < currentDateMinusOne) {
      return true;
    }
    const isDateInOpenDays = openDays.includes(date.format('YYYY-MM-DD'));
    if (!isDateInOpenDays) {
      return true;
    }
    if (isWeekendDate(dateDate)) {
      return true;
    }
    return false;
  };

  const onSubmit = async () => {
    setDisabled(true);
    if (!selectedDate || !selectedHour) {
      await message.error(texts.requiredTripDate);
      return;
    }

    await form.validateFields();
    const values = form.getFieldsValue() as {
      companyName: string;
      companyRut: string;
      contactName: string;
      email: string;
      phone: string;
      amountOfPeople: string;
      message?: string;
    };

    const mailVariables = {
      ...values,
      amountOfPeople: Number(values.amountOfPeople),
      tripDate: selectedDate?.toISOString().split('T')[0],
      tripHour: selectedHour,
    };

    const response = await sendCorporateEventsEmail({
      variables: {
        input: {
          ...mailVariables,
        },
      },
    });
    if (response) {
      await message.success(texts.messageSent);
    } else {
      await message.error(texts.messageSentError);
    }
    form.resetFields();
    setDisabled(false);
  };

  const onDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }
    setSelectedDate(date.toDate());
  };

  const getAllHoursBetweenLimits = (aperture: string, closure: string) => {
    const apertureHour = Number(aperture.split(':')[0]);
    const closureHour = Number(closure.split(':')[0]);

    const hours = [];

    // eslint-disable-next-line no-plusplus
    for (let i = apertureHour; i <= closureHour; i++) {
      hours.push(`${i}:00`);
    }

    return hours;
  };

  const getParkHours = (): DefaultOptionType[] => {
    if (!parkAvailabilityData || !parkAvailabilityData.listParkAvailability) {
      return [];
    }
    const currentDay = new Date();

    const parkHoursLimits: { aperture: string; closure: string }[] = [];

    parkAvailabilityData.listParkAvailability.forEach((availability) => {
      const { startDate, endDate, aperture, closure } = availability;

      if (new Date(startDate) < currentDay && new Date(endDate) > currentDay) {
        parkHoursLimits.push({ aperture, closure });
      }
    });

    const parkHours = getAllHoursBetweenLimits(
      parkHoursLimits[0].aperture,
      parkHoursLimits[0].closure
    );

    return parkHours.map((hour) => ({ label: hour, value: hour }));
  };

  useEffect(() => {
    getParkHours();
  }, [parkAvailabilityData]);

  useEffect(() => {
    setOpenDaysFromQueryData();
  }, [openDaysData]);

  return (
    <div className={styles.Form}>
      <LoadingModal visible={loadingParkAvailability || loadingOpenDays} />
      <Form form={form} layout="vertical">
        <p className={styles.FormTitle}>{texts.quoteDetails}</p>
        <Row gutter={[20, 16]} justify="space-between">
          <Col xs={24} sm={12} className="ComprisedCol">
            <Form.Item
              className={styles.FormItem}
              name="companyName"
              rules={[{ required: true, message: texts.requiredCompanyName }]}
            >
              <Input className={styles.Input} placeholder={texts.companyName} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              className={styles.FormItem}
              name="companyRut"
              rules={[
                { required: true, message: texts.requiredCompanyRut },
                { validator: rutFormValidator },
              ]}
              normalize={rutFormat}
            >
              <Input className={styles.Input} placeholder={texts.companyRut} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              className={styles.FormItem}
              name="contactName"
              rules={[{ required: true, message: texts.requiredFullContactName }]}
            >
              <Input className={styles.Input} placeholder={texts.fullContactName} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              className={styles.FormItem}
              name="email"
              rules={[
                { required: true, message: texts.requiredEmail },
                {
                  message: texts.invalidEmail,
                  pattern: emailRegExp(),
                },
              ]}
            >
              <Input className={styles.Input} placeholder={texts.email} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              className={styles.FormItem}
              name="phone"
              rules={[
                { required: true, message: texts.requiredPhone },
                {
                  message: texts.invalidCellphone,
                  pattern: cellPhoneRegExp(),
                },
              ]}
            >
              <Input
                className={styles.Input}
                placeholder={`${texts.phone} *, Ej: +56912345678`}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              className={styles.FormItem}
              name="tripDate"
              rules={[{ required: true, message: texts.requiredTripDate }]}
            >
              <DatePicker
                placeholder={texts.tripDate}
                className={styles.DatePicker}
                format={(value) => {
                  if (value) {
                    return value.format('DD/MM/YYYY');
                  }
                  return '';
                }}
                disabledDate={(date) => {
                  return isDisabledDate(date);
                }}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={(date) => onDateChange(date)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              className={styles.FormItem}
              name="tripHour"
              rules={[{ required: true, message: texts.requiredTripHour }]}
            >
              <Select
                className={styles.FormSelect}
                options={getParkHours()}
                placeholder={texts.tripHour}
                onChange={setSelectedHour}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              className={styles.FormItem}
              name="amountOfPeople"
              rules={[{ required: true, message: texts.requiredAmountOfPeople }]}
            >
              <Input
                className={styles.Input}
                placeholder={`${texts.amountOfPeople}`}
                type="number"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="message" className={styles.FormItem}>
              <Input.TextArea className={styles.Input} placeholder={texts.comment} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button
                className={styles.FormButton}
                shape="round"
                onClick={onSubmit}
                disabled={disabled}
              >
                {texts.send}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OfficeTripsForm;
