import { message as messageComponent } from 'antd';
import { showErrorModal, errorData } from 'apollo/cache';
import { CustomError } from 'apollo/types';

const messageDuration = 10;

const ErrorHandler = (error: CustomError): void => {
  if (error.type === 'query' || error.type === 'FATAL') {
    showErrorModal(true);
    errorData(error);
  } else {
    errorData(error);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    messageComponent.error(
      `Error: ${error.code} - ${error.message}`,
      messageDuration,
      () => errorData(null)
    );
  }
};

export default ErrorHandler;
