export const title = 'Selecciona los tickets';
export const select = 'Seleccionar';
export const adults = 'Adultos (15-64)';
export const others = 'Niños (5-14) Adulto mayor (65+)';
export const plus = '+';
export const minus = '-';
export const oversold = 'No hay más tickets disponibles para la compra.';
export const activityTimeRemainingLowerThanTicketCount =
  'No hay suficientes tickets disponibles para los horarios de la compra.';
export const subTotal = 'Sub-total';
export const selectActivityTime = 'Selecciona el horario del ';
export const continueText = 'Continuar';
export const warningMessage =
  'Debe seleccionar la cantidad tickets y el horario de actividades';
export const addGrillTitle = '¿Quieres reservar un quincho?';
export const none = 'Ninguno';
export const warningNoActivityMessage = 'Debes seleccionar el horario para la actividad';
export const warningNoTicketMessage =
  'Debes seleccionar al menos una entrada para reservar un quincho';
export const activityTimeWarning =
  'Este horario corresponde al rango de ingreso específicamente al sector del Aviario. Puedes llegar al parque a partir de las 9 am.';
export const grillInformation =
  'Espacios privados equipados con parrilla a gas. Capacidad máxima 25 personas. Trae comida y los utensilios necesarios para preparar tu almuerzo. Cada integrante debe contar con su entrada personal.';
export const moreInformation = 'Más información';
export const certificateMessage =
  'El certificado puede ser solicitado al ingreso del parque.';
export const noRemaining = 'AGOTADO';
export const noActivityTimesRemaining =
  'No quedan más tickets para el horario elegido, por favor elegir otro horario.';
export const noGrills = 'No hay quinchos disponibles';
export const reserved = '(Reservado)';
export const selectAnotherDate = 'Seleccionar otra fecha';
export const closed = 'AGOTADO';
export const whatIncludes = '¿Qué incluye?';
export const showLess = 'Mostrar menos';
