import { LeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Program } from 'apollo/types';
import useGetProgramQuery from 'apollo/resolvers/programs/get';
import LoadingModal from 'components/generalUI/LoadingModal';
import * as texts from '../../../assets/texts/program';
import styles from './Header.module.scss';
import { useProgramViewModel } from '../program.viewModel';

export interface HeaderProps {
  programId: string;
}

const Header: FC<HeaderProps> = ({ programId }: HeaderProps) => {
  const { handleLeftIconClick, setProgramFromQuery, isMobile } = useProgramViewModel();

  const [program, setProgram] = useState<Program>();
  const containerStyle = isMobile ? styles.ContainerMobile : styles.Container;
  const imageContainerStyle = isMobile
    ? styles.ImageContainerMobile
    : styles.ImageContainer;
  const imageStyle = isMobile ? styles.ImageMobile : styles.Image;

  const { data, loading } = useGetProgramQuery(programId);

  useEffect(() => {
    setProgramFromQuery(data, setProgram);
  }, [data]);
  return (
    <>
      <LoadingModal visible={loading} />
      {program && (
        <Row className={containerStyle}>
          <Col span={24}>
            <div className={imageContainerStyle}>
              <div className={styles.ImageBlackContainer}>
                <img src={program.coverImage?.url} alt="" className={imageStyle} />
              </div>
            </div>
            <Row>
              <Col span={24}>
                <Row justify="start">
                  <Col className={styles.LeftIconContainer} span={1} offset={1}>
                    <LeftOutlined
                      className={styles.LeftIcon}
                      onClick={handleLeftIconClick}
                    />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xs={18} lg={16} xxl={14}>
                    <Row>
                      <Col span={24}>
                        <h3 className={styles.ProgramText}>{texts.programs}</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <h1 className={styles.Title}>{program.title}</h1>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Header;
