import { PackOrEntrancePurchase } from 'storage/types';
import React, { FC } from 'react';
import priceFormat from 'utils/formats/priceFormat';
import * as texts from 'assets/texts/purchaseSummary';
import { Row, Col, Card } from 'antd';
import styles from './PackOrEntraceSummaryCard.module.scss';

interface Props {
  item: PackOrEntrancePurchase;
}

const PackOrEntraceSummaryCard: FC<Props> = ({ item }: Props) => {
  return (
    <Row justify="center">
      <Col xs={20} lg={15}>
        <Card className={styles.Card}>
          <h2 className={styles.Title}>{item.name}</h2>
          <p>
            {item.adultTicketCount} {texts.adults}
          </p>
          <p>
            {item.otherTicketCount} {texts.others}
          </p>
          <p className={styles.SubTotal}>
            {texts.subTotal}
            {priceFormat.format(
              item.adultValue * item.adultTicketCount +
                item.otherValue * item.otherTicketCount
            )}
          </p>
        </Card>
      </Col>
    </Row>
  );
};

export default PackOrEntraceSummaryCard;
