export const title = 'Selecciona un tipo de entrada';
export const select = 'Seleccionar';
export const adults = 'Adultos';
export const others = 'Niños/Adulto mayor';
export const outOfStock = 'AGOTADO';
export const closedTemporary = 'CERRADO TEMPORALMENTE';
export const pay = 'Ir a pagar';
export const total = 'Total';
export const selectAnotherDate = 'Seleccionar otra fecha';
export const whatIncludes = '¿Qué incluye?';
export const showLess = 'Mostrar menos';
