import { gql, useLazyQuery } from '@apollo/client';
import {
  BillingSystemCommune,
  QueryListBillingSystemCommunesArgs,
} from 'apollo/generated/types';

const LIST_BILLING_SYSTEM_COMMUNES = gql`
  query listBillingSystemCommunes($input: ListBillingSystemCommunesArgs!) {
    listBillingSystemCommunes(input: $input) {
      id
      name
    }
  }
`;

const useListBillingSystemCommunesLazyQuery = () => {
  return useLazyQuery<
    { listBillingSystemCommunes: BillingSystemCommune[] },
    QueryListBillingSystemCommunesArgs
  >(LIST_BILLING_SYSTEM_COMMUNES);
};

export default useListBillingSystemCommunesLazyQuery;
