import React, { FC } from 'react';
import {
  PackOrEntrancePurchase,
  ActivityPurchase,
  GiftCardPurchase,
} from 'storage/types';
import PackOrEntraceSummaryCard from './PackOrEntraceSummaryCard';
import ActivityOrGrillSummaryCard from './ActivityOrGrillSummaryCard';
import GiftCardSummaryCard from './GiftCardSummaryCard';

interface SummaryCardProps {
  items: PackOrEntrancePurchase[] | ActivityPurchase[] | GiftCardPurchase[];
  type: 'pack' | 'entrance' | 'activity' | 'grill' | 'giftCard';
}

const SummaryCard: FC<SummaryCardProps> = (props: SummaryCardProps) => {
  const { items, type } = props;

  return (
    <>
      {(type === 'pack' || type === 'entrance') &&
        items.map((item) => {
          const packOrPurchase = item as PackOrEntrancePurchase;
          return (
            <PackOrEntraceSummaryCard key={packOrPurchase.id} item={packOrPurchase} />
          );
        })}
      {(type === 'activity' || type === 'grill') &&
        items.map((item) => {
          const activityOrGrill = item as ActivityPurchase;
          return (
            <ActivityOrGrillSummaryCard
              key={activityOrGrill.id}
              item={activityOrGrill}
              isGrill
            />
          );
        })}
      {type === 'giftCard' && items.length === 1 && (
        <GiftCardSummaryCard item={items[0] as GiftCardPurchase} />
      )}
    </>
  );
};

export default SummaryCard;
