import { gql, useQuery } from '@apollo/client';
import { Place } from 'apollo/generated/types';

const LIST_GRILLS = gql`
  query listGrills {
    listGrills {
      id
      mainDescription
      name
      placeMainImage
      secondaryDescription
      placeImages {
        image
      }
      activities {
        name
        value
      }
    }
  }
`;
const useListGrillsQuery = () => {
  return useQuery<{ listGrills: Place[] }>(LIST_GRILLS);
};
export default useListGrillsQuery;
