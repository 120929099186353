import React, { ReactElement } from 'react';
import { Col, Row } from 'antd';
import Image from 'assets/images/we-are.jpg';
import Logo from 'assets/images/logo.svg';
import LogoGreen from 'assets/images/logo-green.svg';
import { title } from 'assets/texts/weAre';
import styles from './ImageAndTitle.module.scss';

const ImageAndTitle = (): ReactElement => {
  return (
    <Row>
      <Col span={24}>
        <div className={styles.Container}>
          <img src={Image} alt="WeAre" className={styles.MainImage} />
          <div className={styles.TitleContainer}>
            <img src={Logo} alt="Logo" className={styles.Logo} />
            <img src={LogoGreen} alt="LogoGreen" className={styles.LogoGreen} />
            <h1 className={styles.Title}>{title}</h1>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ImageAndTitle;
