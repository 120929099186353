import React, { FC } from 'react';
import ScheduleAndPricesSection from 'components/ShareLinks/ScheduleAndPricesSection';
import RedirectionButtonsSection from 'components/ShareLinks/RedirectionButtonsSection';
import ShareLinkNavbar from 'components/ShareLinks/ShareLinkNavbar';
import { Col, Row } from 'antd';
import styles from './ShareLinks.module.scss';

const ShareLinks: FC = () => {
  return (
    <>
      <ShareLinkNavbar />
      <Row className={styles.MainContainer} justify="center">
        <Col xs={18} sm={14} md={10} lg={8} xl={6}>
          <ScheduleAndPricesSection />
          <RedirectionButtonsSection />
        </Col>
      </Row>
    </>
  );
};

export default ShareLinks;
