import { CreatePurchasePacksInput } from 'apollo/types';
import { PackOrEntrancePurchase } from 'storage/types';
import purchaseActivities from './purchaseActivities';

const purchasePacks = (packs: PackOrEntrancePurchase[]): CreatePurchasePacksInput[] => {
  const mappedPacks: CreatePurchasePacksInput[] = [];
  packs.forEach((pack: PackOrEntrancePurchase) => {
    const mappedPack: CreatePurchasePacksInput = {
      activities: purchaseActivities(pack.activities),
      adultTicketCount: pack.adultTicketCount,
      id: pack.id,
      otherTicketCount: pack.otherTicketCount,
    };
    mappedPacks.push(mappedPack);
  });
  return mappedPacks;
};

export default purchasePacks;
