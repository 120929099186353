import React, { FC } from 'react';
import styles from './TricaoMap.module.scss';

const TricaoMap: FC = () => {
  const tricaoCoordinates = {
    lat: -33.72190109226867,
    lng: -71.63934261897339,
  };

  return (
    <div className={styles.MapContainer}>
      <iframe
        className={styles.Map}
        title="googlemap"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/view?zoom=15&center=${
          tricaoCoordinates.lat
        }%2C${tricaoCoordinates.lng}&key=${
          process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
        }`}
      />
    </div>
  );
};

export default TricaoMap;
