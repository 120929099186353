import React, { FC } from 'react';
import TricaoLogo from 'assets/images/tricao-logo.png';
import { Col, Row, Space } from 'antd';
import Button from 'components/generalUI/Button';
import * as texts from 'assets/texts/navbar';
import * as events from 'utils/post-hog/events';
import captureEvent from 'utils/post-hog';
import styles from './NavBar.module.scss';
import NavMenu from './NavMenu';

const NavBar: FC = () => {
  return (
    <div>
      <Row justify="center" className={styles.Container} align="middle">
        <Col xs={4} sm={8}>
          <NavMenu />
        </Col>
        <Col xs={10} sm={8} className={styles.LogoContainer}>
          <a href="/">
            <img src={TricaoLogo} alt="Tricao Logo" className={styles.Logo} />
          </a>
        </Col>
        <Col xs={10} sm={8} className={styles.ButtonsContainer}>
          <Space className={styles.Space}>
            <Button
              className={styles.SchedulesAndPricesButton}
              kind="secondary"
              shape="round"
              href="/prices"
            >
              {texts.schedulesAndPrices}
            </Button>
            <Button
              shape="round"
              href="/date"
              onClick={() => captureEvent(events.toCalendarButtonClicked)}
            >
              {texts.buyTickets}
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default NavBar;
