import { Row, Col } from 'antd';
import React, { FC } from 'react';
import priceFormat from 'utils/formats/priceFormat';
import * as texts from 'assets/texts/giftCard';
import styles from './GiftCardPreview.module.scss';

interface GiftCardPreviewProps {
  formValues: {
    from: string;
    to: string;
    amount: number;
  };
}

const GiftCardPreview: FC<GiftCardPreviewProps> = ({
  formValues,
}: GiftCardPreviewProps) => {
  return (
    <div className={styles.GiftCardPreview}>
      <div className={styles.Gradient}>
        <Row
          className={styles.GiftCardPreviewTextRow}
          justify="space-between"
          align="middle"
        >
          <Col className={styles.GiftCardPreviewName} span={24}>
            <h2 className={styles.NameField}>
              {texts.giftCardFromNameField} {formValues.from}
            </h2>
          </Col>
        </Row>
        <Row className={styles.GiftCardPreviewPriceRow} justify="center" align="middle">
          <Col className={styles.GiftCardPreviewName} span={16}>
            <h2 className={styles.NameField}>
              {texts.giftCardToNameField} {formValues.to}
            </h2>
          </Col>
          <Col className={styles.GiftCardPreviewAmount} span={8}>
            <h2>{priceFormat.format(formValues.amount || 0)}</h2>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GiftCardPreview;
