export enum Breakpoints {
  XS = 0,
  SM = 1,
  MD = 2,
  LG = 3,
  XL = 4,
  XXL = 5,
}
const screenToInt = (screen: string): number => {
  const translateDict: { [id: string]: number } = {
    xs: Breakpoints.XS,
    sm: Breakpoints.SM,
    md: Breakpoints.MD,
    lg: Breakpoints.LG,
    xl: Breakpoints.XL,
    xxl: Breakpoints.XXL,
  };
  return translateDict[screen];
};
export default screenToInt;
