/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Input, message, Checkbox } from 'antd';
import Button from 'components/generalUI/Button';
import useGetPromotionCodeQuery from 'apollo/resolvers/promotionCodes/get';
import LoadingModal from 'components/generalUI/LoadingModal';
import { PromotionCode } from 'apollo/types';
import * as texts from 'assets/texts/purchaseSummary';
import { getOrCreatePurchaseData } from 'storage/utils/getOrCreatePurchaseData';
import styles from './PromotionCodeInput.module.scss';

interface PromotionCodeProps {
  setPromotionCode: React.Dispatch<React.SetStateAction<PromotionCode | null>>;
  date: string;
}

const PromotionCodeInput: FC<PromotionCodeProps> = ({
  setPromotionCode,
  date,
}: PromotionCodeProps) => {
  const [code, setCode] = useState('');
  const [getPromotionCode, { data, loading }] = useGetPromotionCodeQuery();
  const [isFormVisible, setIsFormVisible] = useState(false);

  const isDataFromGetPromotionCodeQueryValid = () => {
    if (data) {
      const dateAsDate = new Date(date);
      const isForWeekends = dateAsDate.getDay() === 6 || dateAsDate.getDay() === 0;
      if (data.getPromotionCode) {
        if (!data.getPromotionCode.forWeekends && isForWeekends) {
          message.error(texts.promotionCodeErrorWeekend, 1);
        } else {
          setPromotionCode(data.getPromotionCode);
          const purchase = getOrCreatePurchaseData(date);
          purchase.code = code;
          sessionStorage.setItem('purchase', JSON.stringify(purchase));
          message.success(texts.correctCode);
        }
      } else {
        message.error(texts.incorrectCode);
      }
    }
  };

  const handleVisibilityChange = () => {
    setIsFormVisible(!isFormVisible);
  };

  useEffect(() => {
    isDataFromGetPromotionCodeQueryValid();
  }, [data]);

  return (
    <>
      <Row justify="center">
        <Col xs={20} lg={15} className={styles.Title}>
          <Checkbox onChange={handleVisibilityChange}>{texts.havePromotionCode}</Checkbox>
        </Col>
      </Row>
      <Row justify="center" hidden={!isFormVisible}>
        <LoadingModal visible={loading} />
        <Col className={styles.PromotionCode} xs={20} lg={15}>
          <Input
            className={styles.Input}
            placeholder={texts.discountCode}
            onChange={(event) => setCode(event.target.value)}
          />
        </Col>
        <Col className={styles.PromotionCode} xs={20} lg={15}>
          <Button
            type="primary"
            className={styles.SubmitButton}
            kind="secondary"
            disabled={!code}
            onClick={() => {
              getPromotionCode({ variables: { code } });
            }}
          >
            {texts.submitCode}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PromotionCodeInput;
