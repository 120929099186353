import { Col, Row } from 'antd';
import { Body } from 'components/Program/Body';
import Header from 'components/Program/Header';
import { OtherPrograms } from 'components/Program/OtherPrograms';
import SubHeader from 'components/Program/SubHeader';
import { useProgramViewModel } from 'components/Program/program.viewModel';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Program.module.scss';

const Program: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useProgramViewModel();
  const containerStyle = isMobile ? styles.MobileContainer : styles.Container;

  return (
    <Row className={containerStyle}>
      <Col span={24}>
        {id && (
          <>
            <Header programId={id} />
            <SubHeader programId={id} />
            <Body programId={id} />
            <OtherPrograms programId={id} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default Program;
