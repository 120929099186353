import {} from 'apollo/types';
import { PackOrEntrancePurchase } from 'storage/types';

const calculateSubTotal = (item: PackOrEntrancePurchase): number => {
  return (
    item.adultTicketCount * item.adultValue + item.otherTicketCount * item.otherValue
  );
};

export default calculateSubTotal;
