import { PurchaseData } from '../types';

export const createNewPurchaseData = (date: string): PurchaseData => {
  return {
    code: '',
    totalPrice: 0,
    packs: [],
    entrances: [],
    grill: null,
    clientId: '',
    paymentMethod: '',
    date,
  };
};

const refreshOrKeepPurchaseData = (
  storagePurchase: string,
  date: string
): PurchaseData => {
  const storagePurchaseData = JSON.parse(storagePurchase) as PurchaseData;
  return storagePurchaseData.date === date
    ? storagePurchaseData
    : createNewPurchaseData(date);
};

export const getOrCreatePurchaseData = (date: string): PurchaseData => {
  const storagePurchase = sessionStorage.getItem('purchase');
  return storagePurchase
    ? refreshOrKeepPurchaseData(storagePurchase, date)
    : createNewPurchaseData(date);
};
