import moment from 'moment';

const updateDateLocaleES = (language: string): void => {
  moment.updateLocale(language, {
    weekdaysMin: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  });

  moment.updateLocale(language, {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  });
};

export default updateDateLocaleES;
