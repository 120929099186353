/* eslint-disable import/prefer-default-export */

import { FormInstance, message } from 'antd';
import useSendEmailProgramMutation from 'apollo/resolvers/programs/sendMail';
import { Program } from 'apollo/types';
import { useEffect, useState } from 'react';
import { Breakpoints } from 'utils/styles/breakpoints';
import useMaxBreakpoint from 'utils/styles/useMaxBreakpoint';
import { useHistory } from 'react-router-dom';
import * as texts from '../../assets/texts/program';

export interface SendEmailFormArgs {
  programId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  reservationDate: string;
  message: string;
}

export const useProgramViewModel = () => {
  const history = useHistory();

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [sendProgramMailMutation] = useSendEmailProgramMutation();

  const sendProgramMail = async (
    values: SendEmailFormArgs,
    setIsLoading: (loading: boolean) => void,
    form: FormInstance
  ) => {
    setIsLoading(true);
    const response = await sendProgramMailMutation({
      variables: {
        input: {
          ...values,
        },
      },
    });
    if (response.data?.sendMailProgram.success) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success(texts.sendEmailSuccess);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(texts.sendEmailError);
    }
    form.resetFields();
    setIsLoading(false);
  };

  const useIsScreenMobile = () => {
    const currentBreakpoint = useMaxBreakpoint();
    if (currentBreakpoint <= Breakpoints.XS) {
      return true;
    }
    return false;
  };

  const isScreenMobile = useIsScreenMobile();

  const setProgramFromQuery = (
    data:
      | {
          getProgram: Program;
        }
      | undefined,
    setProgram: (program: Program) => void
  ) => {
    if (data) {
      setProgram(data.getProgram);
    }
  };

  const handleLeftIconClick = () => {
    history.push('/programs');
  };

  useEffect(() => {
    setIsMobile(isScreenMobile);
  }, [isScreenMobile]);

  return {
    setProgramFromQuery,
    handleLeftIconClick,
    isMobile,
    sendProgramMail,
  };
};
