import { gql, useQuery } from '@apollo/client';
import { NewsPost } from 'apollo/generated/types';

const GET_NEWS_POSTS = gql`
  query getNewsPost($input: GetNewsPostInput!) {
    getNewsPost(input: $input) {
      id
      title
      contents {
        id
        content
        order
      }
      readTime
      coverImage {
        url
        id
        caption
      }
      mainImage {
        url
        id
        caption
      }
      closingImages {
        url
        id
        caption
      }
      publicationDate
      viewCount
      status
      featured
      author
      newsPostRecommendations {
        id
        title
        coverImage {
          url
          id
        }
      }
    }
  }
`;
const useGetNewsPostQuery = (id: string) => {
  return useQuery<{ getNewsPost: NewsPost }>(GET_NEWS_POSTS, {
    variables: { input: { id } },
  });
};
export default useGetNewsPostQuery;
