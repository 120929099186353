export const home = 'Inicio';
export const aboutUs = 'Somos';
export const spaces = 'Espacios';
export const activities = 'Actividades';
export const corporateEvents = 'Eventos corporativos';
export const programs = 'Aula Tricao';
export const newsPosts = 'Noticias';
export const contact = 'Contacto';
export const buyTickets = 'Comprar tickets';
export const faq = 'Preguntas frecuentes';
export const schedulesAndPrices = 'Horarios y precios';
export const giftCards = 'Gift Card';
