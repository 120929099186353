import { OpenDay } from 'apollo/types';
import moment from 'moment-timezone';

export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

const isDayInArray = (calendarDate: moment.Moment, openDays: OpenDay[]): boolean => {
  const startOfDayValue = calendarDate.startOf('day');
  return (
    openDays
      ?.map((openDay) => {
        const momentOpenDay = moment.tz(openDay.day, 'America/Santiago');
        if (openDay.day === '2024-09-08T03:00:00.000Z') {
          momentOpenDay.add(1, 'day');
        }
        const startOfOpenDay = moment
          .tz(momentOpenDay, 'America/Santiago')
          .startOf('day');

        const endOfOpenDay = moment.tz(momentOpenDay, 'America/Santiago').endOf('day');

        return startOfDayValue.isBetween(startOfOpenDay, endOfOpenDay, undefined, '[]');
      })
      .filter((day) => day === true).length === 0
  );
};

export default isDayInArray;
