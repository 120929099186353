import { gql, useQuery } from '@apollo/client';
import { ConfigurationParameters } from 'apollo/generated/types';

const GET_CONFIGURATION_PARAMETERS = gql`
  query getConfigurationParameters {
    getConfigurationParameters {
      cancellationPolicy {
        name
        text
      }
      oversold {
        name
        value
      }
      parkCapacity {
        name
        value
      }
      risk {
        name
        text
      }
      terms {
        name
        text
      }
    }
  }
`;

const useGetConfigurationParametersQuery = () => {
  return useQuery<{ getConfigurationParameters: ConfigurationParameters }>(
    GET_CONFIGURATION_PARAMETERS,
    {}
  );
};
export default useGetConfigurationParametersQuery;
