import { Col, Row } from 'antd';
import React from 'react';
import * as texts from 'assets/texts/corporateEvents';
import Button from 'components/generalUI/Button';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <Row className={styles.Container}>
      <Col className={styles.MainTextContainer} xs={24} sm={8}>
        <h3 className={styles.UpperText}>{texts.companies}</h3>
        <h1 className={styles.Title}>{texts.corporateEvents}</h1>
        <p className={styles.Description}>{texts.description}</p>
        <Button className={styles.QuoteButton}>{texts.quote}</Button>
      </Col>
      <Col className={styles.MainImageContainer} xs={24} sm={16}>
        <img
          className={styles.MainImage}
          src="https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_5.jpg"
          alt="Corporate events"
        />
      </Col>
    </Row>
  );
};

export default Header;
