import React, { FC } from 'react';
import { Row, Col } from 'antd';
import GeoPoint from 'assets/images/geoIcon.png';
import Drop from 'components/sharedComponents/Drop';
import styles from './Header.module.scss';

interface HeaderProps {
  title: string;
  letter: string;
  image: string;
  description: string;
}

const Header: FC<HeaderProps> = (props: HeaderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, letter, image, description } = props;

  return (
    <>
      <Row align="middle" justify="space-around">
        <Col span={24}>
          <img className={styles.GrillImage} alt="grill" src={image} />
        </Col>
        <Col className={styles.TitleBlock} xs={24} sm={24} lg={8}>
          {/* <div className={styles.LetterBlock}>
            <h1 className={styles.LetterSquare}>{letter}</h1>
          </div> */}
          <div className={styles.Block}>
            <img alt="activity Icon" src={GeoPoint} />
            <h1 className={styles.Title}>{title}</h1>
          </div>
        </Col>
      </Row>
      <Drop mainMessage={description} />
    </>
  );
};

export default Header;
