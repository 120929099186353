import { Col, Row } from 'antd';
import { Place } from 'apollo/types';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import ListGrillImages from './ListGrillImages';
import styles from './ListGrillDetail.module.scss';

interface Props {
  grill?: Place;
}

const ListGrillDetail: FC<Props> = ({ grill }: Props) => {
  return grill ? (
    <Row className={styles.Container}>
      <Col lg={8} xs={24}>
        <Row>
          <Col span={24}>
            <h1 className={styles.Title}>{grill.name}</h1>
          </Col>
        </Row>
        <Row justify="center">
          <Col lg={18} xs={24}>
            <div className={styles.Description}>{parse(grill.mainDescription)}</div>
          </Col>
        </Row>
      </Col>
      <Col lg={16} xs={24}>
        <ListGrillImages grill={grill} />
      </Col>
    </Row>
  ) : null;
};

ListGrillDetail.defaultProps = {
  grill: undefined,
};

export default ListGrillDetail;
