import React, { FC } from 'react';
import { Row, Col, Carousel } from 'antd';
import {
  conservationTitle,
  conservationCarousel,
  conservationSubTitle,
} from 'assets/texts/home';
import ScrollableItem from 'components/sharedComponents/ScrollableItem';
import styles from './Conservation.module.scss';

const Conservation: FC = () => {
  const { innerWidth: width } = window;
  return (
    <div className={styles.Container}>
      <Row justify="center">
        <Col lg={12} md={14} xs={20} className={styles.TitleContainer}>
          <h1 className={styles.Title}>{conservationTitle}</h1>
          <p className={styles.SubTitle}>{conservationSubTitle}</p>
        </Col>
      </Row>
      <div className={styles.ScrollableList}>
        <Carousel
          dots={{ className: styles.Dots }}
          slidesToShow={width > 768 ? 3 : 1}
          draggable
          swipeToSlide
          touchThreshold={50}
          focusOnSelect
          autoplay
        >
          {conservationCarousel.map(({ imageUrl, title, subTitle }) => {
            return (
              <div key={`div_${title}`}>
                <ScrollableItem
                  scrollableImageClassName={styles.ScrollableImage}
                  key={title}
                  imageUrl={imageUrl}
                  title={title}
                  subTitle={subTitle}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Conservation;
