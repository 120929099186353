import React, { FC } from 'react';
import { Row, Col } from 'antd';
import {
  directorWordsTitle,
  directorWordsText,
  directorWordsName,
  directorWordsCharge,
} from 'assets/texts/weAre';
import messageIcon from 'assets/images/message-director.svg';
import directorPhoto from 'assets/images/director-photo.png';
import parse from 'html-react-parser';
import styles from './DirectorWords.module.scss';

const DirectorWords: FC = () => {
  return (
    <div className={styles.Container}>
      <Row>
        <Col span={24} className={styles.Background1}>
          <div className={styles.TitleContainer}>
            <img src={messageIcon} alt="messageIcon" className={styles.MessageIcon} />
            <h2 className={styles.Title}>{directorWordsTitle}</h2>
          </div>
        </Col>
      </Row>
      <Row align="middle">
        <Col xs={24} sm={{ span: 12, order: 2 }} className={styles.Background2}>
          <p className={styles.Text}>{parse(directorWordsText)}</p>
        </Col>
        <Col xs={24} sm={{ span: 12, order: 1 }} className={styles.DirectorContainer}>
          <div className={styles.DirectorInfoContainer}>
            <p className={styles.Name}>{directorWordsName}</p>
            <p className={styles.Charge}>{directorWordsCharge}</p>
          </div>
          <img src={directorPhoto} alt="directorPhoto" className={styles.DirectorPhoto} />
        </Col>
      </Row>
    </div>
  );
};

export default DirectorWords;
