export const title = '¡Ha ocurrido un error!';
export const subTitle =
  'Se produjo un error que impide seguir en esta vista, intenta más tarde';
export const contactMail = 'contacto@tricao.cl';
export const errorLabel = 'Error:';
export const messageLabel = 'Mensaje:';
export const contactTextStart =
  'Lamentamos profundamente los inconvenientes. Nuestro equipo técnico revisará este problema de inmediato.';
export const inLabel = 'En ';
export const companyName = 'Tricao';
export const companyText =
  ' estamos muy preocupados de tu experiencia. Si el problema persiste o tienes cualquier duda, contáctanos a ';
export const contactTextEnd =
  '. Puedes indicar el código de error presentado anteriormente para que nuestro equipo pueda darte soluciones oportunas.';
export const redirectText = 'Volver al inicio';
