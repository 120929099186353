import { gql, useMutation } from '@apollo/client';
import {
  UpsertClientAndCreateTransactionInput,
  WebPayCreatePurchaseResponse,
} from 'apollo/types';

const UPSERT_WEBPAY_PURCHASE = gql`
  mutation upsertWebpayTransaction(
    $clientArguments: UpsertClientArgs!
    $purchaseArguments: CreatePurchaseInputArgs!
    $billingSystemArguments: BillingSystemInputArgs!
  ) {
    createPurchase(
      input: {
        clientArguments: $clientArguments
        purchaseArguments: $purchaseArguments
        billingSystemArguments: $billingSystemArguments
      }
    ) {
      url
      token
    }
  }
`;
const upsertWebpayTransactionMutation = () => {
  return useMutation<WebPayCreatePurchaseResponse, UpsertClientAndCreateTransactionInput>(
    UPSERT_WEBPAY_PURCHASE
  );
};

export default upsertWebpayTransactionMutation;
