export const joinEmailChainMessage =
  'Únete a la comunidad y entérate de las últimas novedades del parque';

export const address =
  'Avenida Las Brisas, 4,25 km <br> Santo Domingo, Región de Valparaíso.';
export const seeGoogleMapText = 'Ver en Google Maps';
export const helpFormTitle = '¿Cómo podemos ayudarte?';
export const helpFormNameField = 'Nombre *';
export const helpFormEmailField = 'Correo electrónico *';
export const helpFormMessageField = 'Mensaje *';
export const themeFormMessageField = 'Motivo *';
export const helpFormButton = 'Enviar';

export const requiredEmail = 'Correo electrónico requerido';
export const invalidEmail = 'Ingrese un email válido';
export const requiredName = 'Nombre requerido';
export const requiredMessage = 'Mensaje requerido';

export const summerScheduleTitle = 'Horario Verano';
export const summerMonths = 'Enero y Febrero: ';

export const winterScheduleTitle = 'Horario Invierno';
export const winterMonths = 'Marzo a Diciembre: ';

export const tricaoMission =
  'En Parque Tricao estamos abocados al rescate y preservación del bosque nativo  y de la vegetación autóctona propia de las quebradas de la zona central de Chile. A través de los múltiples espacios y experiencias que ofrecemos, buscamos fomentar una educación integral, siendo la naturaleza el eje central para el desarrollo de actividades culturales y recreativas. ';

export const frequentQuestionsLink = 'Preguntas frecuentes';

export const changeTicketsLink = 'Cambios de fechas u horarios';
export const tos = 'Términos y condiciones';
export const tosLink =
  'https://tricao-production.s3.us-west-2.amazonaws.com/assets/policies/TOS_Tricao_2023.pdf';
export const privacyPolicy = 'Política de privacidad';
export const privacyPolicyLink = 'https://www.iubenda.com/privacy-policy/12034082/legal';

export const messageSent = 'Mensaje enviado';
export const messageSentError = 'Error al enviar mensaje';
