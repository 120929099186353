import { gql, useQuery } from '@apollo/client';
import { Activity, QueryListActivitiesArgs } from 'apollo/generated/types';

const LIST_ACTIVITIES = gql`
  query listActivities($activityTypeId: ID, $date: String!) {
    listActivities(date: $date, activityTypeId: $activityTypeId) {
      activityImages {
        id
        image
      }
      activityTimes {
        id
        startHour
        endHour
        remaining
        dailyAvailability
      }
      description
      enDescription
      enName
      id
      maxStock
      name
      paxPerTicket
      activityTypeId
      status
      value
      hasSingleTimetable
    }
  }
`;
const useListActivitiesQuery = (
  date: string,
  activityTypeId?: string,
  skip?: boolean
) => {
  return useQuery<{ listActivities: Activity[] }, QueryListActivitiesArgs>(
    LIST_ACTIVITIES,
    {
      variables: { date, activityTypeId },
      skip,
    }
  );
};
export default useListActivitiesQuery;
