import { Pack, EntranceTicketType } from 'apollo/types';
import { PackOrEntrancePurchase, PurchaseData, ActivityPurchase } from 'storage/types';
import { History } from 'history';
import { message } from 'antd';

export const checkTicketCount = (
  adultTicketCount: number,
  otherTicketCount: number
): boolean => {
  if (!adultTicketCount && !otherTicketCount) return false;
  return true;
};

export const checkSelectedActivities = (
  selectedItem: Pack | EntranceTicketType | undefined,
  purchaseData: PackOrEntrancePurchase
): boolean => {
  if (selectedItem && 'activities' in selectedItem) {
    const [unSelectedActivityTime]: boolean[] = purchaseData.activities.map(
      (activity) => activity?.activityTimeId === ''
    );
    let activityTimeIdCounter = 0;
    purchaseData.activities.forEach((activity) => {
      if (activity.activityTimeId !== '') {
        activityTimeIdCounter += 1;
      }
    });
    if (activityTimeIdCounter !== selectedItem.activities.length) {
      return false;
    }
    if (unSelectedActivityTime) return false;
  }
  return true;
};

export const redirectAndSave = (
  purchase: PurchaseData,
  history: History,
  date: string
): void => {
  sessionStorage.setItem('purchase', JSON.stringify(purchase));
  history.push(`/summary?date=${date}`);
};

export const saveData = (
  purchase: PurchaseData,
  purchaseData: PackOrEntrancePurchase,
  grillData: ActivityPurchase | null,
  grillTotal: number,
  itemInPurchase: number,
  adultTicketCount: number,
  otherTicketCount: number,
  selectedItem: Pack | EntranceTicketType | undefined,
  item: 'packs' | 'entrances',
  subTotal: number,
  lastSubTotal: number,
  history: History,
  date: string,
  warningNoActivityMessage: string
): void => {
  const purchaseCopy: PurchaseData = purchase;
  if (purchase.grill) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    purchaseCopy.totalPrice -= purchase.grill.value || 0;
    purchaseCopy.grill = null;
  }
  if (grillData) {
    purchaseCopy.totalPrice += grillTotal;
    purchaseCopy.grill = grillData;
  }
  if (
    checkTicketCount(adultTicketCount, otherTicketCount) &&
    checkSelectedActivities(selectedItem, purchaseData)
  ) {
    if (itemInPurchase !== -1 && selectedItem) {
      const updatePrice =
        purchaseCopy[item][itemInPurchase].adultTicketCount * selectedItem.adultValue +
        purchaseCopy[item][itemInPurchase].otherTicketCount * selectedItem.otherValue;
      purchaseCopy.totalPrice -= updatePrice;
      purchaseCopy[item][itemInPurchase] = purchaseData;
    } else {
      purchaseCopy[item] = [...purchaseCopy[item], purchaseData];
    }
    purchaseCopy.totalPrice += subTotal;
    redirectAndSave(purchaseCopy, history, date);
  } else if (
    checkTicketCount(adultTicketCount, otherTicketCount) &&
    !checkSelectedActivities(selectedItem, purchaseData)
  ) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.warning(warningNoActivityMessage);
  } else if (itemInPurchase !== -1) {
    purchaseCopy.totalPrice -= lastSubTotal;
    purchaseCopy[item].splice(itemInPurchase, 1);
    redirectAndSave(purchaseCopy, history, date);
  } else {
    redirectAndSave(purchaseCopy, history, date);
  }
};
