import {
  PromotionCodeEntrance,
  PromotionCodePack,
  PromotionCodeActivity,
} from 'apollo/types';
import { PackOrEntrancePurchase, ActivityPurchase } from 'storage/types';

const totalWithDiscount = (
  adultValue: number,
  otherValue: number,
  adultTicketCount: number,
  otherTicketCount: number,
  promotionCode: PromotionCodePack | PromotionCodeEntrance
): number => {
  let total = 0;
  let countedTickets = 0;
  while (countedTickets < adultTicketCount + otherTicketCount) {
    let price = countedTickets < adultTicketCount ? adultValue : otherValue;
    price -=
      countedTickets < promotionCode.maxQuantity
        ? price * (Number(promotionCode.percentageDiscount) / 100)
        : 0;
    total += price;
    countedTickets += 1;
  }
  return total;
};

export const priceWithDiscount = (
  items: PackOrEntrancePurchase[],
  promotionCodeEntrances?: PromotionCodeEntrance[],
  promotionCodePacks?: PromotionCodePack[]
): number => {
  const totalPrice = items
    .map((item: PackOrEntrancePurchase) => {
      const itemPromotionCode = promotionCodePacks
        ? promotionCodePacks.find(({ packsId }) => packsId === item.id)
        : promotionCodeEntrances?.find(
            ({ entranceTicketTypeId }) => entranceTicketTypeId === item.id
          );
      return itemPromotionCode
        ? totalWithDiscount(
            item.adultValue,
            item.otherValue,
            item.adultTicketCount,
            item.otherTicketCount,
            itemPromotionCode
          )
        : item.adultValue * item.adultTicketCount +
            item.otherValue * item.otherTicketCount;
    })
    .reduce((accumulator, current) => accumulator + current, 0);
  return totalPrice;
};

const totalActivityWithDiscount = (
  percentageDiscount: number,
  maxQuantity: number,
  ticketCount: number,
  totalActivityValue: number,
  value: number
): number => {
  const percentage = (percentageDiscount / 100) * value;
  return maxQuantity < ticketCount
    ? totalActivityValue - maxQuantity * percentage
    : totalActivityValue - ticketCount * percentage;
};

export const grillTotalPrice = (
  grill: ActivityPurchase | null,
  promotionCodes?: PromotionCodeActivity[]
): number => {
  if (grill) {
    const activityPromotionCode = promotionCodes?.find(
      ({ activityId }) => activityId === grill.id
    );
    const totalActivityValue = grill.value ? grill.value * grill.ticketCount : 0;
    return !activityPromotionCode
      ? totalActivityValue
      : totalActivityWithDiscount(
          activityPromotionCode.percentageDiscount,
          activityPromotionCode.maxQuantity,
          grill.ticketCount,
          totalActivityValue,
          grill.value ? grill.value : 0
        );
  }
  return 0;
};
