import { Card, Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import * as texts from 'assets/texts/programs';
import { StarFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styles from './InformationCard.module.scss';
import Button from '../Button';

export interface InformationCardProps {
  title: string;
  subtitle?: string;
  id: string;
  imageUrl?: string;
  isProgram: boolean;
  color?: 'green' | 'orange';
  featured?: boolean;
  fixedHeight?: boolean;
}

export const InformationCard: FC<InformationCardProps> = (
  props: InformationCardProps
) => {
  const history = useHistory();
  const { imageUrl, title, subtitle, id, isProgram, color, featured, fixedHeight } =
    props;
  const { Meta } = Card;
  const { Paragraph } = Typography;

  const cardOnClick = () => {
    if (isProgram) {
      history.push(`/programs/${id}`);
      return;
    }
    history.push(`/newsPosts/${id}`);
  };

  const renderCardMetaDescription = () => {
    return (
      <Row>
        <Col span={24}>
          {subtitle && (
            <Row>
              <Col span={24}>
                <Paragraph
                  className={styles.Subtitle}
                  ellipsis={{
                    rows: 4,
                  }}
                >
                  {subtitle}
                </Paragraph>
              </Col>
            </Row>
          )}
          <Row className={styles.ButtonContainer}>
            <Col span={24} />
          </Row>
        </Col>
      </Row>
    );
  };

  const renderCardCoverImage = () => {
    let styleAccordingToColor;
    switch (color) {
      case 'green':
        styleAccordingToColor = styles.GreenCardCoverImageContainer;
        break;
      default:
        break;
    }
    return (
      <div className={styleAccordingToColor}>
        {featured && <StarFilled className={styles.Featured} />}
        <img src={imageUrl} alt="" className={styles.Image} />
      </div>
    );
  };

  return (
    <Card
      className={fixedHeight ? styles.FixedContainer : styles.Container}
      cover={renderCardCoverImage()}
      actions={[
        <Button key="seeMore" kind="secondary" onClick={cardOnClick}>
          {texts.seeMore}
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Meta title={title} description={renderCardMetaDescription()} />
        </Col>
      </Row>
    </Card>
  );
};

InformationCard.defaultProps = {
  imageUrl: '',
  subtitle: undefined,
  color: undefined,
  featured: false,
  fixedHeight: false,
};
