import React, { FC, useState, useEffect } from 'react';
import { Card, Row, Col, message } from 'antd';
import Button from 'components/generalUI/Button';
import { Activity, ActivityTime } from 'apollo/types';
import * as texts from 'assets/texts/selectTickets';
import { PackOrEntrancePurchase } from 'storage/types';
import styles from './SelectActivityTime.module.scss';

interface SelectActivityTimeProps {
  activity: Activity;
  ticketCount: number;
  purchaseData: PackOrEntrancePurchase;
  setPurchaseData: React.Dispatch<React.SetStateAction<PackOrEntrancePurchase>>;
  index: number;
}

const SelectActivityTime: FC<SelectActivityTimeProps> = (
  props: SelectActivityTimeProps
) => {
  const { activity, ticketCount, purchaseData, setPurchaseData, index } = props;
  const { Meta } = Card;

  const [activityTimeId, setActivityTimeId] = useState<string>(
    purchaseData.activities.length ? purchaseData.activities[index].activityTimeId : ''
  );
  const [validActivityTimes, setValidActivityTimes] = useState<ActivityTime[]>([]);

  const selectActivityTime = (id: string) => {
    setActivityTimeId(id);
  };

  const selectDefaultActivityTime = () => {
    if (activity.hasSingleTimetable) {
      setActivityTimeId(activity.activityTimes[0].id);
    }
  };

  const setValidActivityTimesArray = () => {
    setValidActivityTimes(activity.activityTimes);
  };

  const isActivityTimeButtonDisabled = (activityTime: ActivityTime) => {
    const { remaining } = activityTime;
    if (
      remaining === undefined ||
      remaining === null ||
      activityTime.openActivityTimetablesPerDay?.length === 0
    )
      return true;
    const hasSelectedMoreThanRemaining = ticketCount > remaining;
    const hasNoRemaining = remaining <= 0;
    return hasSelectedMoreThanRemaining || hasNoRemaining || ticketCount === 0;
  };

  const validateCurrentActivityTimeRemaining = () => {
    const currentActivityTime = activity.activityTimes.find(
      (activityTime) => activityTime.id === activityTimeId
    );
    if (
      currentActivityTime &&
      currentActivityTime.remaining &&
      ticketCount > currentActivityTime.remaining
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(texts.noActivityTimesRemaining);
      setActivityTimeId('');
    }
  };

  const renderActivityTimeButtonDisabledText = (activityTime: ActivityTime) => {
    const { remaining } = activityTime;
    if (
      remaining === undefined ||
      remaining === null ||
      activityTime.openActivityTimetablesPerDay?.length === 0
    ) {
      return texts.closed;
    }
    const hasSelectedMoreThanRemaining = ticketCount > remaining;
    const hasNoRemaining = remaining <= 0;
    const canNotAddMoreTicketsToPurchase =
      (hasSelectedMoreThanRemaining || hasNoRemaining) && ticketCount > 0;

    if (canNotAddMoreTicketsToPurchase || hasNoRemaining) {
      return texts.noRemaining;
    }
    return '';
  };

  useEffect(() => {
    const newActivity = {
      id: activity.id,
      ticketCount,
      activityTimeId,
      name: activity.name,
    };
    const isInPurchase = purchaseData.activities.findIndex(
      (purchaseActivity) => purchaseActivity?.id === activity.id
    );
    if (isInPurchase !== -1) purchaseData.activities[isInPurchase] = newActivity;
    else purchaseData.activities.push(newActivity);
    setPurchaseData(purchaseData);
  }, [activityTimeId, ticketCount]);

  useEffect(() => {
    setValidActivityTimesArray();
    selectDefaultActivityTime();
  }, []);

  useEffect(() => {
    validateCurrentActivityTimeRemaining();
  }, [ticketCount]);

  return (
    <>
      {validActivityTimes.length > 1 && !activity.hasSingleTimetable && (
        <Card className={styles.Card}>
          <Meta
            title={
              <h2 className={styles.ActivityTimesTitle}>
                {texts.selectActivityTime}
                {activity.name}
              </h2>
            }
            description={texts.activityTimeWarning as string}
          />
          <Row justify="space-around">
            {validActivityTimes.map(
              (activityTime) =>
                activityTime && (
                  <Col
                    lg={4}
                    sm={8}
                    xs={12}
                    className={styles.GridItem}
                    key={activityTime.id}
                  >
                    <Button
                      className={styles.LargeButton}
                      onClick={() => selectActivityTime(activityTime.id)}
                      disabled={isActivityTimeButtonDisabled(activityTime)}
                      kind={activityTimeId === activityTime.id ? 'primary' : 'secondary'}
                    >
                      <p>
                        {`${activityTime.startHour}-${activityTime.endHour}`}
                        <br />
                        <span className={styles.NoRemaining}>
                          {renderActivityTimeButtonDisabledText(activityTime)}
                        </span>
                      </p>
                    </Button>
                  </Col>
                )
            )}
          </Row>
        </Card>
      )}
    </>
  );
};

export default SelectActivityTime;
