import { Col, Row } from 'antd';
import React, { FC } from 'react';
import LoadingModal from 'components/generalUI/LoadingModal';
import Header from 'components/sharedComponents/Header';
import { CameraFilled } from '@ant-design/icons';
import parse from 'html-react-parser';
import { Place } from 'apollo/types';
import placeholderImage from 'assets/images/placeholder-image.png';
import * as texts from '../../assets/texts/showItem';
import styles from './ShowPlace.module.scss';

interface Props {
  place: Place;
  loading: boolean;
  letter: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShowPlace: FC<Props> = ({ place, loading, letter }: Props) => {
  const isPlaceNamePrural = place.name[place.name.length - 1] === 's';
  return (
    <>
      <LoadingModal visible={loading} />
      <Header
        title={place.name || ''}
        image={place.placeMainImage || placeholderImage}
        letter={letter}
        description={place.mainDescription}
      />
      <div className={styles.ImageContainer}>
        {place.placeImages?.length && (
          <>
            <Row justify="center">
              <Col xs={20} sm={15} lg={8}>
                <h1 className={styles.Title}>
                  <CameraFilled />
                  {isPlaceNamePrural
                    ? `${texts.imageCarouselTitlePrural} ${place.name}`
                    : `${texts.imageCarouselTitle} ${place.name}`}
                </h1>
              </Col>
            </Row>
            <div className={styles.ImagesContainer}>
              {place.placeImages.map((placeImage) => (
                <div className={styles.ActivityImage} key={placeImage.id}>
                  <img
                    alt={place.name || ''}
                    src={placeImage.image || placeholderImage}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {place.secondaryDescription && (
        <Row justify="center">
          <Col className={styles.Descent} xs={16} sm={15} lg={10}>
            {parse(place.secondaryDescription || '')}
          </Col>
        </Row>
      )}
    </>
  );
};

export default ShowPlace;
