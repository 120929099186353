import { gql, useLazyQuery } from '@apollo/client';
import {
  GiftCardWithRemainingAmount,
  QueryGetGiftCardArgs,
} from 'apollo/generated/types';

const GET_GIFT_CARD = gql`
  query getGiftCard($code: String!, $password: String!) {
    getGiftCard(code: $code, password: $password) {
      id
      to
      from
      totalAmount
      remainingAmount
    }
  }
`;

const useGetGiftCardQuery = () => {
  const queryResponse = useLazyQuery<
    { getGiftCard: GiftCardWithRemainingAmount },
    QueryGetGiftCardArgs
  >(GET_GIFT_CARD);
  return queryResponse;
};
export default useGetGiftCardQuery;
