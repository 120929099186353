import React, { FC } from 'react';
import { Button as BaseButton } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import styles from './Button.module.scss';

const typeToStyle: { [type: string]: string } = {
  primary: styles.Primary,
  secondary: styles.Secondary,
  ghost: styles.Ghost,
  selected: styles.Selected,
  selectedActivityTime: styles.SelectedActivityTime,
  secondaryActivityTime: styles.SecondaryActivityTime,
  primaryNavbar: styles.PrimaryNavbar,
  secondaryNavbar: styles.SecondaryNavbar,
  default: '',
};

type CustomButtonProps = ButtonProps & {
  kind?: string;
  onClick?: () => Promise<void> | void;
};

const Button: FC<CustomButtonProps> = (props: CustomButtonProps) => {
  const { children, kind, onClick, ...otherProps } = props;
  return (
    <div className={kind ? typeToStyle[kind] : 'primary'}>
      <BaseButton {...otherProps} onClick={onClick}>
        {children}
      </BaseButton>
    </div>
  );
};

Button.defaultProps = {
  kind: 'primary',
  onClick: undefined,
};

export default Button;
