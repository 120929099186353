import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import * as texts from 'assets/texts/grills';
import { Place } from 'apollo/types';
import styles from './ListGrills.module.scss';
import ListGrillDetail from './ListGrillDetail';

interface ListGrillsProps {
  grills: Place[];
}

const ListGrills: FC<ListGrillsProps> = (props: ListGrillsProps) => {
  const { grills } = props;
  const [selectedGrill, setSelectedGrill] = useState<Place>();

  const setFirstGrill = () => {
    if (grills.length > 0) {
      setSelectedGrill(grills[0]);
    }
  };

  useEffect(() => {
    setFirstGrill();
  }, [grills]);

  return (
    <div className={styles.Container}>
      <Row justify="center">
        <Col lg={15} sm={16} xs={18}>
          <h2 className={styles.ListGrillsTitle}>{texts.grillListTitle}</h2>
        </Col>
      </Row>
      <div className={styles.ScrollableList}>
        {grills?.map((grill, index) => (
          <Button
            type="primary"
            onClick={() => setSelectedGrill(grill)}
            className={
              index === Number(selectedGrill?.id)
                ? styles.SelectedButton
                : styles.UnselectedButton
            }
            key={grill.name}
          >
            <Row align="middle" justify="center">
              <p>{grill.name}</p>
            </Row>
          </Button>
        ))}
      </div>
      {selectedGrill && <ListGrillDetail grill={selectedGrill} />}
    </div>
  );
};

export default ListGrills;
