import React, { FC } from 'react';
import VideoSlogan from 'components/Home/VideoSlogan';
import Conservation from 'components/Home/Conservation';
import Drop from 'components/sharedComponents/Drop';
import InstaFeeds from 'components/Home/InstaFeed';
import ThingsToDo from 'components/sharedComponents/Activities';
import Places from 'components/sharedComponents/Places';
import useListThingsToDoQuery from 'apollo/resolvers/thingsToDo/list';
import BuyTickets from 'components/Home/BuyTickets';
import ParkRules from 'components/Home/ParkRules';

const Home: FC = () => {
  const { data } = useListThingsToDoQuery();

  return (
    <>
      <VideoSlogan />
      <BuyTickets />
      <ParkRules />
      <Drop />
      <Conservation />
      <Places />
      <div id="activities">
        {data !== undefined && <ThingsToDo thingsToDo={data.listThingsToDo} />}
      </div>
      {process.env.REACT_APP_FEED_INSTAGRAM_TOKEN && (
        <InstaFeeds token={process.env.REACT_APP_FEED_INSTAGRAM_TOKEN} limit={12} />
      )}
    </>
  );
};

export default Home;
