import { Row, Col, Form, Collapse } from 'antd';
import React from 'react';
import * as texts from 'assets/texts/giftCard';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import GiftCardForm from './GiftCardForm';
import GiftCardPreview from './GiftCardPreview';
import styles from './ConfigureYourGiftCard.module.scss';

const ConfireYourGiftCard: React.FC = () => {
  const [form] = Form.useForm<{
    from: string;
    to: string;
    amount: number;
  }>();

  const fromNameValue = Form.useWatch('from', form);
  const toNameValue = Form.useWatch('to', form);
  const amountValue = Form.useWatch('amount', form);

  return (
    <>
      <Row className={styles.ConfigureTitleRow}>
        <Col span={24}>
          <h2 className={styles.ConfigureTitle}>{texts.configureGiftCardTitle}</h2>
        </Col>
      </Row>
      <Row justify="start" align="top" className={styles.CardFormRow}>
        <Col flex="600px">
          <Row>
            <Col span={24}>
              <GiftCardPreview
                formValues={{ from: fromNameValue, to: toNameValue, amount: amountValue }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Collapse ghost>
                <CollapsePanel
                  className={styles.ShowMoreCollapse}
                  key="1"
                  header={texts.detailsAndConditions}
                >
                  <p className={styles.DetailsAndConditionsText}>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: texts.detailsAndConditionsText }}
                    />
                  </p>
                </CollapsePanel>
              </Collapse>
            </Col>
          </Row>
        </Col>
        <Col flex="auto">
          <GiftCardForm form={form} />
        </Col>
      </Row>
    </>
  );
};

export default ConfireYourGiftCard;
