import React, { FC } from 'react';
import { Card, Col, Row } from 'antd';
import { MappedPurchase } from 'apollo/types';
import * as texts from 'assets/texts/purchaseChange';
import isDateToDisplayIsBefore from 'utils/helpers/isDateToDisplayIsBefore';
import styles from './PurchaseCard.module.scss';

interface PurchaseCardProps {
  mappedPurchase: MappedPurchase;
  setPurchase?: React.Dispatch<React.SetStateAction<MappedPurchase | undefined>>;
  isSelected: boolean;
  showGrills?: boolean;
}

const PurchaseCard: FC<PurchaseCardProps> = (props: PurchaseCardProps) => {
  const { mappedPurchase, setPurchase, isSelected, showGrills } = props;
  const { date, purchaseItems, purchaseActivities, includePacks, id, purchaseGrills } =
    mappedPurchase;
  const displayDate = isDateToDisplayIsBefore(date, new Date(date));

  const renderTitle = () => {
    return (
      <div className={styles.Title}>
        <Row>
          <Col span={24}>
            <h4 className={styles.BlackTextColor}>{`${texts.orderNumber}${id}`}</h4>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h4 className={styles.BlackTextColor}>{displayDate}</h4>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Card
      hoverable
      className={isSelected ? styles.SelectedCard : styles.Card}
      onClick={() => (setPurchase ? setPurchase(mappedPurchase) : null)}
      title={renderTitle()}
    >
      {purchaseItems.map((purchaseItem) => (
        <div key={purchaseItem.name}>
          <h2 className={styles.ItemName}>{purchaseItem.name}</h2>
          {purchaseItem.adultTicketCount > 0 && (
            <p>{`${purchaseItem.adultTicketCount} ${texts.adults}`}</p>
          )}
          {purchaseItem.otherTicketCount > 0 && (
            <p>{`${purchaseItem.otherTicketCount} ${texts.others}`}</p>
          )}
        </div>
      ))}
      {showGrills &&
        purchaseGrills.map((purchaseGrill) => (
          <div key={purchaseGrill.name}>
            <h2 className={styles.ItemName}>{purchaseGrill.name}</h2>
            <p>
              {purchaseGrill.ticketCount} {texts.tickets}
            </p>
          </div>
        ))}
      {!includePacks &&
        purchaseActivities.map((purchaseActivity) => (
          <div key={purchaseActivity.name}>
            <h2 className={styles.ItemName}>{purchaseActivity.name}</h2>
            <p>
              {purchaseActivity.ticketCount} {texts.tickets}
            </p>
          </div>
        ))}
    </Card>
  );
};

PurchaseCard.defaultProps = {
  setPurchase: undefined,
  showGrills: true,
};

export default PurchaseCard;
