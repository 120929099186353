import React, { FC, useEffect, useState } from 'react';
import { NewsPost } from 'apollo/types';
import useGetNewsPostQuery from 'apollo/resolvers/newsPosts/get';
import LoadingModal from 'components/generalUI/LoadingModal';
import { Col, Row } from 'antd';
import { useNewsPostViewModel } from '../newsPost.viewModel';
import { RichTextComponent } from './RichTextComponent';
import { CloseImages } from './CloseImages';

export interface BodyProps {
  newsPostId: string;
}

export const Body: FC<BodyProps> = ({ newsPostId }: BodyProps) => {
  const { setNewsPostFromQuery, orderContent } = useNewsPostViewModel();

  const [newsPost, setNewsPost] = useState<NewsPost>();

  const { data, loading } = useGetNewsPostQuery(newsPostId);

  const renderContent = (newsPostToRender: NewsPost) => {
    const content = newsPostToRender.contents || [];
    const closingImages = newsPostToRender.closingImages || [];
    const orderedContent = orderContent(content);

    return (
      <>
        <Row justify="center">
          <Col xs={18} md={14} lg={12}>
            {orderedContent.map((contentToRender, index) => {
              const image =
                index === 0
                  ? {
                      url: newsPost?.mainImage?.url as string,
                      caption: newsPost?.mainImage?.caption as string,
                    }
                  : undefined;

              return (
                <RichTextComponent
                  content={contentToRender.content}
                  image={image}
                  key={contentToRender.id}
                />
              );
            })}
            <CloseImages images={closingImages} />
          </Col>
        </Row>
      </>
    );
  };

  useEffect(() => {
    setNewsPostFromQuery(data, setNewsPost);
  }, [data]);

  return (
    <>
      <LoadingModal visible={loading} />
      <Row>
        <Col span={24}>{newsPost && renderContent(newsPost)}</Col>
      </Row>
    </>
  );
};
