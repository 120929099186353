export const companies = 'EMPRESAS';

export const corporateEvents = 'Eventos corporativos';

export const description =
  '¡En Parque Tricao se viven experiencia únicas! Ven a disfrutar junto a tu equipo cada rincón del parque, un asado en los quinchos y la conexión con la naturaleza.';

export const quote = 'Cotizar';

export const teamBuilding = 'Team Building';

export const teamBuildingDescription =
  '¡Eventos a tu medida! Recorre senderos, aprende de aves y disfruta de la calma de la naturaleza. En el Día Tricao podrás disfrutar de un increíble almuerzo, disfrutar del emocionante circuito de canopy  o potenciar las habilidades de tu equipo con Desafío Tricao. ';

export const teamBuildingCarousel = [
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_2.jpg',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_3.jpg',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_4.jpg',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_5.jpg',
  },
  {
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_6.jpg',
  },
];

export const officeTrips = 'Paseos de oficina';

export const officeTripsDescription = '¿Necesitas más información? ¡Escríbenos!';

export const officeTripsImage =
  'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg';

export const quoteDetails = '¡Necesitamos algunos detalles para enviarte una cotización!';

export const companyName = 'Nombre de la empresa';

export const requiredCompanyName = 'Por favor, ingresa el nombre de la empresa';

export const companyRut = 'RUT de la empresa, ej: xx.xxx.xxx-x';

export const requiredCompanyRut = 'Por favor, ingresa el RUT de la empresa';

export const fullContactName = 'Nombre completo del contacto';

export const requiredFullContactName = 'Por favor, ingresa tu nombre completo';

export const email = 'Correo electrónico';

export const requiredEmail = 'Por favor, ingresa tu correo electrónico';

export const invalidEmail = 'Por favor, ingresa un correo electrónico válido';

export const phone = 'Teléfono';

export const requiredPhone = 'Por favor, ingresa tu número de teléfono';

export const invalidCellphone = 'Por favor, ingresa un número de teléfono válido';

export const tripDate = 'Fecha estimada del paseo';

export const requiredTripDate = 'Por favor, ingresa la fecha del paseo';

export const tripHour = 'Hora estimada del paseo';

export const requiredTripHour = 'Por favor, ingresa la hora del paseo';

export const amountOfPeople = 'Cantidad de personas';

export const requiredAmountOfPeople = 'Por favor, ingresa la cantidad de personas';

export const visitingPlaces = '¿Qué te gustaría visitar?';

export const grillReservation = '¿Quieres reservar un quincho?';

export const comment = 'Cuentanos más sobre tu paseo';

export const send = 'Enviar';

export const messageSent = 'Correo electrónico enviado correctamente';

export const messageSentError = 'Ocurrió un error al enviar el correo electrónico';
