import { Form as AntdForm, Col, DatePicker, Input, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import Button from 'components/generalUI/Button';
import {
  SendEmailFormArgs,
  useProgramViewModel,
} from 'components/Program/program.viewModel';
import { addDays } from 'utils/dates/isDayInArray';
import formatDateLocaleES from 'utils/dates/formatDateLocaleES';
import isWeekendDate from 'utils/dates/isWeekendDate';
import emailRegExp from 'utils/validations/emailRegExp';
import * as texts from '../../../../assets/texts/program';
import styles from './Form.module.scss';

export interface FormProps {
  programId: string;
  validForWeekends: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const Form: FC<FormProps> = ({ programId, validForWeekends }: FormProps) => {
  const [loading, setLoading] = useState(false);
  const { sendProgramMail } = useProgramViewModel();
  const { Item } = AntdForm;
  const [form] = AntdForm.useForm();
  const { TextArea } = Input;

  const isDisableDate = (date: moment.Moment) => {
    const dateDate = date.toDate();
    const currentDateMinusOne = addDays(new Date(), -1);
    if (dateDate < currentDateMinusOne) {
      return true;
    }
    if (validForWeekends) {
      return false;
    }
    if (isWeekendDate(dateDate)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    formatDateLocaleES();
  }, []);

  return (
    <div className={styles.Container}>
      <Row>
        <Col span={24}>
          <h1 className={styles.Title}>{texts.inscriptionForm}</h1>
        </Col>
      </Row>
      <AntdForm
        form={form}
        layout="vertical"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onFinish={(values: SendEmailFormArgs) =>
          sendProgramMail(
            {
              ...values,
              programId,
            },
            setLoading,
            form
          )
        }
      >
        <Row justify="space-between">
          <Col xs={24} md={11}>
            <Item name="firstName" label={texts.name} required>
              <Input required />
            </Item>
          </Col>
          <Col xs={24} md={11}>
            <Item name="lastName" label={texts.surname} required>
              <Input required />
            </Item>
          </Col>
        </Row>
        <Item
          name="email"
          label={texts.email}
          required
          rules={[
            { required: true, message: texts.emailError },
            {
              message: texts.emailError,
              pattern: emailRegExp(),
            },
          ]}
        >
          <Input required />
        </Item>
        <Row justify="space-between">
          <Col xs={24} md={11}>
            <Item name="reservationDate" label={texts.selectDate} required>
              <DatePicker
                className={styles.DatePickerContainer}
                format={(value) => {
                  if (value) {
                    return value.format('DD/MM/YYYY');
                  }
                  return '';
                }}
                disabledDate={(date) => {
                  return isDisableDate(date);
                }}
              />
            </Item>
          </Col>
          <Col xs={24} md={11}>
            <Item name="phoneNumber" label={texts.phone} required>
              <Input prefix="+56" required />
            </Item>
          </Col>
        </Row>
        <Item name="message" label={texts.comments}>
          <TextArea />
        </Item>
        <Row justify="center">
          <Col span={18}>
            <Item>
              <Button
                className={styles.SubmitButton}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {texts.send}
              </Button>
            </Item>
          </Col>
        </Row>
      </AntdForm>
    </div>
  );
};
