import React, { FC } from 'react';
import { Place } from 'apollo/generated/types';
import { Row, Col } from 'antd';
import useMaxBreakpoint from 'utils/styles/useMaxBreakpoint';
import { Breakpoints } from 'utils/styles/breakpoints';
import placeholderImage from 'assets/images/placeholder-image.png';
import styles from './PlacesCards.module.scss';
import PlaceCard from './PlaceCard';

interface Props {
  places: Place[] | undefined;
}

const PlacesCards: FC<Props> = ({ places }: Props) => {
  const maxBreakpoint = useMaxBreakpoint();

  return (
    <Row className={styles.Container} wrap={maxBreakpoint > Breakpoints.XS}>
      {places &&
        places.map((place) => (
          <Col key={place.id} xs={16} sm={24} md={12} xl={8}>
            <a key={place.id} href={`/place/${place.id}`}>
              <PlaceCard
                imageUrl={place.placeMainImage || placeholderImage}
                title={place.name || ''}
              />
            </a>
          </Col>
        ))}
    </Row>
  );
};

export default PlacesCards;
