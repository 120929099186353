/* eslint-disable import/prefer-default-export */
import { Button, Col, Empty, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import * as texts from 'assets/texts/newsPosts';
import { NewsPost } from 'apollo/types';
import LoadingModal from 'components/generalUI/LoadingModal';
import { InformationCard } from 'components/generalUI/InformationCard';
import useLazyListNewsPostsQuery from 'apollo/resolvers/newsPosts/list';
import styles from './NewsPosts.module.scss';
import { Pagination } from './types';
import { useNewsPostsViewModel } from './newsPosts.viewModel';

export const NewsPosts = () => {
  const pageSize = 6;
  const { onSeeMoreButtonClick } = useNewsPostsViewModel();
  const [newsPosts, setNewsPosts] = useState<NewsPost[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [count, setCount] = useState<number>(0);

  const [useLazyListNewsPost, { loading }] = useLazyListNewsPostsQuery();

  const getDataFromLazyQuery = async () => {
    if (!pagination) {
      return;
    }
    const { data } = await useLazyListNewsPost({
      variables: {
        input: pagination,
      },
    });
    if (!data) {
      return;
    }
    const { count: newsPostsCount } = data.listNewsPosts;
    setCount(newsPostsCount);
    if (newsPosts.length > 0) {
      setNewsPosts([...newsPosts, ...data.listNewsPosts.newsPosts]);
      return;
    }
    setNewsPosts(data.listNewsPosts.newsPosts);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getDataFromLazyQuery();
  }, [pagination]);

  useEffect(() => {
    setPagination({
      page: 1,
      pageSize,
    });
  }, []);

  return (
    <>
      <LoadingModal visible={loading} />
      {newsPosts && pagination && newsPosts.length > 0 ? (
        <>
          <Row justify="center" gutter={{ sm: 10, md: 15, xl: 20 }}>
            {newsPosts.map((newsPost) => {
              return (
                <Col
                  key={newsPost.id}
                  className={styles.InformationCardColumn}
                  xs={24}
                  sm={12}
                  xl={8}
                >
                  <InformationCard
                    title={newsPost.title}
                    id={newsPost.id}
                    imageUrl={newsPost.coverImage?.url}
                    featured={newsPost.featured}
                    isProgram={false}
                    color={newsPost.featured ? 'green' : undefined}
                  />
                </Col>
              );
            })}
          </Row>
          {newsPosts.length < count && (
            <Row justify="center">
              <Col>
                <Button
                  className={styles.SeeMoreButton}
                  type="ghost"
                  onClick={() => onSeeMoreButtonClick(pagination, setPagination)}
                >
                  {texts.seeMore}
                </Button>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Row justify="center">
          <Col>
            <Empty />
          </Col>
        </Row>
      )}
    </>
  );
};
