import { Col, Row } from 'antd';
import React, { useState } from 'react';
import Button from 'components/generalUI/Button';
import { CloseOutlined } from '@ant-design/icons';
import useGetNewsBannerQuery from 'apollo/resolvers/newsBanner/get';
import styles from './NewsBanner.module.scss';

const NewsBanner = () => {
  const [isOpen, setIsOpen] = useState(true);

  const { data: newsBannerData } = useGetNewsBannerQuery();

  const setNewsBannerText = () => {
    if (!newsBannerData) {
      return null;
    }

    const { body } = newsBannerData.getNewsBanner;

    return <h6 className={styles.NotificationContent}>{body}</h6>;
  };

  return (
    <>
      {isOpen && newsBannerData && (
        <Row className={styles.Container} align="middle">
          <Col className={styles.TextContainer} offset={2} span={20}>
            {setNewsBannerText()}
          </Col>
          <Col span={2}>
            <Row justify="end">
              <Button
                onClick={() => setIsOpen(false)}
                type="ghost"
                icon={<CloseOutlined />}
              />
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default NewsBanner;
