/* eslint-disable import/prefer-default-export */
import { Pagination } from './types';

export const useNewsPostsViewModel = () => {
  const onSeeMoreButtonClick = (
    pagination: Pagination,
    setPagination: (pagination: Pagination) => void
  ) => {
    if (pagination.page === 1) {
      setPagination({
        page: 3,
        pageSize: 3,
      });
      return;
    }
    setPagination({
      page: pagination.page + 1,
      pageSize: pagination.pageSize,
    });
  };

  return {
    onSeeMoreButtonClick,
  };
};
