import { LeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { NewsPost } from 'apollo/types';
import LoadingModal from 'components/generalUI/LoadingModal';
import useGetNewsPostQuery from 'apollo/resolvers/newsPosts/get';
import dayjs from 'dayjs';
import es from 'dayjs/locale/es';
import * as texts from '../../../assets/texts/newsPost';
import styles from './Header.module.scss';
import { useNewsPostViewModel } from '../newsPost.viewModel';

export interface HeaderProps {
  newsPostId: string;
}

const Header: FC<HeaderProps> = ({ newsPostId }: HeaderProps) => {
  const { handleLeftIconClick, isMobile, setNewsPostFromQuery } = useNewsPostViewModel();

  const [newsPost, setNewsPost] = useState<NewsPost>();
  const containerStyle = isMobile ? styles.ContainerMobile : styles.Container;
  const imageContainerStyle = isMobile
    ? styles.ImageContainerMobile
    : styles.ImageContainer;
  const imageStyle = isMobile ? styles.ImageMobile : styles.Image;
  const publicationDate = dayjs(newsPost?.publicationDate)
    .locale(es)
    .format('D MMMM YYYY');

  const { data, loading } = useGetNewsPostQuery(newsPostId);

  useEffect(() => {
    setNewsPostFromQuery(data, setNewsPost);
  }, [data]);
  return (
    <>
      <LoadingModal visible={loading} />
      {newsPost && (
        <Row className={containerStyle}>
          <Col span={24}>
            <div className={imageContainerStyle}>
              <div className={styles.ImageBlackContainer}>
                <img src={newsPost.coverImage?.url} alt="" className={imageStyle} />
              </div>
            </div>
            <Row>
              <Col span={24}>
                <Row justify="start">
                  <Col className={styles.LeftIconContainer} span={1} offset={1}>
                    <LeftOutlined
                      className={styles.LeftIcon}
                      onClick={handleLeftIconClick}
                    />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xs={18} lg={16} xxl={14}>
                    <Row>
                      <Col span={24}>
                        <h3 className={styles.ProgramText}>{texts.newsPost}</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <h1 className={styles.Title}>{newsPost.title}</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <p className={styles.ProgramText}>{publicationDate}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Header;
