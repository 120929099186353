import React, { FC } from 'react';
import styles from './Feed.module.scss';
import { FeedProps } from '../types';

const Feed: FC<FeedProps> = ({ feed, callBackFunction, className }) => {
  const {
    id,
    caption,
    media_type: mediaType,
    media_url: mediaUrl,
    permalink,
    thumbnail_url: thumbnailUrl,
  } = feed;
  let post;
  if (mediaType === 'VIDEO') {
    post = (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        className={styles.Video}
        src={mediaUrl}
        controls={false}
        playsInline={false}
        poster={thumbnailUrl}
        onClick={() => {
          callBackFunction(permalink);
        }}
      />
    );
  } else {
    post = (
      <img
        id={id}
        src={mediaUrl}
        alt={caption}
        role="presentation"
        className={styles.Image}
        // eslint-disable-next-line no-console
        onClick={() => {
          callBackFunction(permalink);
        }}
        onKeyPress={() => {}}
      />
    );
  }

  return <div className={className === 'selected' ? styles.Selected : ''}>{post}</div>;
};

export default Feed;
