import { clone } from 'lodash';

const getOffsetHours = (timeZone = 'America/Santiago', date = new Date()) => {
  const utcDateString = date.toLocaleString('en-US', { timeZone: 'UTC' });
  const utcDate = new Date(utcDateString);
  const tzDateString = date.toLocaleString('en-US', { timeZone });
  const tzDate = new Date(tzDateString);
  return (tzDate.getTime() - utcDate.getTime()) / (6e4 * 60);
};

const addDays = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

const formatDateStringToLocaleString = (dateString: string) => {
  const date = new Date(dateString);
  let toUseDate = clone(date);
  if (date.getUTCHours() < -getOffsetHours('America/Santiago', date)) {
    toUseDate = addDays(date, -1);
  }
  const chileOffset = -getOffsetHours('America/Santiago', date);
  const milliseconds = toUseDate.setUTCHours(chileOffset, 0, 0, 0);
  const chileanDate = new Date(milliseconds);
  return chileanDate.toISOString();
};

export default formatDateStringToLocaleString;
