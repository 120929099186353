import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from 'views/Home';
import SelectEntrances from 'views/SelectEntrances';
import SelectTickets from 'views/SelectTickets';
import PurchaseSummary from 'views/PurchaseSummary';
import PurchaseSuccess from 'views/PurchaseSuccess';
import Grills from 'views/Grills';
import PurchaseChange from 'views/PurchaseChange';
import Place from 'views/Place';
import Faq from 'views/Faq';
import WeAre from 'views/WeAre';
import SelectDate from 'views/SelectDate';
import ConfirmChange from 'views/ConfirmChange';
import PurchaseError from 'views/PurchaseError';
import ScrollToTop from 'views/ScrollToTop';
import Programs from 'views/Programs';
import NewsPosts from 'views/NewsPosts';
import NewsPost from 'views/NewsPost';
import Program from 'views/Program';
import GiftCard from 'views/GiftCard';
import SchedulesAndPrices from 'views/SchedulesAndPrices';
import CorporateEvents from 'views/CorportateEvents';
import ShareLinks from 'views/ShareLinks';

const Routes = (): React.ReactElement => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route exact path="/sell" render={() => <SelectEntrances />} />
        <Route exact path="/tickets/:item/:id" render={() => <SelectTickets />} />
        <Route exact path="/summary" render={() => <PurchaseSummary />} />
        <Route exact path="/success" render={() => <PurchaseSuccess />} />
        <Route exact path="/error" render={() => <PurchaseError />} />
        <Route exact path="/grills" render={() => <Grills />} />
        <Route exact path="/purchaseChange" render={() => <PurchaseChange />} />
        <Route exact path="/purchaseChange/:token" render={() => <PurchaseChange />} />
        <Route exact path="/place/:id" render={() => <Place />} />
        <Route exact path="/date" render={() => <SelectDate />} />
        <Route exact path="/date/:token" render={() => <SelectDate />} />
        <Route exact path="/confirmChange/:token" render={() => <ConfirmChange />} />
        <Route exact path="/faq" render={() => <Faq />} />
        <Route exact path="/somos" render={() => <WeAre />} />
        <Route exact path="/programs" render={() => <Programs />} />
        <Route exact path="/newsPosts" render={() => <NewsPosts />} />
        <Route exact path="/newsPosts/:id" render={() => <NewsPost />} />
        <Route exact path="/programs/:id" render={() => <Program />} />
        <Route exact path="/giftCard" render={() => <GiftCard />} />
        <Route exact path="/prices" render={() => <SchedulesAndPrices />} />
        <Route exact path="/corporateEvents" render={() => <CorporateEvents />} />
        <Route exact path="/shareLinks" render={() => <ShareLinks />} />
        <Redirect exact path="*" to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
