import moment from 'moment';

type HeaderProps = {
  months: string[];
  selectedMonth: number;
  currentYear: number;
};

const setDateHeaderProps = (value: moment.Moment): HeaderProps => {
  const current = value.clone();
  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    current.month(i);
    months.push(localeData.months(current));
  }

  const selectedMonth = value.month();
  const currentYear = value.year();

  return { months, selectedMonth, currentYear };
};

export default setDateHeaderProps;
