import React, { FC } from 'react';
import parse from 'html-react-parser';
import { ThingToDo } from 'apollo/generated/types';
import styles from './ActivityDetails.module.scss';

interface Props {
  thingToDo?: ThingToDo;
}

const ThingsToDoDetails: FC<Props> = ({ thingToDo }: Props) => {
  return thingToDo ? (
    <div className={styles.Container}>
      <div className={styles.ActivityText}>
        <h1 className={styles.Title}>{thingToDo.name}</h1>
        <div className={styles.Description}>{parse(thingToDo.description || '')}</div>
      </div>
      <div className={styles.ImagesContainer}>
        {thingToDo.thingToDoImages?.map((thingToDoImage) => (
          <div className={styles.ActivityImage} key={thingToDoImage?.id}>
            <img alt={thingToDo.name || ''} src={thingToDoImage?.image || ''} />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

ThingsToDoDetails.defaultProps = {
  thingToDo: undefined,
};

export default ThingsToDoDetails;
