import { gql, useQuery } from '@apollo/client';
import { Pack, QueryListPacksForPurchaseArgs } from 'apollo/generated/types';

const LIST_PACKS = gql`
  query listPacksForPurchase($date: String!) {
    listPacksForPurchase(date: $date) {
      packImages {
        image
      }
      id
      name
      adultValue
      otherValue
      description
      remaining
      visibility
      status
    }
  }
`;

const useListPacksForPurchaseQuery = (date: string) => {
  return useQuery<{ listPacksForPurchase: Pack[] }, QueryListPacksForPurchaseArgs>(
    LIST_PACKS,
    {
      variables: { date },
    }
  );
};
export default useListPacksForPurchaseQuery;
