import React, { ReactElement } from 'react';
import { bussinessValues } from 'assets/texts/weAre';
import parse from 'html-react-parser';
import styles from './BussinessValues.module.scss';

const History = (): ReactElement => {
  return (
    <div className={styles.Container}>
      {bussinessValues.map(({ title, image, text }) => {
        return (
          <div className={styles.ValueContainer} key={title}>
            <div className={styles.ValueFlexContainer}>
              <img src={image} alt="value" id={title} className={styles.Image} />
              <div className={styles.TextContainer}>
                <h2 className={styles.Title}>{title}</h2>
                <p className={styles.Text}>{parse(text)}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default History;
