/* eslint-disable react/no-danger */
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import ShareSocialMediaImage from 'assets/images/share-social-media-image.jpg';
import InfoIcon from 'assets/images/info-icon.svg';
import * as texts from '../../../assets/texts/schedulesAndPrices';
import styles from './ImportantInformationHeader.module.scss';

const ImportantInformationHeader: FC = () => {
  return (
    <Row className={styles.Container}>
      <Col span={24}>
        <div className={styles.ImageContainer}>
          <div className={styles.ImageBlackContainer}>
            <img src={ShareSocialMediaImage} alt="" className={styles.Image} />
          </div>
        </div>
        <Row className={styles.TextContainer} align="middle">
          <Col span={24}>
            <Row justify="center">
              <h2 className={styles.Title}>{texts.importantInformation}</h2>
            </Row>
            <Row justify="center" align="middle">
              <Col className={styles.InfoImageContainer} span={2} xs={4} sm={3}>
                <img className={styles.InfoIcon} src={InfoIcon} alt="info" />
              </Col>
              <Col xl={13} lg={15} md={16} sm={17} xs={18}>
                <h3
                  className={styles.ProgramText}
                  dangerouslySetInnerHTML={{ __html: texts.onlyOnlineSales }}
                />
                <h3
                  className={styles.ProgramText}
                  dangerouslySetInnerHTML={{ __html: texts.childrenFree }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ImportantInformationHeader;
