import React, { FC, useState } from 'react';
import Button from 'components/generalUI/Button';
import styles from './Timeline.module.scss';
import { Props } from './types';

const Timeline: FC<Props> = ({ yearsDescriptions }: Props) => {
  const [moreDescriptions, setMoreDescriptions] = useState(false);
  const loadMore = () => {
    setMoreDescriptions(!moreDescriptions);
  };
  const actualDescriptions = () => {
    return moreDescriptions ? yearsDescriptions : yearsDescriptions.slice(0, 2);
  };

  return (
    <div className={styles.Container}>
      {actualDescriptions().map(({ year, descriptions }) => (
        <div className={styles.YearContainer} key={year}>
          <h2 className={styles.Year}>{year}</h2>
          {descriptions.map(({ title, text }) => (
            <div className={styles.DescriptionContainer} key={title}>
              <hr className={styles.DescriptionLine} />
              <div className={styles.DescriptionBox}>
                <h2>{title}</h2>
                <p>{text}</p>
              </div>
            </div>
          ))}
        </div>
      ))}
      <div className={styles.ButtonContainer}>
        <hr className={styles.ButtonLine} />
        <Button className={styles.Button} onClick={loadMore}>
          {moreDescriptions ? 'Cargar Menos' : 'Cargar Más'}
        </Button>
      </div>
    </div>
  );
};

export default Timeline;
