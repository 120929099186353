import React, { FC, ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import selectApolloClient, { mockClient } from 'apollo/config';
import { useMocksCache } from 'apollo/cache';

interface ProviderProps {
  children?: ReactNode | ReactNode[] | null;
}

const ApolloWrapperProvider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const apolloClient = selectApolloClient();
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export const MockProvider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  useMocksCache(true);
  return <ApolloProvider client={mockClient}>{children}</ApolloProvider>;
};

ApolloWrapperProvider.defaultProps = {
  children: undefined,
};

MockProvider.defaultProps = {
  children: undefined,
};

export default ApolloWrapperProvider;
