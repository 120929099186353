import { Carousel, Col, Row } from 'antd';
import React from 'react';
import * as texts from 'assets/texts/corporateEvents';
import ScrollableItem from 'components/sharedComponents/ScrollableItem';
import styles from './TeamBuilding.module.scss';

const TeamBuilding = () => {
  const { innerWidth: width } = window;
  return (
    <Row>
      <Col span={24}>
        <Row className={styles.Container} justify="center">
          <Col xs={24} sm={16}>
            <h2 className={styles.Title}>{texts.teamBuilding}</h2>
            <p>{texts.teamBuildingDescription}</p>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <div className={styles.ScrollableList}>
          <Carousel
            dots={false}
            slidesToShow={width > 768 ? 5 : 2}
            draggable
            swipeToSlide
            touchThreshold={50}
            focusOnSelect
            autoplay
          >
            {texts.teamBuildingCarousel.map(({ imageUrl }, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`div-${index}`}>
                  <ScrollableItem
                    index={index}
                    imageUrl={imageUrl}
                    scrollableImageClassName={styles.ScrollableImage}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </Col>
    </Row>
  );
};

export default TeamBuilding;
