import { gql, useMutation } from '@apollo/client';
import {
  UpsertClientAndCreateGiftCardTransactionInput,
  WebPayCreateGiftCardPurchaseResponse,
} from 'apollo/types';

const UPSERT_WEBPAY_GIFT_CARD_PURCHASE = gql`
  mutation upsertWebpayGiftCardTransaction(
    $clientArguments: UpsertClientArgs!
    $giftCardPurchaseArguments: GiftCardPurchaseInput!
    $billingSystemArguments: BillingSystemInputArgs!
  ) {
    createGiftCardPurchase(
      input: {
        clientArguments: $clientArguments
        giftCardPurchaseArguments: $giftCardPurchaseArguments
        billingSystemArguments: $billingSystemArguments
      }
    ) {
      url
      token
    }
  }
`;
const upsertWebpayGiftCardTransactionMutation = () => {
  return useMutation<
    WebPayCreateGiftCardPurchaseResponse,
    UpsertClientAndCreateGiftCardTransactionInput
  >(UPSERT_WEBPAY_GIFT_CARD_PURCHASE);
};

export default upsertWebpayGiftCardTransactionMutation;
