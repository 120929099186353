import { gql, useQuery } from '@apollo/client';
import { EntranceTicketType } from 'apollo/generated/types';

const LIST_ENTRANCE_TICKET_TYPES = gql`
  query listEntranceTicketTypes {
    listEntranceTicketTypes {
      adultValue
      description
      id
      maxStock
      name
      otherValue
      status
      isBicycle
      visibility
      entranceImages {
        id
        image
        uploadUrl
      }
    }
  }
`;

const useListEntranceTicketTypesQuery = () => {
  return useQuery<{ listEntranceTicketTypes: EntranceTicketType[] }>(
    LIST_ENTRANCE_TICKET_TYPES,
    {}
  );
};
export default useListEntranceTicketTypesQuery;
