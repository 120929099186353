/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { Col, Row } from 'antd';
import * as texts from 'assets/texts/home';
import Button from 'components/generalUI/Button';
import { useHistory } from 'react-router-dom';
import styles from './Drop.module.scss';

const Drop: FC = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/somos');
  };

  return (
    <div className={styles.Container}>
      <Row justify="space-around">
        <Col xl={18} lg={20} xs={24} className={styles.TexturedContainer}>
          <Row className={styles.FullWidth} justify="start">
            <Col md={{ span: 12, offset: 0 }} xs={{ span: 22, offset: 1 }}>
              <img className={styles.Image} src={texts.dropImgUrl} alt="welcome" />
            </Col>
            <Col
              md={{ span: 12, offset: 0 }}
              xs={{ span: 22, offset: 1 }}
              className={styles.TextContainer}
            >
              <h1 className={styles.Title}>{texts.dropMainMessage}</h1>
              <p className={styles.Paragraph}>{texts.dropSecondaryMessage}</p>
              <Button className={styles.AboutUsButton} onClick={handleClick} size="large">
                {texts.dropButton}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

Drop.defaultProps = {
  secondaryMessage: null,
};

export default Drop;
