/* eslint-disable react/no-danger */
import { Col, Row, Image } from 'antd';
import React, { FC } from 'react';
import { useNewsPostViewModel } from 'components/NewsPost/newsPost.viewModel';
import styles from './RichTextComponent.module.scss';

export interface RichTextComponentProps {
  content: string;
  image?: {
    url: string;
    caption: string;
  };
}

export const RichTextComponent: FC<RichTextComponentProps> = ({
  content,
  image,
}: RichTextComponentProps) => {
  const { isMobile } = useNewsPostViewModel();

  return (
    <>
      <div
        className={styles.TextContainer}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {image && (
        <Row className={styles.Container}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Image
                  src={image.url}
                  alt=""
                  className={isMobile ? styles.ImageMobile : styles.Image}
                />
              </Col>
            </Row>
            <Row justify="start">
              <Col>
                <span className={styles.Caption}>{image.caption}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

RichTextComponent.defaultProps = {
  image: undefined,
};
